import React, { useState ,useEffect} from 'react';
import { Col, Modal, Row } from 'antd';
import APIServices from '../../../lib/APIServices';
import FileViewer from '../../../components/FileViewer';
const { getTalentFilePreview} = APIServices;
const UserDetail = (props: any) => {
  const {
    onCancel,
    visibleUserDetail,
    record,
  } = props;

  return (
    <Modal
      destroyOnClose
      width={500}
      title={'简介'}
      visible={visibleUserDetail}
      onCancel={() => onCancel()}
      footer={null}
    >
     
    <div className='user-detail-modal'>
        <Row justify='center'>
            <Col className='user-name'>{record.userName}</Col>
        </Row>
        <Row>
            <Col>工作单位：</Col>
             <Col>{record.gzdw}</Col>
        </Row>
        <Row >
            <Col span={12}>性别：{record.xb}</Col>
            <Col span={12}>证件号码：{record.zjhm}</Col>
        </Row>
        <Row>
            <Col span={12}>专业：{record.zy}</Col>
            <Col span={12} >最高学位：{record.zgxw} </Col>
        </Row>

        <Row>
            <Col span={12}>职务: {record.zw}</Col>
            <Col span={12}>专业技术职称：{record.zc}</Col>
        </Row>
        <Row>
            <Col>课题：{record.sskt}</Col>
        </Row>
        {
            record.gzsj
            &&
            <Row>
                <Col>项目投入时间：{record.gzsj}个月</Col>
            </Row>
        }
      
        
    </div>
    </Modal>
  );
};

export default UserDetail;   
