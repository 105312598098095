
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, List ,Input} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import { actions as globalAction } from '../../redux/modules/global';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
const { Search } = Input;
const { getHospitalList } = APIServices;
  
const {  } = globalAction;
const { NATIONS,  GENDER_WOMAN, GENDER} = Constants;
interface Props {


}

const FormInfo = (props: Props) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [hospitalList, setHospitalList] = useState<any>();
    const [showSave, setShowSave] = useState(false);
    const [openTreatmentIndex, setTreatmentIndex] = useState(false);
    const [patientInfo, setPatientInfo] = useState<any>({});
   
    const getList = () => {
        getHospitalList(value).then((result: any) => {
            setHospitalList(result);
        })
    }
    useEffect(() => {
        getList();
    }, [value]);
    useEffect(() => {
        window.document.title = '添加就诊信息';
    })
    return (
           <div className="mobile-bg">
            <div className="mobile-ht">请选择医院</div>
            <div className='mobile-search'>
                <Search size='large' placeholder="请输入医院名称" onChange={(e: any) => setValue(e.target.value)} onSearch={(val: any) => { setValue(val);  getList()}} enterButton />
            </div>
             <List
                itemLayout="horizontal"
                dataSource={hospitalList}
                renderItem={(item: any, index) => (
                <List.Item>
                    <List.Item.Meta
                        // avatar={<Avatar src={`https://joesch.moe/api/v1/random?key=${index}`} />}
                        title={<a href={`/scanCode/form?hospitalId=${encodeURI(encodeURI(item.yName))}`}>{item.yName}</a>}
                    />
                </List.Item>
                )}
            />
           </div>
    )
};

export default FormInfo;
