export default {
    // graph data
    GET_DIAGNOSIS_DATA: 'get_diagnosis_data',
    TYPE_NAME: ['技法', '方药', '诊断'],
    GENDER:[{
        value:1,
        name: '女'
    },{
        value:2,
        name: '男'
    }], //
    GENDER_MAN: 2, //男
    GENDER_WOMAN: 1, // 女
    STEPS: [
        {
            title: '语义分析中',
            content: 'First-content',
        },
        {
            title: '数据搜索中',
            content: 'Second-content',
        },
        {
            title: '数据整合中',
            content: 'Last-content',
        },
    ],
    GET_ALL_DIAGNOSIS: 'get_all_diagnosis',
    GET_PATIENT_DIAGNOSIS: 'get_patient_diagnosis',
    GET_ALL_TREATMENTS: 'get_all_treatments',
    GET_PATIENT_TREATMENTS: 'get_patient_treatments',
    GET_PATIENT_ALL_DISEASE_INFO: 'get_patient_all_disease_info',
    GET_PATIENT_CURRENT_DISEASE_INFO: 'get_patient_current_disease_info',
    GET_LOADING: 'get_loading',
    SET_WORD_CLOUD: 'set_word_cloud',
    TREATMENT_VISIBLE: 'treatment_visible',
    TREATMENT_RECORD: 'treatment_record',
    DOCTOR_RECORD_LIST: 'get_doctor_record_list',
    MINI_CASE_LIST: 'get_mini_case_list',
    STUDY_CENTER_DIAGNOSIS: 'study_center_diagnosis',
    STUDY_CENTER_TREATMENTS: 'study_center_treatments',
    SET_CURRENT_PATIENT_ID: 'set_current_patient_id',
    GET_MAIN_TYPE: {
        'CHIEF_COMPLAINT': 1,
        'INITIAL_DIAGNOSIS': 2,
        'CZ': 3
    },
    //智能分析状态管理
    // 医生治疗类型
    TREATMENT_TYPE: {
        MZ: 'minzuyi', //民族医
        ZH: 'zhongyi', //中医
        EN: 'xiyi', // 西医
    },
    //诊断参数
    SMART_CHIEF_COMPLAINT: 'smart_chief_complaint', // 主诉
    SMART_CHIEF_COMPLAINT_TAGS: 'smart_chief_complaint_tags',//主诉词汇tags
    SMART_DIAGNOSIS_FILTER: 'smart_diagnosis_filter',// 诊断过滤器
    // 治疗参数
    SMART_DIAGNOSIS: 'smart_diagnosis', // 诊断
    SMART_DIAGNOSIS_TAGS: 'smart_diagnosis_tags',//诊断词汇tags
    SMART_DIAGNOSIS_SELECT: 'smart_diagnosis_select',//诊断推荐选择
    SMART_TREATMENT_FILTER: 'smart_treatment_filter',// 治疗过滤器
    //诊断list
    SMART_DIAGNOSIS_LIST: 'smart_diagnosis_list',
    SMART_DIAGNOSIS_CURRENT_LIST: 'smart_diagnosis_current_list',
    SMART_DIAGNOSIS_DEFAULT_LIST: 'smart_diagnosis_default_list',
    //治疗list
    SMART_TREATMENT_LIST: 'smart_treatment_list',
    SMART_TREATMENT_CURRENT_LIST: 'smart_treatment_current_list',
    SMART_TREATMENT_DEFAULT_LIST: 'smart_treatment_default_list',
    //是否触发选择
    IS_SELECT_SMART_DIAGNOSIS_LIST: 'is_select_smart_diagnosis_list',
    IS_SELECT_SMART_TREATMENT_LIST: 'is_select_smart_treatment_list',
    //词云list
    WORD_CLOUD_LIST: 'word_cloud_list',
    WORD_CLOUD_SELECT_TAGS: 'word_cloud_seke',
    //智能推荐弹窗
    SMART_DIAGNOSIS_VISIBLE: 'smart_diagnosis_visible', // 诊断
    SMART_TREATMENT_VISIBLE: 'smart_treatment_visible', // 治疗
    //智能推荐弹窗索引
    SMART_MODAL_INDEX: 'smart_modal_index',// 弹窗索引
    // 智能弹窗方式
    SMART_MODAL_TYPE: {
        DIAGNOSIS: 1, //诊断
        TREATMENT: 2, // 治疗
    },
    SHOW_TAG_TYPE: {
        SMART: 1,// 智能弹窗
        CY: 2// 词云
    },
    //学习中心智能弹窗推送
    STUDY_CENTER_CURRENT_DIAGNOSIS: 'study_center_current_diagnosis',
    STUDY_CENTER_CURRENT_TREATMENT: 'study_center_current_treatment',
    STUDY_CENTER_DELETE_DIAGNOSIS: 'study_center_delete_diagnosis',
    STUDY_CENTER_DELETE_TREATMENT: 'study_center_delete_treatment',
    // auto select 删除的tags
    AUTO_SELECT_DELETE_VALUE: 'auto_select_delete_value',// 删除的值
    AUTO_SELECT_DELETE_TYPE: {
        DIAGNOSIS: 2, //诊断
        TREATMENT: 3, // 治疗
    },
    //预诊智能弹框推送
    PRE_CLINICAL_CURRENT_DIAGNOSIS: 'pre_clinical_current_diagnosis',
    PRE_CLINICAL_CURRENT_TREATMENT: 'pre_clinical_current_treatment',
    PRE_CLINICAL_DELETE_DIAGNOSIS: 'pre_clinical_delete_diagnosis',
    PRE_CLINICAL_DELETE_TREATMENT: 'pre_clinical_delete_treatment',
    //预诊智能弹框删除值和分类
    PRE_CLINICAL_DELETE_VALUE: 'pre_clinical_delete_value',// 删除的值
    PRE_CLINICAL_DELETE_TYPE: { // 分类
        DIAGNOSIS: 2, //诊断
        TREATMENT: 3, // 治疗
    },
    // 预诊开药
    // 开药list
    PRESCRIPTION_SELECT_LIST: 'prescription_select_list', // 选择药品列表
    PRESCRIPTION_NUMBER_LIST: 'prescription_number_list', // 选择药品数量列表
    PRESCRIPTION_MODAL_INDEX: 'prescription_modal_index',// 弹窗索引
    PRESCRIPTION_MODAL_VISIBLE: 'prescription_modal_visible',// 弹窗可见
    // 开药的encode和name 
    PRESCRIPTION_ENCODE_NAME: [],
    //文件类型图片
    FILE_IMG:<any> {
        pdf: '/pdfFile.png',
        doc: '/docFile.png',
        docx: '/docFile.png',
        mp4: '/videoFile.png',
        mpg: '/videoFile.png',
        tif: '/imgFile.png',
        jpg: '/imgFile.png',
        caj: '/cajFile.png'
    },
    // 人才培养user 
    USER_TYPE: {
        inheritance: 1, // 传承
        behalf: 2, //代表
        lead: 3,// 带头
    },
    CASE_TYPE: {
        TB: 1, // 填报
        JC: 2, // 决策
    },
    CASE_TREATMENT_TYPE: {
        JF: 1, // 技法
        FY: 2, // 方药
        ZD:3 ,// 诊断
    },
    NATIONS: [
        "汉族",
        "蒙古族",
        "回族",
        "藏族",
        "维吾尔族",
        "苗族",
        "彝族",
        "壮族",
        "布依族",
        "朝鲜族",
        "满族",
        "侗族",
        "瑶族",
        "白族",
        "土家族",
        "哈尼族",
        "哈萨克族",
        "傣族",
        "黎族",
        "傈僳族",
        "佤族",
        "畲族",
        "高山族",
        "拉祜族",
        "水族",
        "东乡族",
        "纳西族",
        "景颇族",
        "柯尔克孜族",
        "土族",
        "达斡尔族",
        "仫佬族",
        "羌族",
        "布朗族",
        "撒拉族",
        "毛南族",
        "仡佬族",
        "锡伯族",
        "阿昌族",
        "普米族",
        "塔吉克族",
        "怒族",
        "乌孜别克族",
        "俄罗斯族",
        "鄂温克族",
        "德昂族",
        "保安族",
        "裕固族",
        "京族",
        "塔塔尔族",
        "独龙族",
        "鄂伦春族",
        "赫哲族",
        "门巴族",
        "珞巴族",
        "基诺族"
    ],
    ZD_TYPE: [
        '',
        '中医',
        '西医',
        '民族医'
    ]
};