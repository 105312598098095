import { combineReducers } from 'redux';
import global from './modules/global';
import cases from './modules/cases';
import smart from './modules/smart';
import wordCloud from './modules/wordCloud';
import prescription from './modules/prescription';

export default function createRootReducer() {
    return combineReducers({
        global,
        cases,
        smart,
        wordCloud,
        prescription
    } as any);
}
