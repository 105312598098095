import { Button, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIServices from '../lib/APIServices';
import Constants from '../lib/Constants';
import { actions as smartAction } from '../redux/modules/smart';

interface Props {
    onGetValue: (value: any) => void
    type: number
    label: string
    defaultValue?: string
    wordList?: Array<any>
    smartList?: Array<any>
};
const { Option } = Select;
const { getQueryMainKey } = APIServices;
const {AUTO_SELECT_DELETE_TYPE} = Constants;
const { setAutoSelectDeleteValue } = smartAction;
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<any>();
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = async (value: any) => {
            // 多个空格
            const newValueArr = value.split(' ');
            const showOption = newValueArr.some((item: any) => item);
            if (showOption) {
                fetchRef.current += 1;
                const fetchId = fetchRef.current;
                setOptions([]);
                setFetching(true);
                fetchOptions(value, props.type)
                    .then((newOptions: any) => {
                        if (fetchId !== fetchRef.current) {
                            // for fetch callback order
                            return;
                        }
                        const _selfValue: any = [{ value }];
                        value && setOptions(_selfValue.concat(newOptions));
                        setFetching(false);
                    });
            }
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
       <>
        <Select
            labelInValue
            defaultValue={['']}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
       </>
    );
} // Usage of DebounceSelect

async function fetchUserList(_value: any, _type: number) {
    const data = _value && await getQueryMainKey(_type, _value) || [];
    const newValue = data.map((item: any) => {
        return {
            value: item.itemName
        }
    }) || [];
    return newValue;

}

const AutoSelect = (props: Props) => {
    const { label, type, onGetValue, defaultValue, wordList, smartList } = props;
    const [value, setValue] = useState<any>([]);
    const [valueText, setValueText] = useState<any>([]);
    const [options, setOptions] = useState([]);
    
    const { studyCenterCurrentDiagnosis, studyCenterCurrentTreatment, studyCenterDeleteDiagnosis, studyCenterDeleteTreatment } = useSelector((state: any) => {
        return {
            studyCenterCurrentDiagnosis: state.smart.studyCenterCurrentDiagnosis,
            studyCenterCurrentTreatment: state.smart.studyCenterCurrentTreatment,
            studyCenterDeleteDiagnosis: state.smart.studyCenterDeleteDiagnosis,
            studyCenterDeleteTreatment: state.smart.studyCenterDeleteTreatment
        }
    });
    const dispatch = useDispatch();
    // 点击按钮动态添加值
    const onClickToSelect = (text: string) => {
        if(valueText.includes(text)) {
            // 删除
            const filterValue = value.filter((item: any) => item.value !== text);
            setValue(filterValue)
        } else {
            const _default = {
                disabled: false,
                value: text,
                label: text,
                key: text ,// 随机数字
            }
            setValue([...value, _default])
        }
    }
    useEffect(() => {
        if(defaultValue) {
            setValue([defaultValue])
        }
    }, [])
    
    // 将标签插入select 控制select的值
    useEffect(() => {
       const newValueString = value.map((item: any) => item.value).join(',');
       const newValueText  = value.map((item: any) => item.value);
       setValueText(newValueText);
       // 根据值得添加和删除返回
       onGetValue(newValueString);
    }, [value])

      // 将标签插入select 控制select的值
      useEffect(() => {
        if(JSON.stringify(studyCenterCurrentDiagnosis) !== '{}' && type === AUTO_SELECT_DELETE_TYPE.DIAGNOSIS) {
            const newSmartList = {
                disabled: false,
                value: `#${studyCenterCurrentDiagnosis.zd}#`,
                label: `#${studyCenterCurrentDiagnosis.zd}#`,
                key: `#${studyCenterCurrentDiagnosis.zd}#` ,// 随机数字
            }
            setValue([...value, newSmartList])
        }
     }, [studyCenterCurrentDiagnosis])

     useEffect(() => {
        if(JSON.stringify(studyCenterCurrentTreatment) !== '{}' && type === AUTO_SELECT_DELETE_TYPE.TREATMENT) {
            const newSmartList = {
                disabled: false,
                value: `#${studyCenterCurrentTreatment.tName}#`,
                label: `#${studyCenterCurrentTreatment.tName}#`,
                key: `#${studyCenterCurrentTreatment.tName}#` ,// 随机数字
            }
            setValue([...value, newSmartList])
        }
     }, [studyCenterCurrentTreatment])
    // 智能删除tag 联动auto select的值
    useEffect(() => {
        // 诊断
        if(JSON.stringify(studyCenterDeleteDiagnosis) !== '{}') {
            const filterValues = value.filter((item: any) => item.value !== `#${studyCenterDeleteDiagnosis.zd}#`)
            setValue(filterValues)
        }
    }, [studyCenterDeleteDiagnosis])

    useEffect(() => {
        // 治疗
        if(JSON.stringify(studyCenterDeleteTreatment) !== '{}') {
            const filterValues = value.filter((item: any) => item.value !== `#${studyCenterDeleteTreatment.tName}#`)
            setValue(filterValues)
        }
    }, [studyCenterDeleteTreatment])
    return (
       <>
        <DebounceSelect
            mode="multiple"
            type={type}
            value={value}
            placeholder={`输入${label}`}
            fetchOptions={fetchUserList}
            onChange={(newValue: any, e: any) => {
                // 过滤出删除的#项目
                if(newValue.length < value.length) {
                    const newValueString = newValue.map((item: any) => item.value);
                    const deleteTarget = value.find((item: any) => ! newValueString.includes(item.value))
                    if(deleteTarget.value.includes('#')) {
                        dispatch(setAutoSelectDeleteValue({
                            type,
                            tag:deleteTarget.value
                        }))
                    }
                   
                }
                setValue(newValue);
               
                
            }}
            style={{
                width: '100%',
            }}
        />
        {
            wordList
            &&
            wordList.map((tag: any) => <Button  onClick={() => onClickToSelect(`${tag}`)} type='dashed'>+{tag}</Button>)
        }
          
         {/* <Button  onClick={() => onClickToSelect('(2)')} type='dashed'>插入文本2</Button> */}
       </>
    );
};

export default AutoSelect;