import React, { useState, useEffect } from 'react';
import { Drawer, Tabs } from 'antd';
import '../standards.css'

interface Props {
    values: any
    onCancel: () => void
    visible: boolean
    title:string
}
const ChineseMedicineStore = (props: Props) => {
  const { values ,visible , onCancel, title} = props;
  
  const valuesList = [{
    label: '类别名称',
    name: 'dtypeName'
  }, {
    label: '药品编码',
    name: 'ypbm'
  }, {
    label: '药品名称',
    name: 'ypmc'
  }, {
    label: '药品剂型',
    name: 'ypJx'
  }, {
    label: '规格',
    name: 'gg'
  }, {
    label: '产地',
    name: 'cd'
  }, {
    label: '说明',
    name: 'sm'
  }, {
    label: '费用类型',
    name: 'fylx'
  }, {
    label: '国家医保编码',
    name: 'gjybbm'
  }, {
    label: ' 国家医保名称',
    name: 'gjybmc'
  }, {
    label: '剂量系数',
    name: 'jlxs'
  }, {
    label: '剂量单位',
    name: 'jldw'
  }, {
    label: '售价单位',
    name: ' sjdw'
  }, {
    label: '门诊包装',
    name: ' mzbz'
  }, {
    label: '门诊单位',
    name: ' mzdw'
  }, {
    label: '住院包装',
    name: ' zybz'
  }, {
    label: '住院单位',
    name: ' Zydw'
  }, {
    label: '药库包装',
    name: ' ykbz'
  }, {
    label: ' 药库单位',
    name: ' ykdw'
  }, {
    label: '上次批准文号',
    name: 'scpzwh'
  }, {
    label: '药品来源',
    name: 'yply'
  }, {
    label: '本位码',
    name: 'bwm'
  }, {
    label: '标识码',
    name: 'bsm'
  }, {
    label: '价值分类',
    name: 'jzfl'
  }, {
    label: '毒理分类',
    name: 'dlfl'
  }, {
    label: '发药类型',
    name: 'fyfl'
  }, {
    label: '基本药物',
    name: 'jbyw'
  }, {
    label: '中药类型',
    name: 'zylx'
  }, {
    label: '中药包装方式',
    name: 'zybzfs'
  }
]
  return (
    <Drawer
      title={title}
      onClose={() => {
        onCancel();
      }}
      width={'50%'}
      visible={visible}
    >
    {
        valuesList.map((item: any) => (
            // values[item.name]
            // &&
            <div className='chinese-medicine-row'>
                <span className='chinese-medicine-label'>{item.label}: </span>
                <span className='chinese-medicine-text'>{ values[item.name]}</span>
            </div>
        ))
    }

    </Drawer>
  )
};
export default ChineseMedicineStore;
