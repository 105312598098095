
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { Col, Row } from 'antd';
import ExtendChainMap from './ExtendChainMap';
import ResultTransFormChart from './ResultTransFormChart';
import StandardsFormChart from './StandardsFormChart';
import TalentTrainFormChart from './TalentTrainFormChart';
import SameTechFormChart from './SameTechFormChart';
const ChartPage = () =>{
    return (
        <div className='chart-container'>
            <Row justify='space-between'>
                <Col span={24}>
                    <StandardsFormChart />
                </Col>
            </Row>
            <Row  justify='space-between'>
                <Col span={6}>
                    <ResultTransFormChart />
                </Col>
                {/* 地图统计图 */}
                <Col span={11}>
                   <ExtendChainMap />
                </Col>
                <Col span={6}>
                    <SameTechFormChart />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TalentTrainFormChart />
                </Col>
            </Row>
            
        </div>
    );
}

export default  ChartPage;