import React, { useEffect, useState } from 'react'
import NewFileViewer  from 'react-file-viewer';
import PdfPreview from './pdfPreview';
import './fileViewer.less';

export default function FileViewer (props: any){
    const {file: filePath, type, id} = props;
    const getError = (val: any) => {
        console.log('err=', val)
    }
    return (
        <div className='file-view' style={{height: '600px'}}>
            {type ? (
                <NewFileViewer
                    key={id}
                    fileType={type}
                    filePath={filePath}
                    errorComponent={getError}
                    onError={(err:any) => console.log(err)}
                />
            ) : (
                <div className='file-view-warn'>暂无文件预览</div>
            )}
        </div>
    )
}
