import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Steps, Spin, Tree, Menu, Tooltip} from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import PreviewForm from '../../components/Resources/components/PreviewForm';

import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import './standards.css';
import JiFaDetail from './components/JiFaDetail';
import FangYaoDetail from './components/FangYaoDetail';
import ChineseMedicineStore from './components/ChineseMedicineStore';

const { Step } = Steps;
const { getSearchTreatment, getTalentTrainList, getStandardsMenuTree, getStandardsListByMenu, getFangYaoDetail, getJiFaDetail} = APIServices;
const { TYPE_NAME, STEPS, FILE_IMG} = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;

const Standards = (props: any) => {
    const { menuId } =  props;
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const [searchValue, setSearchValue] = useState('')
    const [TreatmentData, setTreatmentData] = useState([]);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [current, setCurrent] = React.useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);
    
    const [treeData ,setTreeData] = useState<any>([]);
    const [dataSource ,setDataSource] = useState<any>([]);
    const [previewModalVisible, handlePreviewModalVisible] = useState(false);
    const [values ,setValues] = useState<any>({});
    const [activeJiFa, setActiveJiFa] = useState<any>([]);
    const [allJiFa, setAllJiFa] = useState<any>([]);
    const [enCode , setEnCode] = useState<any>('');
    //fangyao
    const [fangYaoVisible, setFangYaoVisible] = useState(false);
    const [fangYaoRecord, setFangYaoRecord] = useState('');
    //jifa
    const [jiFaVisible, setJiFaVisible] = useState(false);
    const [jiFaRecord, setJiFaRecord] = useState('');
    // 中药库
    const [chineseMedicineStoreVisible, setChineseMedicineStoreVisible] = useState(false);
    const [chineseMedicineStoreRecord, setChineseMedicineStoreRecord] = useState('');
    const [pagination, setPagination] = useState<any>({
        pageSize: 10,
        pageNum: 1,
        total: 0
    });
    const columns: any = [
        {
            title: '名称',
            dataIndex: 'zwTitle',
            key: 'zwTitle',
           
        },
        {
            title: '拼音名称',
            dataIndex: 'pyTitle',
            key: 'pyTitle',
           
        },
        {
            title: '英文名称',
            dataIndex: 'ddTitle',
            key: 'ddTitle',
           
        },
        {
            title: '来源',
            dataIndex: 'ly',
            key: 'ly',
            align: 'center',
        },
        {
            title: '分类',
            dataIndex: 'fl',
            key: 'fl',
            align: 'center',
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            align: 'center',
            width: '50%',
            render: (text: any) => (
            <div className='standards-content-container'>
                <span>{text}</span>
            </div>
            ),
        },
       
        // {
        //     title: '详情',
        //     key: 'ttbw',
        //     align: 'center',
        //     render: (text: number, record: any) => {
        //         const openDetailPage = () => {
        //             handlePreviewModalVisible(true);
        //             setValues(record)
        //         }
        //         return (<Button  type="primary" size="middle" onClick={openDetailPage}>
        //             {/* <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} /> */}
        //             在线预览
        //         </Button>)
        //     },
        // }
    ];
    const columns2: any =[
        {
          title: "中文名称",
          dataIndex: 'cName',
          align: 'center',
        },
        {
          title: "拼音名称",
          dataIndex: 'cpName',
          align: 'center',
        },
        {
          title: "英文名称",
          dataIndex: 'eName',
          align: 'center'
        },
        {
          title: "本族名称",
          dataIndex: 'hName',
          align: 'center',
        },
        {
          title: "简介",
          dataIndex: 'jj',
          align: 'center',
          width: '400px',
        },
        {
            title: '详情',
            // key: 'ttbw',
            align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage =async () => {
                    setFangYaoVisible(true);
                    const result = await getFangYaoDetail(record.id);
                    setFangYaoRecord(result)
                }
                return (<Button  type="primary" size="middle" onClick={openDetailPage}>
                    {/* <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} /> */}
                    详情
                </Button>)
            },
        }
      ];
    const columns3: any =[
    {
        title: "技术名称",
        dataIndex: 'title',
        align: 'center',
    },
    {
        title: "术语与定义",
        dataIndex: 'syhdy',
        align: 'center',
        render: (text: any) => (
        <div className='standards-content-container'>
            <span>{text}</span>
        </div>)
    },
    {
        title: "范围",
        dataIndex: 'fw',
        align: 'center',
    //   width: '400px',
    },
      {
            title: '详情',
            // key: 'ttbw',
            align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage = async () => {
                    setJiFaVisible(true);
                    // 通过接口获取
                    const result = await getJiFaDetail(record.id);
                    setJiFaRecord(record)
                }
                return (<Button  type="primary" size="middle" onClick={openDetailPage}>
                    {/* <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} /> */}
                    详情
                </Button>)
            },
        }
    ];
    const columns4: any = [
        {
            title: "技术名称",
            dataIndex: 'title',
            align: 'center',
          },
    ]

    const columns5: any = [
        {
            title: "类别名称",
            dataIndex: 'dtypeName',
            align: 'center',
        },
        {
            title: "药品编码",
            dataIndex: 'ypbm',
            align: 'center',
        },
        {
            title: "药品名称",
            dataIndex: 'ypmc',
            align: 'center',
        },
        {
            title: "药品剂型",
            dataIndex: 'ypJx',
            align: 'center',
        },
        {
            title: "规格",
            dataIndex: 'gg',
            align: 'center',
        },
        {
            title: "产地",
            dataIndex: 'cd',
            align: 'center',
        },
        {
            title: "费用类型",
            dataIndex: 'fylx',
            align: 'center',
        },
        {
            title: '详情',
            // key: 'ttbw',
            align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage = () => {
                    setChineseMedicineStoreVisible(true);
                    setChineseMedicineStoreRecord(record)
                }
                return (<Button  type="primary" size="middle" onClick={openDetailPage}>
                    {/* <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} /> */}
                    详情
                </Button>)
            },
        }
    ]
    const columnsData: any = {
        standardszyyd: columns,
        standardsmzfy: columns2,
        standardsjf: columns3,
        standardsyzk: columns4,
        standardszyk: columns5,
    }
   

    // 获取治疗推荐数据
    const getTreatmentData = async (_value: string) => {
        const _result = await getSearchTreatment(_value);
        setTreatmentData(_result)
        setCurrent(0)
    }
    const onSearch = (value: any, event: any) => {
        // getList(enCode, value);
    }
    const onCloseDetail = (_visible: boolean) => {
        setVisibleDetail(_visible)
        
    };

    const onChange = (_pagination: any) => {
        setPagination({
          pageNum: _pagination.current,
          pageSize: _pagination.pageSize,
        })
      };

    const getList = async (_enCode: string,keywords: string) => {
      
        const res =  await getStandardsListByMenu({ offset: (pagination.pageNum - 1) * pagination.pageSize, size: pagination.pageSize , enCode: _enCode, keywords}) || '';
        if(res) {
            const { list, pagination: _pagination} = res;
            setDataSource(list || [])
            setPagination({
                ...pagination,
                total: _pagination.records || 0
            })
        } else{
            setDataSource([])
            setPagination({
                ...pagination,
                total:  0
            })
        }
        

    };
    const  getMenuInitData = (data: any) => {
        return data.map((item: any) => {
            if(item.children && item.children.length > 0) {
             return getMenuInitData(item.children)
            }else {
                return {
                    ...item,
                    label: item.fullName,
                    key: item.moduleId,
                };
            }
        })
        
    }
    const getMenuItem = (data: any) => {
        return data.map((item: any) => {
            if(item.children && item.children.length > 0) {

             const children = getMenuItem(item.children)
                return {
                    label: item.fullName,
                    key: item.enCode,
                    // icon: <MailOutlined />,
                    // children
                };
            }else {
                return {
                    label: item.fullName,
                    key: item.enCode,
                    // icon: <MailOutlined />,
                }
            }
        })
        
    }

    const refresh = async () => {
        const menuList: any = JSON.parse(sessionStorage.getItem("standardsMenu") || '') || [];
        if(menuList.length > 0) {
            setTreeData(getMenuItem(menuList));// 设置目录
            enCode == '' && setEnCode(menuList[0].enCode) // 首次默认访问
        }
    }

    const onSelect = (item: any) => {
        const { key} = item;
        setEnCode(key)
        // 设置当前目录下得技法
        setPagination({
            ...pagination,
            pageNum: 1,
        })
      };

    useEffect(() => {
        console.log('=dddd')
        refresh();
    }, []); 


    useEffect(() => {
        enCode && getList(enCode, '');
    }, [enCode, pagination.pageNum]); 
 
    return (
        <div className='talent-container'>
            {/* menu */}
          <div className='standards-menu-list'>
            {
                treeData && treeData.length > 0
                && 
                <Menu
                    mode="inline"
                    // openKeys={openKeys}
                    defaultSelectedKeys={['standardszyyd']}
                    onSelect={onSelect}
                    // onOpenChange={onOpenChange}
                    style={{ width: 156 }}
                    items={treeData}
                />

            }
            
          </div>
           <div className='talent-content'>
                <div className='result-transform-tip'>
                        <p>共有<span className='count'>{pagination.total}</span>条数据 </p>
                </div>
                <div className='standards-box'>
                    {
                        loadingStatus
                            ? <Spin tip={current ? STEPS[current].title : STEPS[0].title} size="large" spinning={loadingStatus} className='tip'>
                            </Spin>
                            :
                            <Table 
                            loading={loadingStatus} 
                            rowKey='id' 
                            scroll={{
                                x: '100%'
                            }}
                            dataSource={dataSource} 
                            columns={columnsData[enCode]} 
                            pagination={pagination}
                            onChange={onChange}
                            />
                    }
                </div>
           </div>
           {
            previewModalVisible 
            &&
            <PreviewForm 
                previewModalVisible
                values={values}
                onCancel={() => {
                    handlePreviewModalVisible(false);
                    setValues({})
                }}
            />
           }
            {
            // 方药详情
            <FangYaoDetail
                record={fangYaoRecord}
                onCancel={() => setFangYaoVisible(false)}
                visible={fangYaoVisible}
                title={'方药详情'}
            />
           }
           {
            // 技法详情
            <JiFaDetail
                values={jiFaRecord}
                onCancel={() => setJiFaVisible(false)}
                visible={jiFaVisible}
                title={'技法详情'}
            />
           }

            {
            // 中药库
            <ChineseMedicineStore
                values={chineseMedicineStoreRecord}
                onCancel={() => setChineseMedicineStoreVisible(false)}
                visible={chineseMedicineStoreVisible}
                title={'药品详情'}
            />
           }
        </div>
    )
};

export default Standards;
