
import Oidc from 'oidc-client';
import { WebStorageStateStore } from 'oidc-client'
import CONFIG_HOST from './Config';

const {signLoginHost, host} = CONFIG_HOST;
// 修改请勿提交
const hostName = {
    dev: 'http://192.168.2.180:3000/', //本地 3000 /3001
    pre: host, //线上环境
}

var config = {
    loadUserInfo: true,
    authority: signLoginHost,
    client_id: "eskgweb",
    redirect_uri: `${hostName.pre}login`,
    response_type: "id_token token",
    scope: "openid profile eskg rightsManagement",
    post_logout_redirect_uri: `${hostName.pre}`,
};
var mgr = new Oidc.UserManager(config);



export function login() {
    mgr.signinRedirect();
}

export function logout() {
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("menuList");
    mgr.signoutRedirect();
    // mgr.signoutRedirectCallback('http://192.168.2.59:8000')
}
