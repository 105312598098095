
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { MapChart, EffectScatterChart ,BarChart , LineChart } from 'echarts/charts';
import {  TooltipComponent, LegendComponent,TitleComponent  } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import APIServices from '../../../lib/APIServices';

const { geChartForSameTech } = APIServices;
echarts.use([MapChart, BarChart ,LineChart]);
echarts.use([CanvasRenderer, TooltipComponent , LegendComponent,TitleComponent ]);

const  SameTechFormChart = () =>{
    const ref: any = useRef(null);
    let mapInstance = null;
   
    const renderChart = async () => {
    // 获取数据
    const _data = await geChartForSameTech();
    const xAxisData = _data.map((item: any) => item.moduleName);
    const barData = _data.map((item: any) => item.fCount);

    // 基于准备好的dom，初始化echarts实例
    mapInstance = echarts.init(ref.current);
    mapInstance.setOption( {
        // backgroundColor:'#323a5e',
           tooltip: {
             trigger: 'axis',
             axisPointer: { // 坐标轴指示器，坐标轴触发有效
               type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
             },
             formatter: (params: any) => {
                const target = params[0];
                return `${target.name}:${target.value}`;
            },
           },
           grid: {
             left: '2%',
             right: '4%',
             bottom: '14%',
             top:'16%',
             containLabel: true
           },
           title: {
            show: true, //是否显示标题
            text: '共性技术', //标题名称
            left: 'center',
            textStyle: {
                // fontSize: 35,
                color: '#1890ff'
            },
            top: '10px'
            },
           xAxis: {
             type: 'category',
             data: xAxisData,
             axisLine: {
               lineStyle: {
                 color: '#1890ff'
   
               }
             },
             axisLabel: {
               // interval: 0,
               // rotate: 40,
               textStyle: {
                 fontFamily: 'Microsoft YaHei'
               }
             },
           },
   
           yAxis: {
             type: 'value',
            //  max:'20',
             axisLine: {
               show: false,
               lineStyle: {
                 color: '#1890ff'
               }
             },
             splitLine: {
               show: true,
               lineStyle: {
                 color: 'rgba(255,255,255,0.5)'
               }
             },
             axisLabel: {}
           },
          
           series: [{
             name: '标准制定',
             type: 'bar',
             barWidth: 35,
             itemStyle: {
                color: 'transparent',
                borderWidth: 1,
                borderColor: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [{
                            offset: 0,
                            color: '#156dff'
                        },
                        {
                            offset: 1,
                            color: '#00eaeb'
                        },
                    ]
                ),
                shadowColor: 'blue',
                shadowBlur: 12,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
            },
             data: barData
           },
           {
            name: '标准制定',
            type: 'line',
            barWidth: '15%',
            itemStyle: {
              normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#248ff7'
                  }, {
                      offset: 1,
                      color: '#fff'
                  }]),
                  barBorderRadius: 12,
              },
            },
            data: barData
          },
           ]
         })
    
  };

    useEffect(() => {
        renderChart();
    }, []);
  

    return (
        <div style={{  height: "400px",}} ref={ref}></div>
    );
}

export default  SameTechFormChart;