import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button } from 'antd';

import CasesInfo from '../../../cases/components/CasesInfo';
import WordCloud from '../../../cases/components/WordCloud';
import Constants from '../../../../lib/Constants';
import SmartAnalyse from '../../../cases/components/SmartAnalyse';
import AddCase from './AddCase';
import '../../diagnosis.css';
interface Props {
    visible: boolean
    record: any
    onClose: () => void
    title: string
}
const TreatmentIndex = (props: Props) => {
    const { visible, record, onClose, title } = props;
    const [smartSearch, setSmartSearch] = useState({
        chiefComplaint: '',
        diagnosis: '',
    });

    const getSmartSearch = (_value: any) => {
        setSmartSearch(_value);
    };
    return (
        <Drawer
            title={`${title}详情`}
            placement="right"
            onClose={onClose}
            width={'90%'}
            visible={visible}
            bodyStyle={{padding:0}}
        >
            <div className='treatment-index-layout'>
                <AddCase type='add' record={record} onClose={onClose} getSmartSearch={getSmartSearch} />
                {/* <WordCloud /> */}
                <SmartAnalyse smartSearch={smartSearch} type='add-center-right' />
            </div>
        </Drawer>
    );
};

export default TreatmentIndex;