import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Oidc from 'oidc-client';

import { login } from '../../utils/AuthService';
import Common from '../../lib/Common';
import dataSet from '../../config/routerData'
import Login from '../login/login';
import ILayout from '../layout/layout';
import Home from '../home/home';
import ScanCodeIndex from '../scanCode/index';
import SelectHospital from '../scanCode/selectHospital';
import ScanCodeForm from '../scanCode/scanCodeForm';
import ScanCodeResult from '../scanCode/scanCodeResult';
import DiagnosisIndex from '../diagnosis/diagnosisIndex';
import Treatment from '../treatment/treatment';

const { getData } = Common;

const RoutesSelf = () => {
    const routeItems = dataSet.map((route: any) => <Route key={route.path}  path={route.path} element={route.component} />);
    return (
        <Routes>
            {routeItems}
        </Routes >
    );
};
const Index = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<Navigate to='login' />} />
                {/* <Route path='/' element={<Navigate to='/home/diagnosis' />} /> */}
                <Route path='/home' element={<Navigate to='/home/auxiliaryDiagnosis' />} />
                <Route path='/home/' element={<Navigate to='/home/auxiliaryDiagnosis' />} />
                <Route path='/homeIndex' element={<Home />}/>
                <Route path='/home/*' element={<ILayout> {RoutesSelf()}</ILayout>}/>
                <Route path='/login' element={<Login />} />
                <Route path='/scanCode/index' element={<ScanCodeIndex />} />
                <Route path='/scanCode/hospital' element={<SelectHospital />} />
                <Route path='/scanCode/form' element={<ScanCodeForm />} />
                <Route path='/scanCode/result' element={<ScanCodeResult />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Index;
