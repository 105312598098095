import { Col, List, Row } from 'antd';
import '../treatment.css'
interface Props {
    record: any
}
const CompositionList = (props: Props) => {
    const { record } = props;
    const listLabel = [{
        label: '名称',
        value: 'yName'
    }, {
        label: '来源',
        value: 'source'
    }, {
        label: '功效主治',
        value: 'functionIndications'
    }, {
        label: '民族医特色运用',
        value: 'featuredUse'
    }];
    const splitMzytsyy = (text: string) => {
        let _text = '';
        _text = text
        if (typeof _text == 'string' && _text) {
            return _text.split('_').map((item, index) => <p key={`${index}`}>({index + 1}) {item}</p>)
        }
    };
    return (<List
        itemLayout="vertical"
        size="large"
        pagination={{
            pageSize: 100,
            hideOnSinglePage: true
        }}
        dataSource={record}
        renderItem={(item: any) => (
            <List.Item key={item.id}>
                {
                    listLabel.map((_list, _index) => <Row key={_index} className='composition-list'>
                        <Col span={4}><p className='composition-title'>{_list.label}: </p></Col>
                        <Col span={20}>
                            {
                                _list.value === 'featuredUse' ? splitMzytsyy(item[_list.value]) : item[_list.value]
                            }
                        </Col>
                    </Row>)
                }
            </List.Item>
        )}
    />)
};
export default CompositionList;