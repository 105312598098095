
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Table, Spin, Empty, Button, Col, Row, Input, DatePicker, Select, Space, Tag, Form } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import SmartAnalysesItemTable from '../../diagnosis/components/SmartAnalysesItemTable';
import APIServices from '../../../lib/APIServices';
import Constants from '../../../lib/Constants';
import { actions as globalAction } from '../../../redux/modules/global';

import '../patientCase.css';

const { getMinZuYiData } = APIServices;
const { TYPE_NAME } = Constants;
const { Panel } = Collapse;
const { getCurrentDiagnosis, getCurrentTreatments, getAllDiagnosis, getAllTreatments, setTreatmentVisible, setTreatmentRecord } = globalAction;
const { Option } = Select;
interface Props {
    type?: string
    smartSearch?: {
        chiefComplaint: string
        diagnosis: string
    }
}
const SmartAnalyse = (props: Props) => {
    const { type, smartSearch } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRowKeys2, setSelectedRowKeys2] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<any>(true);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>([]);
    const [mzysjzd, setMzysjzd] = useState<any>([]);
    const [filterDiagnosis, setFilterDiagnosis] = useState<any>({
        nl1: '',
        nl2: '',
        xb: '',
        year: ''
    });
    const [filterTreatments, setFilterTreatments] = useState<any>({
        mzyId: '',
    });

    const { allDiagnosis, allTreatments, currentDiagnosis, currentTreatments, patientCurrentDiseaseInfo, globalLoading, wordCloud } = useSelector((state: any) => ({
        allDiagnosis: state.global.allDiagnosis,
        allTreatments: state.global.allTreatments,
        currentDiagnosis: state.global.currentDiagnosis,
        currentTreatments: state.global.currentTreatments,
        patientCurrentDiseaseInfo: state.global.patientCurrentDiseaseInfo,
        globalLoading: state.global.globalLoading,
        wordCloud: state.global.wordCloud,
    }));
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 3,
        total: 0,
    });
    const dispatch = useDispatch();
    const columns: any = [
        {
            title: '诊断',
            dataIndex: 'zd',
            key: 'zd',
        },
        {
            title: '推荐度',
            dataIndex: 'tjd',
            key: 'tjd',
            render: (text: any) => <span>{text}%</span>
        },
        {
            title: '参考病例',
            key: 'generalList',
            align: 'center',
            render: (data: any, record: any) => {
                const openDetailPage = () => {
                    setDefaultRecord(record.generalList);
                    setVisibleDetail(true);
                }
                // return (<Space size="middle" onClick={openDetailPage}>
                //     <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} />
                // </Space>)
                const _data = record.generalList.length > 3 ? record.generalList.slice(0, 3) : record.generalList;
                return <div className='custom-table'>
                    {/* <Row align='middle' wrap={false} className='custom-table-th'>
                        <Col span={4}>姓名</Col>
                        <Col span={3}>年龄</Col>
                        <Col span={3}>性别</Col>
                        <Col span={14}>推荐治疗</Col>
                    </Row> */}
                    {
                        _data.map((item: any) => <Row align='middle' key={item.id}>
                            <Col span={4}><Tag color="success">{item.patientName}</Tag> </Col>
                            <Col span={3}><Tag color="orange">{item.nl}岁</Tag></Col>
                            <Col span={3}><Tag color="processing">{item.xb}</Tag></Col>
                            <Col span={14} className='overflow'>
                                <a target={'_blank'} href={`http://tbxt.mzyx.zydyfy.cn/anquan/details/${item.aId}`}>{item.mainTitle}</a>
                            </Col>
                        </Row>)
                    }
                    {
                        record.generalList.length > 3
                        &&
                        <Row align='middle' wrap={false} >
                            <Button type='text' className='custom-table-btn' onClick={openDetailPage} >更多</Button>
                        </Row>
                    }

                </div>
            },
        }
    ];
    const columns2: any = [
        {
            title: '名称',
            dataIndex: 'tName',
            key: 'tName',
            // align: 'center',
            width: 160,
            render: (text: any, record: any) =>
                <Button
                    type='link'
                    onClick={() => {
                        dispatch(setTreatmentVisible(true));
                        dispatch(setTreatmentRecord(record));
                    }} >
                    {text}
                </Button>
        },
        {
            title: '分类',
            dataIndex: 'type',
            key: 'type',
            // align: 'center',
            width: 80,
            render: (text: number) => <span>{TYPE_NAME[Number(text) - 1]}</span>,
        },
        {
            title: '范围',
            dataIndex: 'rane',
            key: 'rane',
            // align: 'center',
            render: (text: any) => <span>{text || '无'}</span>,
        },
    ];

    const rowSelection: any = {
        onChange: (_selectedRowKeys: React.Key[], selectedRows: []) => {
            // 选择当前患者的诊断
            setSelectedRowKeys(_selectedRowKeys);
            dispatch(getCurrentDiagnosis(selectedRows))
        },
        getCheckboxProps: (record: any) => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
    const rowSelection2: any = {
        onChange: (_selectedRowKeys: React.Key[], selectedRows: []) => {
            // 选择当前患者的诊断
            setSelectedRowKeys2(_selectedRowKeys);
            dispatch(getCurrentTreatments(selectedRows))
        },
        getCheckboxProps: (record: any) => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onChange = (date: any, dateString: any, title: any) => {
        setFilterDiagnosis({
            ...filterDiagnosis,
            year: dateString,
        })

    };

    const onChangeSelect = (_value: any, key: string) => {
        if (key === 'mzyId') {
            setFilterTreatments({ mzyId: _value })
        } else {
            if (key === 'nl') {
                if (_value) {
                    const nl1 = _value > 80 ? 80 : _value - 20;
                    const nl2 = _value > 80 ? 200 : _value;
                    setFilterDiagnosis({
                        ...filterDiagnosis,
                        nl1,
                        nl2
                    })
                } else {
                    setFilterDiagnosis({
                        ...filterDiagnosis,
                        nl1: '',
                        nl2: ''
                    })
                }

            } else {
                setFilterDiagnosis({
                    ...filterDiagnosis,
                    [key]: _value,
                })
            }
        }



    }
    useEffect(() => {
        // allDiagnosis is clear currentDiagnosis
        if (allDiagnosis.length) {
            // selectedRowKeys 当前选择的在不在数组中， 在保留， 不在，删除
            const _existRow =  allDiagnosis.filter((item: any) => selectedRowKeys.includes(item.id));
            dispatch(getCurrentDiagnosis(_existRow));
        } else {
            dispatch(getCurrentDiagnosis([]));
            setSelectedRowKeys([]);
        }
    }, [allDiagnosis])


    useEffect(() => {
        // allDiagnosis is clear currentDiagnosis
        if (allTreatments.length) {
            // selectedRowKeys2 当前选择的在不在数组中， 在保留， 不在，删除
            const _existRow =  allTreatments.filter((item: any) => selectedRowKeys2.includes(item.id));
            dispatch(getCurrentTreatments(_existRow));
        } else {
            dispatch(getCurrentTreatments([]));
            setSelectedRowKeys2([]);
        }
    }, [allTreatments])

    useEffect(() => {
        // if (currentDiagnosis.length || wordCloud.diagnosis) {
        //     const _diagnosisTag = wordCloud.diagnosis && wordCloud.diagnosis.join(',') || '';
        //     let AllcurrentDiagnosisText = _diagnosisTag;
        //     currentDiagnosis.map((item: any) => AllcurrentDiagnosisText += item.zd);
        //     if (AllcurrentDiagnosisText) {
        //         dispatch(getAllTreatments(AllcurrentDiagnosisText))
        //     } else {
        //         dispatch(getAllTreatments(''))
        //         dispatch(getCurrentTreatments([]));
        //         setSelectedRowKeys2([]);
        //     }
        // } else {
        //     dispatch(getAllTreatments(''))
        //     dispatch(getCurrentTreatments([]));
        //     setSelectedRowKeys2([]);
        // }

    }, [currentDiagnosis, wordCloud.diagnosis])
    useEffect(() => {
        // submit after reset 
        if (globalLoading) {
            dispatch(getAllTreatments('', ''))
            dispatch(getCurrentDiagnosis([]))
            dispatch(getCurrentTreatments([]))
        }
    }, [globalLoading])
    const getMinZuYi = async () => {
        const data = await getMinZuYiData();
        setMzysjzd(data)
    }

    useEffect(() => {
        getMinZuYi();
    }, [])

    useEffect(() => {
        // refresh data
        dispatch(getAllDiagnosis(smartSearch?.chiefComplaint, filterDiagnosis))
    }, [filterDiagnosis, smartSearch?.chiefComplaint])

    useEffect(() => {
        // refresh data
        dispatch(getAllTreatments(smartSearch?.diagnosis, filterTreatments))
    }, [filterTreatments, smartSearch?.diagnosis])

    return (
        <div className={type ? type : 'patient-right'} style={{ display: isVisible ? 'block' : 'none' }}>
            {/* <div className='smart-title'>智能分析</div> */}
            <Collapse bordered={false} defaultActiveKey={['1', '2']}>
                <Panel header="智能诊断" key="1">
                    <Form
                        layout='inline'
                    >
                        <Form.Item label="年龄:">
                            <Select
                                showSearch
                                placeholder="选择年龄段"
                                optionFilterProp="children"
                                onChange={(value) => onChangeSelect(value, 'nl')}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                <Option value="">全部</Option>
                                <Option value="20">20岁以下</Option>
                                <Option value="40">20-40岁</Option>
                                <Option value="60">40-60岁</Option>
                                <Option value="80">60-80岁</Option>
                                <Option value="200">80岁以上</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="性别:">
                            <Select
                                // showSearch
                                placeholder="选择性别"

                                // optionFilterProp="children"
                                onChange={(value) => onChangeSelect(value, 'xb')}
                            // onSearch={onSearch}
                            // filterOption={(input, option) =>
                            //     (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            // }
                            >
                                <Option value="">全部</Option>
                                <Option value="男">男</Option>
                                <Option value="女">女</Option>
                            </Select>
                        </Form.Item >
                        <Form.Item label="年份:">
                            {
                                // @ts-ignore
                                <DatePicker
                                    picker="year"
                                    placeholder='选择时间'
                                    onChange={(data: any, dataString: any) => onChange(data, dataString, 'yarn')} />
                            }
                        </Form.Item>
                    </Form>
                    <div className='smart-content smart-content-short'>
                        {
                            allDiagnosis.length
                                ? <Table
                                    rowKey='zd'
                                    rowSelection={{
                                        type: 'checkbox',
                                        ...rowSelection,
                                        selectedRowKeys
                                    }}
                                    // expandable={{
                                    //     expandedRowRender: (record: any) =>
                                    //         <SmartAnalysesItemTable
                                    //             record={record.generalList} />
                                    // }}
                                    pagination={pagination}
                                    columns={columns}
                                    dataSource={allDiagnosis}
                                    // loading={!allDiagnosis.length}
                                />
                                : <Empty description={'没有数据'} />
                        }
                    </div>
                </Panel>
                <Panel header="治疗方案推荐" key="2">
                    <div className='smart-content smart-content-long'>
                        <Form
                            layout='inline'
                        >
                            <Form.Item label="民族医:">
                                <Select
                                    showSearch
                                    placeholder="选择民族医"
                                    optionFilterProp="children"
                                    onChange={(value) => onChangeSelect(value, 'mzyId')}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value={''}>全部</Option>
                                    {
                                        mzysjzd && mzysjzd.map((option: any) =>
                                            <Option key={option.itemId} value={option.itemId}>{option.itemName}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Form>

                        {
                            allTreatments.length
                                ? <Table
                                    rowKey='id'
                                    rowSelection={{
                                        type: 'checkbox',
                                        ...rowSelection2,
                                        selectedRowKeys: selectedRowKeys2

                                    }}
                                    pagination={pagination}
                                    columns={columns2}
                                    // loading={!allTreatments.length}
                                    dataSource={allTreatments}
                                />
                                : <Empty description={'没有数据'} />
                        }
                    </div>


                </Panel>
            </Collapse>
            {
                visibleDetail
                &&
                <SmartAnalysesItemTable
                    visible={visibleDetail}
                    title='就诊'
                    onCloseDetail={() => setVisibleDetail(false)}
                    record={defaultRecord}
                />
            }
        </div >
    )
};

export default SmartAnalyse;
