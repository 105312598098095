import { createAction, handleActions } from 'redux-actions';
import { notification } from 'antd';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/Constants';
import COMMON from '../../lib/Common';

const {
    SMART_CHIEF_COMPLAINT,
    SMART_CHIEF_COMPLAINT_TAGS,
    SMART_DIAGNOSIS_FILTER,
    SMART_DIAGNOSIS,
    SMART_DIAGNOSIS_TAGS,
    SMART_DIAGNOSIS_SELECT,
    SMART_TREATMENT_FILTER,

    SMART_DIAGNOSIS_LIST,
    SMART_DIAGNOSIS_CURRENT_LIST,
    SMART_DIAGNOSIS_DEFAULT_LIST,
    SMART_TREATMENT_LIST,
    SMART_TREATMENT_CURRENT_LIST,
    SMART_TREATMENT_DEFAULT_LIST,
    IS_SELECT_SMART_DIAGNOSIS_LIST,
    IS_SELECT_SMART_TREATMENT_LIST,
    //智能推荐弹窗
    SMART_DIAGNOSIS_VISIBLE,
    SMART_TREATMENT_VISIBLE,
    SMART_MODAL_INDEX,
    //学习中心
    STUDY_CENTER_CURRENT_DIAGNOSIS,
    STUDY_CENTER_CURRENT_TREATMENT,
    STUDY_CENTER_DELETE_DIAGNOSIS,
    STUDY_CENTER_DELETE_TREATMENT,
    //auto select 
    AUTO_SELECT_DELETE_VALUE,
    AUTO_SELECT_DELETE_TYPE,
    // 预诊智能推送
    PRE_CLINICAL_CURRENT_DIAGNOSIS,
    PRE_CLINICAL_CURRENT_TREATMENT,
    PRE_CLINICAL_DELETE_DIAGNOSIS,
    PRE_CLINICAL_DELETE_TREATMENT,
    PRE_CLINICAL_DELETE_VALUE,
    PRE_CLINICAL_DELETE_TYPE
} = CONSTANTS;
// initialState
const initialState = {
    //诊断列表参数
    smartChiefComplaint: '',
    smartChiefComplaintTags: [],
    smartDiagnosisFilter: {},
    // 治疗参数
    smartDiagnosis: {},
    smartDiagnosisTags: '',
    smartDiagnosisSelect: [],
    smartTreatmentFilter: {},
    //诊断列表
    smartDiagnosisList: [],
    smartDiagnosisCurrentList: {},
    smartDiagnosisDefaultList: [],
    //治疗列表
    smartTreatmentList: [],
    smartTreatmentCurrentList: {},
    smartTreatmentDefaultList: [],
    //是否选择智能推荐列表
    isSelectSmartDiagnosisList: false,
    isSelectSmartTreatmentList: false,
    smartDiagnosisVisible: false, // 诊断智能弹窗
    smartTreatmentVisible: false, // 治疗智能弹窗
    smartModalIndex: 0,
    // 学习中心当前选择值
    studyCenterCurrentDiagnosis: {},
    studyCenterCurrentTreatment: {},
    // 学习中心当前删除值
    studyCenterDeleteDiagnosis: {},
    studyCenterDeleteTreatment: {},
    // auto select
    autoSelectDeleteValue: {
        type: AUTO_SELECT_DELETE_TYPE.DIAGNOSIS, // 1 诊断 2 治疗
        tag: '',
    },
    // 预诊智能弹窗选择值
    preClinicalCurrentDiagnosis: {},
    preClinicalCurrentTreatment: {},
    // 预诊智能弹窗删除值
    preClinicalDeleteDiagnosis: {},
    preClinicalDeleteTreatment: {},
    //预诊删除智能分析tags
    preClinicalDeleteValue: {
        type: PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS, // 1 诊断 2 治疗
        tag: '',
    },
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        //主诉
        [SMART_CHIEF_COMPLAINT](state: State, action: Action) {
            return {
                ...state,
                smartChiefComplaint: action.payload, //主诉
            };
        },
         //主诉Tags
         [SMART_CHIEF_COMPLAINT_TAGS](state: State, action: Action) {
            return {
                ...state,
                smartChiefComplaintTags: action.payload, 
            };
        },
         //诊断过滤器
         [SMART_DIAGNOSIS_FILTER](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisFilter: action.payload, 
            };
        },
        //诊断
        [SMART_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosis: action.payload, 
            };
        },

         //诊断Tags
         [SMART_DIAGNOSIS_TAGS](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisTags: action.payload, 
            };
        },
         //诊断select
         [SMART_DIAGNOSIS_SELECT](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisSelect: action.payload, 
            };
        },

         //治疗滤器
         [SMART_TREATMENT_FILTER](state: State, action: Action) {
            return {
                ...state,
                smartTreatmentFilter: action.payload, 
            };
        },

        //all诊治列表
        [SMART_DIAGNOSIS_LIST](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisList: action.payload, 
            };
        },
        //当前选择诊治列表
        [SMART_DIAGNOSIS_CURRENT_LIST](state: State, action: Action) {
            let newData = action.payload;
            // if (newData) {
            //     newData = newData.map((item: any) => {
            //         return {
            //             ...item,
            //             jId: item.id,
            //             Jf: item.ff
            //         }
            //     })
            // }
            return {
                ...state,
                smartDiagnosisCurrentList: newData, 
            };
        },
        //默认诊治推荐
        [SMART_DIAGNOSIS_DEFAULT_LIST](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisDefaultList: action.payload, 
            };
        },
        //all治疗列表
        [SMART_TREATMENT_LIST](state: State, action: Action) {
            return {
                ...state,
                smartTreatmentList: action.payload, 
            };
        },
        //当前选择治疗列表
        [SMART_TREATMENT_CURRENT_LIST](state: State, action: Action) {
            let newData = action.payload;
            // if (newData) {
            //     newData = newData.map((item: any) => {
            //         return {
            //             ...item,
            //             jId: item.id,
            //         }
            //     })
            // }
            return {
                ...state,
                smartTreatmentCurrentList: newData, 
            };
        },
        //默认治疗列表推荐
        [SMART_TREATMENT_DEFAULT_LIST](state: State, action: Action) {
            return {
                ...state,
                smartTreatmentDefaultList: action.payload, 
            };
        },
        //是否出发列表选择（是否点击列表进行选择）
        [IS_SELECT_SMART_DIAGNOSIS_LIST](state: State, action: Action) {
            return {
                ...state,
                isSelectSmartDiagnosisList: action.payload, 
            };
        },
        [IS_SELECT_SMART_TREATMENT_LIST](state: State, action: Action) {
            return {
                ...state,
                isSelectSmartTreatmentList: action.payload, 
            };
        },
        //智能弹窗
        [SMART_DIAGNOSIS_VISIBLE](state: State, action: Action) {
            return {
                ...state,
                smartDiagnosisVisible: action.payload, //诊断智能弹窗
            };
        },
        [SMART_TREATMENT_VISIBLE](state: State, action: Action) {
            return {
                ...state,
                smartTreatmentVisible: action.payload, // 治疗智能弹窗
            };
        },
        [SMART_MODAL_INDEX](state: State, action: Action) {
            return {
                ...state,
                smartModalIndex: action.payload, // 弹窗索引
            };
        },
        //学习中心智能弹窗选择和删除值
        [STUDY_CENTER_CURRENT_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                studyCenterCurrentDiagnosis: action.payload, // 学习中心诊断
            };
        },
        [STUDY_CENTER_CURRENT_TREATMENT](state: State, action: Action) {
            return {
                ...state,
                studyCenterCurrentTreatment: action.payload, // 学习中心治疗
            };
        },
        [STUDY_CENTER_DELETE_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                studyCenterDeleteDiagnosis: action.payload, // 学习中心删除诊断
            };
        },
        [STUDY_CENTER_DELETE_TREATMENT](state: State, action: Action) {
            return {
                ...state,
                studyCenterDeleteTreatment: action.payload, // 学习中心删除治疗
            };
        },
        //学习中心删除值
        [AUTO_SELECT_DELETE_VALUE](state: State, action: Action) {
            return {
                ...state,
                autoSelectDeleteValue: action.payload, // auto select 删除的值
            };
        },
        //预诊 智能弹窗选择和删除值
        [PRE_CLINICAL_CURRENT_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                preClinicalCurrentDiagnosis: action.payload, // 学习中心诊断
            };
        },
        [PRE_CLINICAL_CURRENT_TREATMENT](state: State, action: Action) {
            return {
                ...state,
                preClinicalCurrentTreatment: action.payload, // 学习中心治疗
            };
        },
        [PRE_CLINICAL_DELETE_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                preClinicalDeleteDiagnosis: action.payload, // 学习中心删除诊断
            };
        },
        [PRE_CLINICAL_DELETE_TREATMENT](state: State, action: Action) {
            return {
                ...state,
                preClinicalDeleteTreatment: action.payload, // 学习中心删除治疗
            };
        },
         //预诊删除值
        [PRE_CLINICAL_DELETE_VALUE](state: State, action: Action) {
            return {
                ...state,
                preClinicalDeleteValue: action.payload, // auto select 删除的值
            };
        },

    },
    initialState
);

export const actions = {
    //设置主诉，主诉tags 诊断过滤器
    setSmartChiefComplaint: createAction(SMART_CHIEF_COMPLAINT),
    setSmartChiefComplaintTags: createAction(SMART_CHIEF_COMPLAINT_TAGS),
    setSmartDiagnosisFilter: createAction(SMART_DIAGNOSIS_FILTER),

    //设置诊断，诊断tags， 诊断选择, 治疗过滤器
    setSmartDiagnosis: createAction(SMART_DIAGNOSIS),
    setSmartDiagnosisTags: createAction(SMART_DIAGNOSIS_TAGS),
    setSmartDiagnosisSelect: createAction(SMART_DIAGNOSIS_SELECT),
    setSmartTreatmentFilter: createAction(SMART_TREATMENT_FILTER),

    //获取诊断list
    getAllDiagnosisList: createAction(SMART_DIAGNOSIS_LIST, async (searchValue: string | undefined,  filter: any) => {
        let _data = searchValue ? await APIServices.getDiagnosisByChiefComplaint(searchValue, filter) : [];
        return _data;
    }),
    setSmartDiagnosisCurrentList: createAction(SMART_DIAGNOSIS_CURRENT_LIST),
    setSmartDiagnosisDefaultList: createAction(SMART_DIAGNOSIS_DEFAULT_LIST),
    //获取治疗list
    getAllTreatmentsList: createAction(SMART_TREATMENT_LIST, async (searchValue: string | undefined,  filter: any) => {
        let _data = searchValue ? await APIServices.getTreatmentByDiagnosis(searchValue, filter) : [];
        return _data;
    }),
    setSmartTreatmentsCurrentList: createAction(SMART_TREATMENT_CURRENT_LIST),
    setSmartTreatmentsDefaultList: createAction(SMART_TREATMENT_DEFAULT_LIST),
    setIsSelectSmartDiagnosisList: createAction(IS_SELECT_SMART_DIAGNOSIS_LIST),
    setIsSelectSmartTreatmentList: createAction(IS_SELECT_SMART_TREATMENT_LIST),
    //设置诊断智能分析
    setSmartDiagnosisVisible: createAction(SMART_DIAGNOSIS_VISIBLE),
    setSmartTreatmentVisible: createAction(SMART_TREATMENT_VISIBLE),
    // 智能弹窗索
    setSmartModalIndex: createAction(SMART_MODAL_INDEX),
    // 学习中心
    //智能弹窗选择的诊断 治疗
    setStudyCenterCurrentDiagnosis: createAction(STUDY_CENTER_CURRENT_DIAGNOSIS),
    setStudyCenterCurrentTreatment: createAction(STUDY_CENTER_CURRENT_TREATMENT),
    setStudyCenterDeleteDiagnosis: createAction(STUDY_CENTER_DELETE_DIAGNOSIS),
    setStudyCenterDeleteTreatment: createAction(STUDY_CENTER_DELETE_TREATMENT),
    // 学习中心删除tags 
    setAutoSelectDeleteValue: createAction(AUTO_SELECT_DELETE_VALUE),
    // 预诊记录
    setPreClinicalCurrentDiagnosis: createAction(PRE_CLINICAL_CURRENT_DIAGNOSIS),
    setPreClinicalCurrentTreatment: createAction(PRE_CLINICAL_CURRENT_TREATMENT),
    setPreClinicalDeleteDiagnosis: createAction(PRE_CLINICAL_DELETE_DIAGNOSIS),
    setPreClinicalDeleteTreatment: createAction(PRE_CLINICAL_DELETE_TREATMENT),
    // 学习中心删除tags 
    setPreClinicalDeleteValue: createAction(PRE_CLINICAL_DELETE_VALUE),
};
