
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Picker,
  Space,
  Toast,
  Cascader,
  DatePicker,
  TextArea,
} from "antd-mobile";
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import { actions as globalAction } from '../../redux/modules/global';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import Common from '../../lib/Common';
const { onScanCodeAddCaseInfo } = APIServices;

const { } = globalAction;
const { NATIONS, GENDER_WOMAN, GENDER } = Constants;
const { getQueryString } = Common;
interface Props {


}
const initDefaultValue = {
    // particularHistory: 0,
    // seeDoctor: 0,
    gender: GENDER_WOMAN, // 2是男 
    // cardNumber: '610521199504050322',
    // recordDate: moment(new Date(), 'YYYY-MM-DD'),
    presentIllnessHistory: '无',
    pastHistory: '无',
    allergyHistory: '无',
}
const Gender = [
    [
      { label: "男", value: "0" },
      { label: "女", value: "1" },
    ],
  ];
const RACE = NATIONS.map((item) =>( { label: item, value: item }))
const FormInfo = (props: Props) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [options, setOptions] = useState<any>([]);
    const [showSave, setShowSave] = useState(false);
    const [openTreatmentIndex, setTreatmentIndex] = useState(false);
    const [patientInfo, setPatientInfo] = useState<any>({});
    const caseHistoryList = [{
        label: '主诉',
        key: 'chiefComplaint',
        require: true
    },{
        label: '现病史',
        key: 'presentIllnessHistory',
        require: true
    },{
        label: '既往史',
        key: 'pastHistory',
        require: true
    },{
        label: '特殊病史',
        key: 'particularHistoryText',
        require: false
    },{
        label: '过敏史',
        key: 'allergyHistory',
        require: false
    },{
        label: '月经史',
        key: 'menstruationHistory',
        require: false
    },{
        label: '婚育史',
        key: 'marriageHistory',
        require: false
    },{
        label: '手术史',
        key: 'operation',
        require: false
    },{
        label: '家族史',
        key: 'familyHistory',
        require: false
    }]
    // 编辑患者信息
    const onFinish = (values: any) => {
        const hospitalName = decodeURI(getQueryString('hospitalId'));
        values.gender = values.gender[0];
        values.race = values.race[0];
        values.birthday =  moment(values.birthday).format('YYYY-MM-DD');

        const newData = { ...values, hospitalName };
        onScanCodeAddCaseInfo(newData).then((res: any) => {
            if (res) {
                form.resetFields();
                Toast.show({
                    icon: 'success',
                    content: '提交成功',
                    afterClose: () => {
                         navigate('/scanCode/result', {
            state: {
                baseInfo: newData
            }
        })
                    },
                })
            }
        }).catch((err) => {
            Toast.show({
                icon: 'fail',
                content: '提交失败',
            })
        })
       
    };

    const onReset = () => {
        form.resetFields();
        setPatientInfo({});
    };
    const onFinishFailed = (error: any) => {
        console.error(error);
        const { errorFields } = error;
        Toast.show({
                content: errorFields[0].errors[0],
            })
    };

    const checkMobile = (_: any, value: any) => {
        if (value) {
          if(value.length === 11) {
            return Promise.resolve()
          }else{
            if(value.length < 11) {
                return Promise.reject(new Error('手机号不足11位!'))
            } else {
                return Promise.reject(new Error('手机号超过11位!'))
            }
          }
          
        }
    };

    const checkIdCode = (val: any) => {
        var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ]
        var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ]
        var code = val.substring(17)
        if (p.test(val)) {
          var sum = 0
          for (var i = 0; i < 17; i++) {
            sum += val[i] * factor[i]
          }
          if (parity[sum % 11] == code.toUpperCase()) {
            return true
          }
        }
        return false
      }

    const checkCareNumber = (_: any, value: any) => {
        if (value) {
          if(value.length === 18) {
            if(checkIdCode(value)) {
                return Promise.resolve()
            }else {
                return Promise.reject(new Error('身份证不合法！'))
            }
          }else{
            if(value.length < 18) {
                return Promise.reject(new Error('身份证位号码短'))
            } else {
                return Promise.reject(new Error('身份证位号码短长'))
            }
          }
          
        }
    }
    useEffect(() => {
        window.document.title = '填写就诊信息';
    })
    return (
        <div className="mobile-bg">
            <div className="mobile-ht">请如实填写</div>
            <Form
                requiredMarkStyle="asterisk"
                className="page-add-form"
                name="form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                footer={
                <div className="btn-box">
                    <Button
                    block
                    type="submit"
                    color="primary"
                    size="large"
                    className="btn"
                    >
                    提交
                    </Button>
                </div>
                }
            >
                 <Form.Item name="patientName" label="姓名" rules={[{ required: true, message: `患者姓名不能为空` }]}>
                    <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item 
                    name="cardNumber" 
                    label="身份证号码" 
                    rules={[
                        { required: true, message: `身份证不能为空` },
                        { validator: checkCareNumber }
                        ]}>
                    <Input placeholder="身份证号码" />
                </Form.Item>

                <Form.Item
                    name="tel"
                    label="电话"
                    rules={[
                        { required: true ,message: `电话不能为空`},
                        { validator: checkMobile }
                    ]}
                >
                    <Input type='number' maxLength={11} placeholder="请输入电话" />
                </Form.Item>
                <Form.Item
                    name='birthday'
                    label='生日'
                    trigger='onConfirm'
                    rules={[{ required: true, message: `生日不能为空` }]}
                    onClick={(e, datePickerRef: any) => {
                        datePickerRef.current?.open()
                    }}
                    >
                    <DatePicker  min={new Date('1800')} max={new Date()}>
                        {value =>
                        value ? moment(value).format('YYYY-MM-DD') : '请选择日期'
                        }
                    </DatePicker>
                </Form.Item>
                <Form.Item
                    name="gender"
                    label="性别"
                    rules={[{ required: true, message: `性别不能为空` }]}
                    trigger="onConfirm"
                    onClick={(e, _ref: any) => {
                    _ref.current?.open();
                    }}
                >
                    <Picker
                    columns={Gender}
                    // value={value}
                    // onConfirm={setValue}
                    // onSelect={(val, extend) => {
                    //     console.log('onSelect', val, extend.items)
                    // }}
                    >
                    {(items, { open }) => {
                        return (
                        <Space align="center">
                            {items.every((item) => item === null)
                            ? "未选择"
                            : items
                                .map((item) => item?.label ?? "未选择")
                                .join(" - ")}
                        </Space>
                        );
                    }}
                    </Picker>
                </Form.Item>
                <Form.Item
                    name="race"
                    label="民族"
                    rules={[{ required: true, message: `民族不能为空` }]}
                    trigger="onConfirm"
                    onClick={(e, _ref: any) => {
                    _ref.current?.open();
                    }}
                >
                    <Picker
                    columns={[RACE]}
                    // value={value}
                    // onConfirm={setValue}
                    // onSelect={(val, extend) => {
                    //     console.log('onSelect', val, extend.items)
                    // }}
                    >
                    {(items, { open }) => {
                        return (
                        <Space align="center">
                            {items.every((item) => item === null)
                            ? "未选择"
                            : items
                                .map((item) => item?.label ?? "未选择")
                                .join(" - ")}
                        </Space>
                        );
                    }}
                    </Picker>
                </Form.Item>
                {
                    caseHistoryList.map((option: any) => (
                    <Form.Item key={option.key} name={option.key} label={option.label} rules={[{ required: option.require, message: `${option.label}不能为空` }]}>
                        <TextArea placeholder={`请输入${option.label}`} />
                    </Form.Item>
                    ))
                }
              
                <div className="form-item-card">
               
                </div>
                <div></div>
            </Form>
        </div>
    )
};

export default FormInfo;
