import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import createRootReducer from './createReducers';


const middlewares = [
    promiseMiddleware,
];
const rootReducer = createRootReducer();

export default function configStore() {
    const store = createStore(rootReducer, applyMiddleware(...middlewares));
    return store;
}
