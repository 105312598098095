
import DiagnosisIndex from '../pages/diagnosis/diagnosisIndex';
import SameTechnology from '../pages/sameTechnology/sameTechnology';
// import Standards from '../pages/standards/oldStandards';
import Standards from '../pages/standards/standards';
import TalentTrain from '../pages//talentTrain/talentTrain';
// import TalentTrain from '../pages/talentTrain/oldTalentTrain';
import ResultTransform from '../pages/resultTransform/resultTransform';
import AuxiliaryDiagnosis from '../pages/auxiliaryDiagnosis/auxiliaryDiagnosis';

const menuListString: any = sessionStorage.getItem("menuList");
const mockData = [{
    enCode: 'diagnosis',
    fullName: '辅助诊断'
},{
    enCode: 'auxiliaryDiagnosis',
    fullName: '辅助诊断2'
},{
    enCode: 'sameTechnology',
    fullName: '共性技术'
},{
    enCode: 'standards',
    fullName: '标准制定'
},{
    enCode: 'talentTrain',
    fullName: '人才培养'
},{
    enCode: 'resultTransform',
    fullName: '成果转化'
}]
//配置导航菜单
// const menuList =  mockData;
const menuList = JSON.parse(menuListString) || [];
const basicMenu = [
    { path: 'diagnosis', label: '辅助诊断',  component: <DiagnosisIndex />},
    { path: 'auxiliaryDiagnosis', label: '辅助诊断',  component: <AuxiliaryDiagnosis />},
    { path: 'sameTechnology', label: '共性技术',  component: <SameTechnology />},
    { path: 'standards', label: '标准制定',  component: <Standards />},
    { path: 'talentTrain', label: '人才培养',  component: <TalentTrain />},
    { path: 'resultTransform', label: '成果转化',  component: <ResultTransform />},
];
const dataSet = menuList.map((item: any) => {
    const target: any = basicMenu.find((basic: any) =>basic.path == item.enCode);
    if(target) {
        return {
            ...target,
            label: item.fullName ||  target.label
        }
    }else{
        return false
    }
}).filter(Boolean);

export default dataSet;