import React, {useEffect, useState} from 'react';
import {Spin, Alert, Form, Input, Button, message} from 'antd';
import {Navigate} from 'react-router-dom';
import './login.css';

import Oidc from 'oidc-client';

import Common from '../../lib/Common';
import {login} from '../../utils/AuthService'
import APIServices from '../../lib/APIServices';

const {getData} = Common;
const {getAuthorize} = APIServices;
const Login = () => {
    const [loginState, setLoginState] = useState(false)
    const getMenu = () => {
        const jcknowledgeGraph = 6;
        getAuthorize(jcknowledgeGraph).then((res) => {
            if (typeof res == 'string') {
                message.warn(res);
                return false;
            } else {
                if (res.length > 0) {
                    // 存储标准制定菜单
                    const arrayToTree = (arr, pid) => {
                        const treeData = arr.reduce((res, current) => {
                            if (current['parentId'] === pid) {
                                current.children = arrayToTree(arr, current['moduleId']);
                                return res.concat(current);
                            }
                            return res;
                        }, []);
                        return treeData;
                    };
                    const allMenu = arrayToTree(res, null);
                    const standardsMenu = allMenu.find((item) => item.enCode == 'standards').children;
                    sessionStorage.setItem("standardsMenu", JSON.stringify(standardsMenu));
                    sessionStorage.setItem("menuList", JSON.stringify(res));
                    const preDocurlString = sessionStorage.getItem("locationPathname");
                    const preDocurl = preDocurlString && preDocurlString.substring(2, preDocurlString.length - 1) || '';
                    const docurl = preDocurl || '/homeIndex';
                    window.location.href = docurl;
                } else {
                    message.warn('暂无菜单权限！');
                    return false;
                }
            }
        })
    }

    useEffect(() => {
        // const docurl = window.location.origin + '/home';
        // window.location.href = docurl;
        if (!sessionStorage.getItem("token") && !getData('id_token')) {
            login();
        } else if (sessionStorage.getItem("token")) {
            // const docurl = window.location.origin + '/home';
            // window.location.href = docurl;
            getMenu()
        } else {

            let mgr = new Oidc.UserManager({
                userStore: new Oidc.WebStorageStateStore(),
                loadUserInfo: true,
                filterProtocolClaims: true
            });
            mgr.signinRedirectCallback().then(function (user) {
                sessionStorage.setItem("token", user.access_token);
                sessionStorage.setItem("userName", user.profile.name);
                sessionStorage.setItem("profile", JSON.stringify(user.profile));
                // const docurl = window.location.origin + '/home';
                // window.location.href = docurl;
                getMenu()
            }).catch(function (err) {
                console.log(err);
            });
        }
    }, [])
    return (
        <div className='login'>
            <div className='box'>
                <Spin tip="" size="large"></Spin>
            </div>
        </div>
    )
};

export default Login;
