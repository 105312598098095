import React, { useState, useEffect } from 'react';
import { Drawer, Tabs } from 'antd';
import ChuFangCollection from './ChuFangCollection';
const { TabPane } = Tabs;

interface Props {
    record: any
    onCancel: () => void
    visible: boolean
    title:string
}
const FangYaoDetail = (props: Props) => {
  const { record ,visible , onCancel, title} = props;
  const [values, setValues] = useState<any>([]); //方药详情
  const [tB_FYYYGF_CFZC, setTB_FYYYGF_CFZC] = useState([]); //处方组成

   useEffect(() => {
      const { tB_FYYYGF, tB_FYYYGF_CFZC} = record;
      setValues(tB_FYYYGF);
      setTB_FYYYGF_CFZC(tB_FYYYGF_CFZC);
   }, [record])
   
  return (
    <Drawer
      title={title}
      onClose={() => {
        onCancel();
      }}
      className='detail-container'
      width={'50%'}
      visible={visible}
    >
      <Tabs defaultActiveKey='1'>
        <TabPane tab="基本信息" key="1">
          <div className="titBox">
            <div><span>民族医:</span> <span className="textBox">{values && values.mzyObjectIDName || ''}</span></div>
            <div><span>中文名称:</span> <span className="textBox">{values &&  values.cName || ''}</span></div>
            <div><span>拼音名称:</span> <span className="textBox">{values &&  values.cpName || ''}</span></div>
            <div><span>英文名称:</span> <span className="textBox">{values &&  values.eName || ''}</span></div>
            <div><span>本族名称:</span> <span className="textBox">{values &&  values.hName || ''}</span></div>
            <div><span>简介:</span> <span className="textBox">{values &&  values.jj || ''}</span></div>
          </div>
        </TabPane>
        {
            // 处方组成
            <TabPane tab="组方依据" key="2">
               <ChuFangCollection record={tB_FYYYGF_CFZC} />
            </TabPane> 
        }
        {
          values && values.zfyj ?
            <TabPane tab="组方依据" key="3">
              <div dangerouslySetInnerHTML={{ __html: values.zfyj }}></div>
            </TabPane> : null
        }
        {
           values && values.lcyy ?
            <TabPane tab={'临床应用'} key="4">
              <div>{values.lcyy }</div>
            </TabPane> : null
        }
        {
          values && values.pzff ?
            <TabPane tab={'配制方法'} key="5">
              <div>{values.pzff}</div>
            </TabPane> : null
        }
        {
          values && values.zysx ?
            <TabPane tab="注意事项" key="6">
              <div dangerouslySetInnerHTML={{ __html: values.zysx }}></div>
            </TabPane> : null
        }
        {
          values && values.ycqkjcl ?
            <TabPane tab="异常情况及处理措施" key="7">
              <div dangerouslySetInnerHTML={{ __html: values.ycqkjcl }}></div>
            </TabPane> : null
        }
        {
          values && values.jjz ?
            <TabPane tab="禁忌症" key="8">
              <div dangerouslySetInnerHTML={{ __html: values.jjz }}></div>
            </TabPane> : null
        }
        {
          values && values.syff ?
            <TabPane tab="使用方法" key="9">
              <div dangerouslySetInnerHTML={{ __html: values.syff }}></div>
            </TabPane> : null
        }
         {
          values && values.llyyJsybj ?
            <TabPane tab="理论依据及使用背景" key="10">
              <div dangerouslySetInnerHTML={{ __html: values.llyyJsybj }}></div>
            </TabPane> : null
        }
         {
          values && values.ckwx ?
            <TabPane tab="参考文献" key="11">
              <div dangerouslySetInnerHTML={{ __html: values.ckwx }}></div>
            </TabPane> : null
        }
      </Tabs>

    </Drawer>
  )
};
export default FangYaoDetail;
