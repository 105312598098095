import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Steps, Spin, Collapse, Col, Row, Menu, Popover, Alert } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import Common from '../../lib/Common';
import ChartPage from './components/ChartPage';
import './resultTransform.css';
import PreviewForm from '../../components/Resources/components/PreviewForm';

import moment from 'moment';
const { getResultTransformList } = APIServices;
const {FILE_IMG} = Constants;
const {byteChange} = Common

const ResultTransform = () => {
    const [resultTransformList, setResultTransformList] = useState<any>([]);
    const [previewModalVisible, handlePreviewModalVisible] = useState(false);
    const [values ,setValues] = useState<any>({});
    const [initData, setInitData]  = useState<any>([]);
    const [menuData, setMenuData]  = useState<any>([]);
    const [menuTitle, setMenuTitle]  = useState<any>('课题一');
    const [tabTitle, setTabTitle]  = useState<any>('知识产权');
    const [fileCount, setFileCount]  = useState<any>(0);
    const [tabData, setTabData]  = useState<any>([]);
    const [currentTableData, setCurrentTableData]  = useState<any>([]);
    const [menuEnCode, setMenuEnCode] = useState<any>('cgzh_kt1');
    const [tabEnCode, setTabEnCode] = useState<any>('cgzh_kt1_zscq');

    const getList = async () => {
        const data = await APIServices.getResultTransformList();
        setResultTransformList(data[0].children);//
        const _menuData = data[0].children.map((item: any) => {
            return {
                label: item.fullName,
                key: item.enCode,
            }
        })
        setMenuData(_menuData);// 设置目录
        setInitData(data[0].children);
    }

    const columns: any = [
        {
            title: '文件名称',
            dataIndex: 'fileName',
            key: 'fileName',
            // align: 'center',
            width: '40%',
            render: (text: any, record: any) => <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div><img className='file-img' src={FILE_IMG[record.fileType] || ''} /></div>
                <span>{text}</span>
                </div>,
        },
        {
            title: '类型', 
            dataIndex: 'fileType',
            key: 'fileType',
            align: 'center',
            // render: (text: number) => <span>{TYPE_NAME[Number(text) - 1]}</span>,
        },
        {
            title: '大小', 
            dataIndex: 'fileSize',
            key: 'fileSize',
            align: 'center',
            render: (text: any) => <span>{text ? byteChange(text) : '-'}</span>,
        },
        {
            title: '作者',
            dataIndex: 'rancreateUserNamee',
            key: 'createUserName',
        },
        {
            title: '时间',
            dataIndex: 'createDate',
            key: 'createDate',
            render: (text: string) => <span>{moment(text).format('YYYY-MM-DD')}</span>,
        },
        {
            title: '详情',
            key: 'ttbw',
            align: 'center',
            render: (text: number, record: any) => {
                // 提示不支持CAJ
                const openDetailPage = () => {
                     handlePreviewModalVisible(true);
                    setValues(record)
                }
                return (<Button  type="primary" size="middle"  disabled={record.fileType == 'caj'} onClick={openDetailPage} icon={<EyeOutlined style={{ fontSize: 24,  }} />}>
                    <span style={{ verticalAlign: 'top'}}> { record.fileType !== 'caj' ? '在线预览': '工具浏览'} </span>
                </Button>)
            },
        }];
 
    
    const getCurrentTableData = () => {
        //filter 当前menu item
        const _menuData = initData.find((item: any) => item.enCode === menuEnCode) || '';
        if(_menuData) {
        //获取当前tab item
        const _tabData = _menuData.children.find((item: any) => item.enCode === tabEnCode) || '';
        setTabData(_menuData.children);
        // 获取item 数据
        const _currentTableData =_tabData &&  _tabData.fileList || [];
        const _fileCount = _tabData && _tabData.countFile;
        setCurrentTableData(_currentTableData);
        setFileCount(_fileCount);
        }    
    };

    const onSelect = (item: any) => {
        const { key} = item;
        setMenuEnCode(key);
        setTabEnCode(`${key}_zscq`)
        const  currentMenu = menuData && menuData.find((item: any) => item.key === key );
        const {label} = currentMenu;
        setMenuTitle(label);
        setTabTitle('知识产权')
    };

    const onClickTab = (item: any) => {
        setTabEnCode(item.enCode)
        setTabTitle(item.fullName)
    }
    useEffect(() => {
        getList();
    }, [])

    useEffect(() => {
        initData.length && getCurrentTableData();
    }, [menuEnCode,tabEnCode, initData])

    return(
        <div className='result-transform-container'>
            <div className='result-transform-menu'>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['cgzh_kt1']}
                    onClick={onSelect}
                    // style={{ width: 256 }}
                    items={menuData}
                />
            </div>
            <div className='result-transform-bg'>
                    <div className='result-transform-tab'>
                        {/* 菜单标题 */}
                        <div className='result-transform-tab-title'>
                            <h3>{menuTitle}</h3>
                        </div>
                        {/* tab 导航 */}
                        <div className='result-transform-tab-btn'>
                        {
                            tabData &&
                            tabData.map((item: any) => (<Button type='text' className={`tab-btn-title ${item.enCode == tabEnCode && 'tab-btn-title-active'}`} key={item.enCode} onClick={() =>onClickTab(item)}>{item.fullName}({item.countFile})</Button>))
                        }
                        {
                            menuEnCode === 'cgzh_kt5'
                            &&
                            <Button type='text' className={`tab-btn-title ${'cgzh_kt5_chart'== tabEnCode && 'tab-btn-title-active'}`} key={'cgzh_kt5_chart'} onClick={() =>onClickTab({enCode: 'cgzh_kt5_chart', fullName: '图表汇总'})}>{'图表汇总'}</Button>
                        }
                        </div>
                       
                    </div>
                    {
                        tabEnCode === 'cgzh_kt5_chart'
                        ?   <ChartPage />
                        :   <div className='result-content-container'>
                                <div className='result-transform-tip'>
                                     <p>共有<span className='count'>{fileCount}</span>份{tabTitle}文件 </p>
                                </div>
                                <div className='result-transform-table'>
                                        <Table
                                            rowKey='id'
                                            scroll={{
                                                x: '100%'
                                            }}
                                            columns={columns}
                                            // expandable={{ expandedRowRender }}
                                            dataSource={currentTableData}
                                        />
                                </div>
                            </div>
                    }
            </div>
                {
                    previewModalVisible 
                    &&
                    <PreviewForm 
                    previewModalVisible
                    values={values}
                    onCancel={() => {
                        handlePreviewModalVisible(false);
                        setValues({})
                    }}
                    />
                }
        </div>
    )
};

export default ResultTransform;
