import { Steps, Tag, Divider, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import APIServices from '../../../../lib/APIServices';
import { actions as globalAction } from '../../../../redux/modules/global';
import Constants from '../../../../lib/Constants';
import AutoSelect from '../../../../components/AutoSelect';

const { GET_MAIN_TYPE } = Constants;
const { CheckableTag } = Tag;
const { setWordCloud } = globalAction;
const { getAllWords } = APIServices;

const wordType = [{
    title: '主诉',
    key: 'chiefComplaint',
}, {
    title: '诊断',
    key: 'diagnosis',
}, {
    title: '处置',
    key: 'cz',
},]

interface Props {
    type?: string
    getSearchValue: (_value: any) => void
}
const WordCloud = (props: Props) => {
    const { type, getSearchValue } = props;
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [allWords, setAllWords] = useState<any>([]);
    const [selectWord, setSelectWord] = useState<any>({
        chiefComplaint: [],
        diagnosis: [],
        cz: [],
    });
    const [isVisible, setIsVisible] = useState<any>(true);
    const [selectValue, setSelectValue] = useState<any>({
        chiefComplaint: '',
        diagnosis: '',
        cz: '',
    });
    const [searchValue, setSearchValue] = useState<any>({
        chiefComplaint: '',
        diagnosis: '',
        cz: '',
    });
    //redux smart analyses data 
    const { studyCenterDiagnosis, studyCenterTreatments } = useSelector((state: any) => {
        return {
            studyCenterDiagnosis: state.global.studyCenterDiagnosis,
            studyCenterTreatments: state.global.studyCenterTreatments
        }
    });

    const handleChange = (tag: any, checked: any, title: string) => {
        // 根据选择判断添加还是删除tag
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t: any) => t !== tag);
        setSelectedTags(nextSelectedTags);
        const _targetType: any = wordType.find((_type: any) => _type.title === title);
        if (_targetType) {
            const currentTag = selectWord[_targetType.key] || [];
            const nextSelectedTags = checked ? currentTag.concat([tag]) : currentTag.filter((t: any) => t !== tag);
            setSelectWord({
                ...selectWord,
                [_targetType.key]: nextSelectedTags,
            })
        }

    };
    const getCurrentSmartList = (title: string ) => {
        if(title === '症状') {
            return studyCenterDiagnosis && studyCenterDiagnosis.map((item: any) => item.zd)
        }else if(title === '处置') {
            return studyCenterTreatments && studyCenterTreatments.map((item: any) => item.tName)
            // return []
        }else {
            return []
        }
    }
    const refreshData = async () => {
        const data = await getAllWords() || []
        const list = data && data.splice(0, 2); // 去掉处置
        setAllWords(list);
    };
    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        if (selectValue || selectWord.length) {
            setSearchValue({
                chiefComplaint: `${selectValue.chiefComplaint}`,
                diagnosis: `${selectValue.diagnosis}`,
                cz: `${selectValue.cz}`,
            })
        }
    }, [selectValue, selectWord]);

    useEffect(() => {
        getSearchValue(searchValue);
    }, [searchValue])

    return (
        <Col span={12} className={type ? type : 'patient-word-cloud'} style={{ display: isVisible ? 'block' : 'none' ,marginTop:'18px'}}>
            {
                allWords.map((item: any, index: number) => (
                    <div className='word-cloud-box' key={item.title}>
                        <div className='word-cloud-label'>{item.title}</div>
                        <div className='word-cloud-bd'>
                            <div className='word-cloud-type'>
                                <AutoSelect
                                    type={index + 1}
                                    label={item.title}
                                    onGetValue={(_value) => { setSelectValue({ ...selectValue, [wordType[index].key]: _value }) }}
                                    wordList={item.wordList}
                                    smartList={getCurrentSmartList(item.title)}
                                />
                            </div>
                           
                            {/* <div className='tags'>

                                {item.wordList.map((tag: string) => (
                                    <CheckableTag
                                        key={tag}
                                        checked={selectedTags.indexOf(tag) > -1}
                                        onChange={checked => handleChange(tag, checked, item.title)}
                                    >
                                        {tag}
                                    </CheckableTag>
                                ))}
                            </div> */}
                        </div>
                    </div>
                ))
            }

        </Col>
    );
}

export default WordCloud;