import React, { useState, useEffect } from 'react';
import { Layout, Input, Tabs, Menu } from 'antd';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import { useLocation} from 'react-router'

import Diagnosis from '../diagnosis/diagnosis';
import Treatment from '../treatment/treatment';
import PatientCase from '../cases/patientCase';

import DiagnosisIndex from '../diagnosis/diagnosisIndex';

import dataSet from '../../config/routerData';
import './home.css';
import AvatarDropdown from '../layout/components/AvatarDropdown';

const history = createBrowserHistory();
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;
const Home = (props: any) => {
    const [searchValue, setSearchValue] = useState('');
    const [defaultSelectedKeys ,  setDefaultSelectedKeys] = useState('');
    let navigate = useNavigate();
    const location = useLocation();
    const menuItems = dataSet.map((item: {
        path: string,
        label: string
    }) => {
        return {
            key: item.path,
            label: item.label,
        }
    })
    // 点击路由跳转
    const onClickHeaderMenu = (key: string) => {
        navigate(`/home/${key}`);
    };
    useEffect(() => {
        // 刷token
        const accessToken = sessionStorage.getItem('token');
        if(!accessToken) {
            navigate(`/login`);
        }
    })
   
    return (
        <div className='home-page'>
            <div className='header'>
                <img src="/images/logo.png" alt="" />
                {/* <span className='title'><img src="/logo.png" alt="" /> 民族医智慧医test疗平台</span> */}
                <AvatarDropdown />
            </div>
            <div className="wrap">
		
                <div onClick={() => onClickHeaderMenu('resultTransform')} className="item cgzh" style={{'left':'96px','top':'513px','zIndex':8}}>
                    <img src="images/t-cgzh.png" alt="" />
  
                </div>
                <div onClick={() => onClickHeaderMenu('sameTechnology')} className="item gxjs" style={{'right':'277px','top':'514px','zIndex':7}}>
                    <img src="images/t-gxjs.png" alt="" />
                </div>
                <div onClick={() => onClickHeaderMenu('auxiliaryDiagnosis')} className="item zhzl" style={{'right':'18px','top':'422px','zIndex':6}}>
                    <img src="images/t-zhzl.png" alt="" />
                </div>
                <div onClick={() => onClickHeaderMenu('standards')} className="item bzzd" style={{'left':'274px','bottom':'164px','zIndex':9}}>
                    <img src="images/t-bzdz.png" alt="" />

                </div>
                <div onClick={() => onClickHeaderMenu('talentTrain')} className="item rcpy" style={{'left':'96px',bottom:'172px','zIndex':5}}>
                    <img src="images/t-rcpy.png" alt="" />

                </div>
                <img src="images/1.png" alt="" className="sl" />
                    
            </div>
        </div>
    )
};

export default Home;
