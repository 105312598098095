import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Steps, Spin, Tree, Menu, Col, Row, List, Card} from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import PreviewForm from './components/PreviewForm';
import UserDetail from './components/UserDetail';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import './talentTrain.css';
import moment from 'moment';

const { Step } = Steps;
const { getSearchTreatment, getTalentTrainMenuTree, getTalentTrainList,getTalentTrainNewList, getTalentTrainMenuList, getStandardsMenuTree} = APIServices;
const { TYPE_NAME, STEPS, FILE_IMG, USER_TYPE } = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;
const {inheritance,behalf, lead } = USER_TYPE;
const TalentTrain = (props: any) => {
    const {  } =  props;
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const [searchValue, setSearchValue] = useState('')
    const [TreatmentData, setTreatmentData] = useState([]);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [current, setCurrent] = React.useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);
    
    const [treeData ,setTreeData] = useState<any>([]);
    const [dataSource ,setDataSource] = useState<any>([]);
    const [previewModalVisible, handlePreviewModalVisible] = useState(false);
    const [values ,setValues] = useState<any>({});
    const [subjectid , setSubjectid] = useState<any>('');
    const [menuData , setMenuData] = useState<any>([]);
    const [activeMenu, setActiveMenu] = useState<any>({
        label:'',
        key: '',
        countUser: ''
    });
    const [trainUserList, setTrainUserList] = useState<any>([]); //培养人
    const [trainUserCategory, setTrainUserCategory] = useState<any>({
        leadUserList: [], //带头人
        behalfUserList: [], // 代表人
        inheritanceUserList:[], // 传承人
    }); //培养人分类
    const [visibleUserDetail, setVisibleUserDetail] = useState(false);
    const [userRecord, setUserRecord] = useState({});
    const [pagination, setPagination] = useState<any>({
        pageSize: 10,
        pageNum: 1,
        total: 0
    });

    const onSearch = (value: any, event: any) => {
        getTalentList(subjectid, value);
    }

    const onSearchMenu = (value: string) => {
        const newMenuData = treeData.filter((item: any) => item.label.includes(value) || item.pName.includes(value));
        setMenuData(newMenuData)
    }
    const onCloseDetail = (_visible: boolean) => {
        setVisibleDetail(_visible)
        
    };

    const onChange = (_pagination: any) => {
        setPagination({
          pageNum: _pagination.current,
          pageSize: _pagination.pageSize,
        })
      };

    // const onSelect = (node: any) => {
    //     // getList(node[0], '');
    //     setSubjectid(node[0])
    //     setPagination({
    //         ...pagination,
    //         pageNum: 1,
    //     })
    // }
    const getList = async (_subjectid: string,keywords: string) => {
        const { list, pagination: _pagination} =await getTalentTrainList({ offset: (pagination.pageNum - 1) * pagination.pageSize, size: pagination.pageSize , subjectid: _subjectid, keywords});
        setDataSource(list)
        setPagination({
            ...pagination,
            total: _pagination.records
        })

    };

    const getTalentList = async (_subjectid: string,keywords: string) => {
        const { list, pagination: _pagination} = await getTalentTrainNewList({ offset: (pagination.pageNum - 1) * pagination.pageSize, size: pagination.pageSize , subjectid: _subjectid, keywords});
        setDataSource(list)
        setPagination({
            ...pagination,
            total: _pagination.records
        })

    };
    
    const  getMenuInitData = (data: any, pName: string) => {
        return data.map((item: any) => {
            if(item.children && item.children.length > 0) {
             return getMenuInitData(item.children, item.fullName)
            }else {
                return {
                    ...item,
                    label: item.fullName,
                    key: item.moduleId,
                    pName,
                    countUser: item.countUser,
                };
            }
        })
        
    }
    const getMenuData = (data: any, pName: string) => {
        return data.map((item: any) => {
            if(item.children && item.children.length > 0) {
             return getMenuData(item.children, item.fullName)
            }else {
                return {
                    label: item.fullName,
                    key: item.moduleId,
                    pName,
                    countUser: item.countUser,
                };
            }
        })
        
    }
    const refresh = async () => {
         // 根据menuId 来获取菜单
        // const data = await getTalentTrainMenuTree(); //左侧菜单目录
        const data = await getTalentTrainMenuList();
            // setTreeData([ {fullName: '全部', moduleId: data[0].moduleId}, ...data[0].children]);
        const initMenuData = getMenuInitData(data[0].children, '').flat();
        setTreeData(initMenuData)
        const _menuData = getMenuData(data[0].children, '').flat();
        setMenuData(_menuData)
        setSubjectid(_menuData[0].key) // 获取第一条数据
        // 默认培养人
        setTrainUserList(initMenuData[0].userList);
        setTrainUserCategory({
            inheritanceUserList: initMenuData[0].userList.filter((item: any) => item.uType ===inheritance ) || [], // 传承人
            behalfUserList: initMenuData[0].userList.filter((item: any) => item.uType === behalf ) || [], // 代表人
            leadUserList: initMenuData[0].userList.filter((item: any) => item.uType === lead ) || [], //带头人
        })
        setActiveMenu(_menuData[0]);
       
    }
    const onSelect = (item: any) => {
       const { key, label} = item;
      
        setSubjectid(key)
        setPagination({
            ...pagination,
            pageNum: 1,
        })
        //设置当前目录
        setActiveMenu(item)
        // 设置当前培养人
        treeData.map((item: any) => {
            if(item.moduleId === key) {
                setTrainUserList(item.userList || []);// 设置培养人
                setTrainUserCategory({
                    inheritanceUserList: item.userList.filter((item: any) => item.uType ===inheritance ) || [], // 传承人
                    behalfUserList: item.userList.filter((item: any) => item.uType === behalf ) || [], // 代表人
                    leadUserList: item.userList.filter((item: any) => item.uType === lead ) || [], //带头人
                })
            }
        })
      };

    const openDetailPage = (record: any) => {
        handlePreviewModalVisible(true);
        setValues(record)
    }

    const onShowUserDetail = (record: any) => {
        setVisibleUserDetail(true);
        setUserRecord(record)
    }

    const onOpenChange = (keys: any) => {
        const key = keys.length ? keys[keys.length - 1]: '';
        if(key) {
            setSubjectid(key)
            setPagination({
                ...pagination,
                pageNum: 1,
            })
        }
        
    };


    useEffect(() => {
        refresh();
    }, []); 


    useEffect(() => {
        subjectid && getTalentList(subjectid, '');
    }, [subjectid, pagination.pageNum]); 

    return (
        <div className='talent-container'>
            {/* menu */}
          <div className='talent-menu'>
            {/* 过滤目录 */}
                <div className='menu-input'>
                        <Input.Search 
                            placeholder="请输入关键词" 
                            allowClear 
                            onSearch={onSearchMenu} 
                            // style={{ width: 280 }}
                            
                            size="large" 
                        />
                </div>
                <Row gutter={16} className='menu-list-box'>
                    {
                        menuData && menuData.length > 0
                        && 
                        menuData.map((item: any) => 
                        <Col span={12} key={item.key} onClick={() => onSelect(item)}>
                            <div className={`menu-box ${subjectid === item.key &&  'menu-box-active'}`}>
                                <div  className='menu-p-name'>{item.pName}</div>
                                <div className={`menu-label ${subjectid === item.key &&  'menu-label-active'}`}>{item.label}</div>
                                <div  className='menu-user-count'>
                                    <span className='menu-user-title' >培养人数</span>
                                    <span className='menu-user-title' >{item.countUser}</span>
                                </div>
                            </div>
                        </Col>)
                    }
                </Row>
                
          </div>
           <div className='talent-content'>
                <div className='top-input'>
                    <div className='tip'> <span className='menu-title'>{activeMenu.label}</span> 共有<span className='file-count'>{pagination.total}</span>份文件</div>
                    <Input.Search 
                        placeholder="请输入关键词" 
                        allowClear 
                        onSearch={onSearch} 
                        loading={loadingStatus} 
                        style={{ width: 280 }}  
                        size="large" 
                    />
                </div>
                <div className='tab-box'>
                    {
                        loadingStatus
                            ? <Spin tip={current ? STEPS[current].title : STEPS[0].title} size="large" spinning={loadingStatus} className='tip'>
                            </Spin>
                            :
                            // <Table 
                            // loading={loadingStatus} 
                            // rowKey='id' 
                            // dataSource={dataSource} 
                            // columns={columns} 
                            // pagination={pagination}
                            // expandable={{ expandedRowRender }}
                            // onChange={onChange}
                            // />
                            <List
                                grid={{ gutter: 16, column: 6 }}
                                dataSource={dataSource}
                                pagination={{
                                    onChange: page => {
                                    console.log(page);
                                    },
                                    pageSize: 18,
                                }}
                                renderItem={(item: any) => (
                              <List.Item>
                                <div className='file-box' onClick={() => openDetailPage(item)}>
                                    <img src={FILE_IMG[item.fileType]} className='file-img'/>
                                    <div className='file-name'>{item.fileName}</div>
                                    <div className='file-date'>{moment(item.createDate).format('YYYY-MM-DD')}</div>
                                </div>
                              </List.Item>
                            )}
                          />
                    }
                </div>
                 {/* 带头人 */}
                 <div className='train-user'>
                    <div className='train-user-title'>{activeMenu.label}--带头人员({trainUserCategory.leadUserList && trainUserCategory.leadUserList.length || 0})</div>
                    <div className='train-user-list-box'>
                        {
                            trainUserCategory.leadUserList && trainUserCategory.leadUserList.length ? trainUserCategory.leadUserList.map((item: any) => {
                                return <div key={item.id} className='train-user-list' onClick={() => onShowUserDetail(item)}>
                                    <img src="/userVisit.png" alt=""  className='train-user-img'/>
                                    <span className='train-user-name'>{item.userName}</span>
                                </div>
                            })
                            :''
                        }
                    </div>
                </div>
                 {/* 传承人 */}
                 <div className='train-user'>
                    <div className='train-user-title'>{activeMenu.label}--传承人员({trainUserCategory.inheritanceUserList && trainUserCategory.inheritanceUserList.length || 0})</div>
                    <div className='train-user-list-box'>
                        {
                            trainUserCategory.inheritanceUserList && trainUserCategory.inheritanceUserList.length ? trainUserCategory.inheritanceUserList.map((item: any) => {
                                return <div key={item.id} className='train-user-list' onClick={() => onShowUserDetail(item)}>
                                    <img src="/userVisit.png" alt=""  className='train-user-img'/>
                                    <span className='train-user-name'>{item.userName}</span>
                                </div>
                            })
                            :''
                        }
                    </div>
                </div>
                {/* {代表人} */}
                <div className='train-user'>
                    <div className='train-user-title'>{activeMenu.label}--代表人员({trainUserCategory.behalfUserList && trainUserCategory.behalfUserList.length || 0})</div>
                    <div className='train-user-list-box'>
                        {
                            trainUserCategory.behalfUserList && trainUserCategory.behalfUserList.length ? trainUserCategory.behalfUserList.map((item: any) => {
                                return <div key={item.id} className='train-user-list' onClick={() => onShowUserDetail(item)}>
                                    <img src="/userVisit.png" alt=""  className='train-user-img'/>
                                    <span className='train-user-name'>{item.userName}</span>
                                </div>
                            })
                            :''
                        }
                    </div>
                </div>
           </div>
           {
            previewModalVisible 
            &&
            <PreviewForm 
            previewModalVisible
            values={values}
            onCancel={() => {
                handlePreviewModalVisible(false);
                setValues({})
            }}
            />
           }

            {
            visibleUserDetail 
            &&
            <UserDetail 
            visibleUserDetail
            record={userRecord}
            onCancel={() => {
                setVisibleUserDetail(false)
            }}
            />
           }
        </div>
    )
};

export default TalentTrain;
