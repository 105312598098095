
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { MapChart, EffectScatterChart ,BarChart  } from 'echarts/charts';
import {  TooltipComponent, LegendComponent,TitleComponent  } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import APIServices from '../../../lib/APIServices';

const { geChartForResultTrans } = APIServices;
echarts.use([MapChart, BarChart ]);
echarts.use([CanvasRenderer, TooltipComponent , LegendComponent,TitleComponent ]);

const  ResultTransFormChart = () =>{
    const ref: any = useRef(null);
    let mapInstance = null;
   
    const convertData = (initData: any, key: string) => {
        let convert: any = [];
        initData.map((item: any) => {
            if(item.children && item.children.length > 0) {
              //找encode是zscq的countFile
              const target = item.children.find((childItem: any) => childItem.enCode.includes(key)) || '';
             if(target) {
                convert.push(target.countFile)
             }
            }
        })

        return convert
    }
    const renderChart = async () => {
    // 获取数据
    const _data = await geChartForResultTrans();
    const xAxisData = _data.map((item: any) => item.fullName);

    // 基于准备好的dom，初始化echarts实例
    mapInstance = echarts.init(ref.current);
    mapInstance.setOption( {
        // backgroundColor:'#323a5e',
           tooltip: {
             trigger: 'axis',
             axisPointer: { // 坐标轴指示器，坐标轴触发有效
               type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
             }
           },
           title: {
            show: true, //是否显示标题
            text: '成果转化', //标题名称
            left: 'center',
            textStyle: {
                // fontSize: 35,
                color: '#1890ff'
            },
            top: '10px'
            },
            grid: {
                left: '2%',
                right: '4%',
                bottom: '14%',
                top:'16%',
                containLabel: true
            },
            legend: {
                show: false,
                data: ['知识产权', '软著证书', '论文'],
                right: 10,
                top:12,
                textStyle: {
                    color: "#fff"
                },
                itemWidth: 12,
                itemHeight: 10,
                // itemGap: 35
            },
           xAxis: {
             type: 'category',
             data: xAxisData,
             axisLine: {
               lineStyle: {
                 color: '#1890ff'
   
               }
             },
             axisLabel: {
               // interval: 0,
               // rotate: 40,
               textStyle: {
                 fontFamily: 'Microsoft YaHei'
               }
             },
           },
   
           yAxis: {
             type: 'value',
            //  max:'20',
             axisLine: {
               show: false,
               lineStyle: {
                 color: '#1890ff'
               }
             },
             splitLine: {
               show: true,
               lineStyle: {
                 color: 'rgba(255,255,255,0.5)'
               }
             },
             axisLabel: {}
           },
          
           series: [{
             name: '知识产权',
             type: 'bar',
             barWidth: '25%',
             itemStyle: {
               normal: {
                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                       offset: 0,
                       color: '#fccb05'
                   }, {
                       offset: 1,
                       color: '#f5804d'
                   }]),
                   barBorderRadius: 12,
               },
             },
             data: convertData(_data, 'zscq')
           },
           {
             name: '软著证书',
             type: 'bar',
             barWidth: '25%',
             itemStyle: {
               normal: {
                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                       offset: 0,
                       color: '#8bd46e'
                   }, {
                       offset: 1,
                       color: '#09bcb7'
                   }]),
                   barBorderRadius: 11,
               }
               
             },
             data: convertData(_data, 'rzzs')
           },
           {
             name: '论文',
             type: 'bar',
             barWidth: '25%',
             itemStyle: {
               normal: {
                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                       offset: 0,
                       color: '#248ff7'
                   }, {
                       offset: 1,
                       color: '#fff'
                   }]),
               barBorderRadius: 11,
               }
             },
             data: convertData(_data, 'lw')
           }]
         })
    
  };

    useEffect(() => {
        renderChart();
    }, []);
  

    return (
        <div style={{  height: "400px",}} ref={ref}></div>
    );
}

export default  ResultTransFormChart;