import moment from 'moment';
const getData = (name: any) => {
    let urlArr = window.location.href.split("#");
    let data = '';
    if (urlArr.length > 1) {
        let dataArr = urlArr[1].split("&");
        for (let i = 0; i < dataArr.length; i++) {
            let url = dataArr[i].split("=");
            if (url[0] == name) {
                data = url[1];
                return data;
            }
        }
    }
    return data;
}

// 计算年龄
const getAge = (strBirthday: string) =>{
    strBirthday = moment(strBirthday).format('YYYY-MM-DD');
    var returnAge,
    strBirthdayArr=strBirthday.split("-"),
    birthYear = Number(strBirthdayArr[0]),
    birthMonth = Number(strBirthdayArr[1]),
    birthDay = Number(strBirthdayArr[2]),
    d = new Date(),
    nowYear = d.getFullYear(),
    nowMonth = d.getMonth() + 1,
    nowDay = d.getDate();
    if(nowYear == birthYear){
        returnAge = 0;//同年 则为0周岁
    }
    else{
        var ageDiff = nowYear - birthYear ; //年之差
        if(ageDiff > 0){
        if(nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay;//日之差
            if(dayDiff < 0) {
            returnAge = ageDiff - 1;
            }else {
            returnAge = ageDiff;
            }
        }else {
            var monthDiff = nowMonth - birthMonth;//月之差
                if(monthDiff < 0) {
                returnAge = ageDiff - 1;
                }
                else {
                returnAge = ageDiff ;
                }
            }
            }else {
            returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
            }
        }
        return returnAge; // 返回周岁年龄
}

// 字节转换为kb. mb,gb
const  byteChange = (bytes: any)  =>{
    if (isNaN(bytes)) {
        return ''
    };

    const symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let exp = Math.floor(Math.log(bytes) / Math.log(2));
    if (exp < 1) {
        exp = 0;
    }
    const i = Math.floor(exp / 10);
    bytes = bytes / Math.pow(2, 10 * i);

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2);
    }

    return bytes + ' ' + symbols[i];
};
const getQueryString: any = (name: string) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}
export default {
    getData,
    getAge,
    byteChange,
    getQueryString
};