import React, { useEffect, useState } from 'react';
import { Table, Input, Steps, Spin, Tree , Collapse, Button} from 'antd';

import APIServices from '../../../lib/APIServices';
import Constants from '../../../lib/Constants';
import '../sameTechnology.css'
import { extendWith } from 'lodash';

const { Panel } = Collapse;
const { Step } = Steps;
const { getSameTechMenuTree,  getSameTreatDisease, getSameMaterial ,getSameOperation,  getSameContraindications, getSameRecipe} = APIServices;
const { STEPS } = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;
const SameTableTemp = (props: any) => {
    const [searchValue, setSearchValue] = useState('');
    const [casesData, setCasesData] = useState([]);
    const [count, setCount] = useState<number>(0);
    const [current, setCurrent] = React.useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const { tabKey, titleName } = props;
    const [treeData ,setTreeData] = useState<any>([]);
    const [dataSource ,setDataSource] = useState<any>([]);
    const [subjectid , setSubjectid] = useState<any>('');
    const [defaultExpandedRowKeys , setDefaultExpandedRowKeys] = useState<any>('')
    const [showActiveAll, setShowActiveAll] = useState<any>('');
    const [pagination, setPagination] = useState<any>({
        pageSize: 10,
        pageNum: 1,
        total: 0
    });
    const columns: any = [
        {
            title: titleName.name,
            dataIndex: titleName.value,
            key: titleName.value,
            align: 'center',
        },
        {
            title: '病例数',
            dataIndex: 'techniqueList',
            key: 'techniqueList',
            align: 'center',
            render: (text: any) => <span>{text.length}</span>,
        },
    ];

    //嵌套表格
    const expandedRowRender = (record: any) => {
        const columns2 = [
          { title: '技法', dataIndex: 'tName', key: 'tName' },
          {
            title: '操作方法',
            dataIndex: 'czff',
            key: 'czff',
          },
          { 
            title: '类型', 
            dataIndex: 'tType', 
            key: 'tType',   
            render: (type: any) => <span>{Number(type) === 1 ? '方药': '技法'}</span>
        },
          { title: '禁忌症', dataIndex: 'jjz', key: 'jjz' },
          { title: '治疗范围', dataIndex: 'zlfw', key: 'zlfw' },
          { title: '处方组成', dataIndex: 'cfzc', key: 'cfzc' , hidden: true},
          { title: '准备材料', dataIndex: 'zbcl', key: 'zbcl' },
        ];
    
        const _dataSource = record.techniqueList || [];
        return _dataSource.length > 0 ? <Table rowKey='tName'  columns={columns2} dataSource={_dataSource} pagination={false} />: null;;
      }; 

    const onSearch = (value: any, event: any) => {
      
    }
    const onSelect = (node: any) => {
        // getList(node[0], '');
        setSubjectid(node[0])
    }
    const refresh = async () => {
      // 1. 先换取菜单
      const treeMenu = await getSameTechMenuTree();
      setTreeData([{mName: '全部', id: '0'}, ...treeMenu])
       // 2. 在获取数据
    // const data = await getSameTreatDisease();
    // setDataSource(data)
       
   }

   const getList =async (id:any) => {
    // 2. 在获取数据
    if( tabKey === 'treatDisease') {
        const data = await getSameTreatDisease(id);
        setDataSource(data)
    }

    if( tabKey === 'material') {
        const data = await getSameMaterial(id);
        setDataSource(data)
    }

    if( tabKey === 'operation') {
        const data = await getSameOperation(id);
        setDataSource(data)
    }

    if( tabKey === 'contraindications') {
        const data = await getSameContraindications(id);
        setDataSource(data)
    }

    if( tabKey === 'recipe') {
        const data = await getSameRecipe(id);
        setDataSource(data)
    }
   };

    useEffect(() => {
        refresh();
    }, []); 

    useEffect(() => {
        subjectid === '0' ? getList('') :  getList(subjectid);
    }, [subjectid]); 

    
    useEffect(() => {
        if(dataSource.length > 0) {
            setDefaultExpandedRowKeys(dataSource[0].id);
        }
    }, [dataSource]); 

    return (
        <div className='table-container'>
             <div className='table-menu'>
                {
                    treeData && treeData.length > 0
                    && 
                    <Tree
                        defaultSelectedKeys={['0']}
                        onSelect={onSelect}
                        defaultExpandAll
                        treeData={treeData}
                        fieldNames={{
                            title: 'mName',
                            key: 'id'
                        }}
                    />
                }
             </div>
             <div className='table-content'>
                <Collapse defaultActiveKey={['0']} >
                    {
                        dataSource.map((item: any, index: number) =>  <Panel header={`${item[titleName.value]}    (相关性-${item.techniqueList && item.techniqueList.length }例)`} key={index}>
                        {
                            item.techniqueList.map((content: any, order: number) => {
                                return (
                                    <div key={content.id} className='content-container'>
                                        <p>
                                            <span><b>名称：</b>{content.tName}</span>
                                            <span><b>类型：</b>{Number(content.type) === 1 ? '方药': '技法'}</span>
                                        </p>
                                        {
                                            content.czff
                                            &&
                                            <div className='content-text'>
                                                <p className={showActiveAll ===`${index}-${order}-1` ? 'showAll' : 'showLit'}><b>操作方法：</b>{content.czff}</p>
                                                <Button className={content.czff.length > 85 ? 'showBtn': 'hiddenBtn'} type='link' onClick={() => { showActiveAll ===`${index}-${order}-1` ? setShowActiveAll('')  :setShowActiveAll(`${index}-${order}-1`)}}>
                                                    { showActiveAll ===`${index}-${order}-1`? '关闭': '展开'}
                                                </Button>
                                            </div>
                                        }
                                        {
                                            content.jjz
                                            &&

                                            <div className='content-text'>
                                                <p className={showActiveAll ===`${index}-${order}-2` ? 'showAll' : 'showLit'}><b>禁忌症：</b>{content.jjz}</p>
                                                <Button className={content.jjz.length > 85 ? 'showBtn': 'hiddenBtn'} type='link' onClick={() => { showActiveAll ===`${index}-${order}-2` ? setShowActiveAll('')  :setShowActiveAll(`${index}-${order}-2`)}}>
                                                    { showActiveAll ===`${index}-${order}-2`? '关闭': '展开'}
                                                </Button>
                                            </div>
                                        }
                                        
                                        {
                                            content.cfzc
                                            &&

                                            <div className='content-text'>
                                                <p className={showActiveAll ===`${index}-${order}-3` ? 'showAll' : 'showLit'}><b>处方组成：</b>{content.cfzc}</p>
                                                <Button className={content.cfzc.length > 85 ? 'showBtn': 'hiddenBtn'} type='link' onClick={() => { showActiveAll ===`${index}-${order}-3` ? setShowActiveAll('')  :setShowActiveAll(`${index}-${order}-3`)}}>
                                                    { showActiveAll ===`${index}-${order}-3`? '关闭': '展开'}
                                                </Button>
                                            </div>
                                        }

                                        {
                                            content.zlfw
                                            &&
                                            <div className='content-text'>
                                                <p className={showActiveAll ===`${index}-${order}-4` ? 'showAll' : 'showLit'}><b>治疗范围：</b>{content.zlfw}</p>
                                                <Button className={content.zlfw.length > 85 ? 'showBtn': 'hiddenBtn'}  type='link' onClick={() => { showActiveAll ===`${index}-${order}-4` ? setShowActiveAll('')  :setShowActiveAll(`${index}-${order}-4`)}}>
                                                    { showActiveAll ===`${index}-${order}-4`? '关闭': '展开'}
                                                </Button>
                                            </div>   
                                        }
                                        
                                        {
                                            content.zbcl
                                            &&
                                            <div className='content-text'>
                                                <p className={showActiveAll ===`${index}-${order}-5` ? 'showAll' : 'showLit'}><b>准备材料：</b>{content.zbcl}</p>
                                                <Button className={content.zbcl.length > 85 ? 'showBtn': 'hiddenBtn'} type='link' onClick={() => { showActiveAll ===`${index}-${order}-5` ? setShowActiveAll('')  :setShowActiveAll(`${index}-${order}-5`)}}>
                                                    { showActiveAll ===`${index}-${order}-5`? '关闭': '展开'}
                                                </Button>
                                            </div>  
                                        }
                                        
                                    </div>
                                )
                            })
                        }
                    </Panel>)
                    }
                
                </Collapse>
             </div>
        </div>
    )
};

export default SameTableTemp;
