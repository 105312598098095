import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button } from 'antd';

import CompositionList from './compositionList';
import Constants from '../../../lib/Constants';
const { TYPE_NAME } = Constants;
interface Props {
    visible: boolean
    record: any
    onCloseDetail: (_visible: boolean) => void
}
const TreatmentDetail = (props: Props) => {
    const { visible, record, onCloseDetail } = props;

    const _textAndHtmlLabel = [{
        label: '名称',
        name: 'tName',
        isHtml: false
    }, {
        label: '范围',
        name: 'rane',
        isHtml: false
    }, {
        label: '定义',
        name: 'definition',
        isHtml: false
    }, {
        label: '方药临床应用',
        name: 'clinicalApplication',
        isHtml: false
    }, {
        label: '方药配置方法',
        name: 'configurationMethod',
        isHtml: false
    }, {
        label: '注意事项',
        name: 'precautionsHtml',
        isHtml: true
    }, {
        label: '异常情况及处理措施',
        name: 'exceptionHandlerHtml',
        isHtml: true
    }, {
        label: '禁忌症',
        name: 'contraindicationsHtml',
        isHtml: true
    }];

    const _arrLabel = [{
        label: '方药处方组成',
        name: 'composition'
    }, {
        label: '操作方法',
        name: 'operationMethod'
    }, {
        label: '常见病操作技术',
        name: 'commonDiseases'
    }];

    const columns: any = [{
        title: '名称',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        width: 200,
        render: (text: any) => (<span style={{ whiteSpace: 'nowrap' }}>{text}</span>)
    },
    {
        title: '描述',
        dataIndex: 'describeText',
        key: 'describeText',
        // align: 'center',
    }];

    const onClose = () => {
        onCloseDetail(false);
    };
    const arrayToTree = (arr: any, pid: string) => {
        const treeData = arr.reduce((res: any, current: any) => {
            if (current['parentId'] === pid) {
                current.children = arrayToTree(arr, current['id']);
                current.children.length === 0 && delete current.children
                return res.concat(current);
            }
            return res;
        }, []);
        return treeData;
    };

    return (
        <Drawer
            title={`${TYPE_NAME[record.type - 1]}详情`}
            placement="right"
            onClose={onClose}
            width={'80%'}
            visible={visible}
        >
            {
                record && _textAndHtmlLabel.map((item) => {
                    if (record[item.name]) {
                        return (
                            <Row key={item.label}>
                                <Col span={4}>
                                    <span className='chuFangBoxTitle'>{item.label}: </span>
                                </Col>
                                <Col span={20}>
                                    {
                                        item.isHtml
                                            ? <div dangerouslySetInnerHTML={{ __html: record[item.name] }} />
                                            : <p>{record[item.name] || '无'}</p>
                                    }
                                </Col>
                            </Row>
                        )
                    }
                })
            }

            {
                _arrLabel.map((item: { label: string, name: string }, index: number) => {
                    if (record[item.name]) {
                        return (<div key={index}>
                            <h2>{item.label}</h2>
                            {
                                item.name == 'composition' ?
                                    <CompositionList record={record[item.name]} />
                                    :
                                    <Table
                                        rowKey={(record) => record.id}
                                        expandable={{
                                            defaultExpandAllRows: true,
                                            rowExpandable: (record) => record.children.length > 0,
                                        }}
                                        className='bottom-card'
                                        dataSource={arrayToTree(record[item.name], '')}
                                        columns={columns}
                                    />
                            }

                        </div>)
                    }
                })
            }
        </Drawer>
    );
};

export default TreatmentDetail;