import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button, Tag, List, Card } from 'antd';
import { useSelector } from 'react-redux';

import SmartAnalyse from '../../../cases/components/SmartAnalyse';
import AddCase from '../AddPatientInfo/AddCase';
import APIServices from '../../../../lib/APIServices';
import Common from '../../../../lib/Common';
const { getAge } = Common;
const { getCaseDetail } = APIServices;
interface Props {
    visible: boolean
    record: any
    onClose: () => void
    title: string
}
const CaseDetail = (props: Props) => {
    const { visible, record, onClose, title } = props;

    const [caseValue, setCaseValue] = useState<any>();

    const onGetCaseDetail =async () => {
        const value = await getCaseDetail(record.id);
        if(JSON.stringify(value[0]) !== '{}') {
            setCaseValue(value[0])
        }
    };
    useEffect(() => {
        onGetCaseDetail();
    }, [record])
    
    return (
        <Drawer
            title={'预诊记录'}
            placement="right"
            onClose={onClose}
            width={'80%'}
            visible={visible}
        >
            {
                caseValue
                &&
                <div className='mini-case-detail-container'>
                    <h3><b>患者信息:</b></h3>
                    <div className='base-info'>
                        <Row>
                            <Col span={8}><span>姓名: </span>{caseValue.patientName}</Col>
                            <Col span={8}><span>年龄: </span>{getAge(caseValue.birthday)}</Col>
                            <Col span={8}><span>性别: </span>{Number(caseValue.gender) ? '女': '男'}</Col> 
                        </Row>
                        <Row>
                            <Col span={8}><span>电话: </span>{caseValue.tel}</Col>
                            <Col ><span>身份证: </span>{caseValue.cardNumber}</Col>
                        </Row>
                        <Row>
                            <Col>
                            <span>病名: </span>{caseValue.diseaseName || ''}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <span>主诉: </span>{caseValue.chiefComplaint}
                            </Col>
                        </Row>
                    </div>
                    <h3><b>问诊记录: </b></h3>
                    {
                        caseValue.replyInfo
                        ?
                        <div>
                         <List
                            size="small"
                            // header={<div>Header</div>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={caseValue.replyInfo}
                            renderItem={(item: any, index: number) => 
                            <List.Item>
                                <div style={{ width: '70%'}}>{index + 1}. {item.quName}</div>
                                <p>答: {item.anName}</p>
                            </List.Item>}
                            />
                    </div>
                    : '暂无'
                    }
                </div>
            }
           
        </Drawer>
    );
};

export default CaseDetail;