import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button, Tag, Divider, List } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Common from '../../../../lib/Common';

// import CompositionList from './compositionList';
import APIServices from '../../../../lib/APIServices';
import Constants from '../../../../lib/Constants';
import '../../diagnosis.css';

const {GENDER_WOMAN} = Constants;
const { getAge } = Common;
const { getCaseDetail } = APIServices;
interface Props {
    title: string
    visible: boolean
    record: { id: string }
    onCloseDetail: () => void
}
const DoctorRecordDetail = (props: Props) => {
    const [dataSource, setDataSource] = useState([]);
    const [recordValue, setRecordValue] = useState<any>({});
    const { title, visible, record, onCloseDetail } = props;
    const [loading, setLoading] = useState(true);


    const getCurrentRecord = async (_id: string) => {
        const value = await getCaseDetail(record.id);
        if(JSON.stringify(value[0]) !== '{}') {
            setRecordValue(value[0])
        }
        setLoading(false);
    };
    const getTags = (item: any, data: any) => {
        const { tagName } = item;
        return tagName.map((tag: any) => {
            if (typeof (data[tag]) === 'string') {
                const _tags = data[tag] && data[tag].split(',');
                return _tags && _tags.map((_item: string) => _item && <span key={_item} color="success">{_item}</span>)

            } else {
                return data[tag] && data[tag].map((_item: any) => <span key={_item.id} color="success">{_item.zd || _item.tName}</span>)
            }
        })
    }
    useEffect(() => {
        getCurrentRecord(record.id);
    }, [record])

    return (
        <Drawer
            title={`${title}`}
            placement="right"
            onClose={onCloseDetail}
            width={'80%'}
            visible={visible}
        >
            {/* <Table rowKey='id' dataSource={dataSource} columns={columns} loading={loading} /> */}
            {
                recordValue
                && 
                <div className='case-detail-container'>
                <Row justify='center'>
                    <h2>病例记录</h2>
                </Row>
                {/* 患者信息 */}

                <Row justify='space-between'>
                    <Col span={6}><span>姓名:</span> {recordValue.patientName} </Col>
                    <Col span={6}><span>年龄:</span> {recordValue.birthday ? getAge(recordValue.birthday): ''} </Col>
                    <Col span={6}><span>性别:</span> {Number(recordValue.gender) === GENDER_WOMAN ? '女' : '男'} </Col>
                    <Col span={6}><span>名族:</span> {recordValue.race} </Col>
                </Row>
                <Row>
                    <Divider style={{ backgroundColor: '#000'}} />
                </Row>
                {/* 病例 */}
                <Row wrap={false} className='row'>
                    <Col className='row-label'>就诊时间： </Col>
                    <Col className='row-value'>{moment(recordValue.recordDate).format('YYYY-MM-DD hh:mm:ss') }</Col>
                </Row>
                <Row wrap={false} className='row'>
                    {/* chiefComplaint 主诉  initialDiagnosisTags 主诉tags*/}
                    <Col className='row-label'>病名： </Col>
                    <Col>
                    {recordValue.diseaseName || ''}
                    </Col>
                </Row>
                <Row wrap={false} className='row'>
                    {/* chiefComplaint 主诉  initialDiagnosisTags 主诉tags*/}
                    <Col className='row-label'>主诉： </Col>
                    <Col>
                    {recordValue.chiefComplaint}
                    {
                        getTags({ tagName: ['initialDiagnosisTags'] }, recordValue)
                    }
                    </Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>特殊病史： </Col>
                    <Col>{recordValue.particularHistoryText}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>现病史： </Col>
                    <Col>{recordValue.presentIllnessHistory}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>既往史： </Col>
                    <Col>{recordValue.pastHistory}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>过敏史： </Col>
                    <Col>{recordValue.allergyHistory}</Col>
                </Row>
                
                
                {/* 诊断  诊断的tags 诊断的智能集合*/}
                <Row justify='space-between' className='row'>
                    <Col>
                         <Row wrap={false} className='row'>
                            <Col className='row-label'>诊断： </Col>
                            {/* initialDiagnosis 诊断 diagnosis diagnosisTag 诊断tags */}
                            <Col>
                            {recordValue.initialDiagnosis}
                            {
                                getTags({ tagName: ['diagnosis', 'diagnosisTag'] }, recordValue)
                            }
                            </Col>
                         </Row>
                    </Col>
                </Row>

                {/* 处置  处置的tags 处置的智能集合*/}
                <Row justify='space-between' className='row'>
                    <Col>
                         <Row wrap={false} className='row'>
                            <Col className='row-label'>处置： </Col>
                            {/* cz 处置 treatments treatmentsTag 处置tags */}
                            <Col>
                              {recordValue.cz}
                              {
                                getTags({ tagName: ['treatments', 'treatmentsTag'] }, recordValue)
                            }
                            </Col>
                         </Row>
                    </Col>
                </Row>
                {/* 是否有 replyInfo 问诊问题*/}
                {
                    recordValue.replyInfo
                    &&
                    <List
                    size="small"
                    header={<div>问诊记录</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={recordValue.replyInfo}
                    renderItem={(item: any, index: number) => 
                    <List.Item>
                        <div style={{ width: '70%'}}>{index + 1}. {item.quName}</div>
                        <p>答: {item.anName}</p>
                    </List.Item>}
                    />
                }
            </div>

            }
           
        </Drawer>
    );
};

export default DoctorRecordDetail;