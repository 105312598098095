
import {Col, Row, Form, Table, Button, Tag, List } from 'antd';
import '../preCase.css'
import TextArea from 'antd/lib/input/TextArea';

interface Props {
    record: any
}

const CaseBasic = (props: Props) => {
    const {  record } = props;
    return (
        <>
        <div className='case-history'>
            {/* 特殊病史： */}
            <Row gutter={16}>
             <Col span={24}>
               <Form.Item
                 name="particularHistoryText"
                 label="特殊病史："
                 rules={[{ required: false, message: '特殊病史' }]}
               >
                 <TextArea placeholder="请输入特殊病史" />
               </Form.Item>
             </Col>
            {/* 既往史： */}
            <Col span={24}>
               <Form.Item
                 name="pastHistory"
                 label="既往史："
                 rules={[{ required: false, message: '既往史' }]}
               >
                 <TextArea placeholder="请输入既往史" />
               </Form.Item>
             </Col>
            </Row>

          {/* 现病史： */}
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item
                    name="presentIllnessHistory"
                    label="现病史："
                    rules={[{ required: false, message: '现病史' }]}
                >
                    <TextArea placeholder="请输入现病史" />
                </Form.Item>
                </Col>
                {/* 过敏史： */}
                <Col span={24}>
                <Form.Item
                    name="allergyHistory"
                    label="过敏史："
                    rules={[{ required: false, message: '过敏史' }]}
                >
                    <TextArea placeholder="请输入过敏史" />
                </Form.Item>
                </Col>
            </Row>

          {/* 月经史 */}
          <Row gutter={16}>
             <Col span={24}>
               <Form.Item
                 name="menstruationHistory"
                 label="月经史："
                 rules={[{ required: false, message: '月经史' }]}
               >
                 <TextArea placeholder="请输入月经史" />
               </Form.Item>
             </Col>
              {/* 婚育史 */}
              <Col span={24}>
               <Form.Item
                 name="marriageHistory"
                 label="婚育史"
                 rules={[{ required: false, message: '婚育史' }]}
               >
                 <TextArea placeholder="请输入婚育史" />
               </Form.Item>
             </Col>
         </Row>
          {/* 手术史： */}
          <Row gutter={16}>
             <Col span={24}>
               <Form.Item
                 name="operation"
                 label="手术史："
                 rules={[{ required: false, message: '手术史：' }]}
               >
                 <TextArea placeholder="请输入手术史：" />
               </Form.Item>
             </Col>
              {/* 家族史： */}
              <Col span={24}>
               <Form.Item
                 name="familyHistory"
                 label="家族史："
                 rules={[{ required: false, message: '家族史：' }]}
               >
                 <TextArea placeholder="请输入家族史：" />
               </Form.Item>
             </Col>
         </Row>
        </div>
        <div className='case-record'>
                <Row className='case-title-row'>
                    <Button className='case-title-btn' disabled>预诊问答</Button>
                </Row>
               {
                   record.szReply
                   ?
                    <List
                        size="small"
                        className='case-list'
                        dataSource={record.szReply}
                        renderItem={(item: any, index: number) => 
                        <List.Item>
                            <div>
                                <div>{index + 1}. {item.quName}</div>
                                {
                                    item.anName
                                    && 
                                    <div>答: {item.anName}</div>
                                }
                            </div>
                        </List.Item>}
                    />
                   : <div>
                       <h3>预诊问答</h3>
                       <span>无</span>
                   </div>
               }
            </div>
        </>
    );
};

export default CaseBasic;