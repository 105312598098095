import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Table, Input, Steps, Spin, Form, Button, Tag, Divider, Row, Col, Radio, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { actions as globalAction } from '../../../../redux/modules/global';
import { actions as casesAction } from '../../../../redux/modules/cases';
import APIServices from '../../../../lib/APIServices';
import Constants from '../../../../lib/Constants';
// import PatientInfo from './PatientInfo';
// import CasesInfoDetail from './CasesInfoDetail';
import AutoSearchInput from '../../../../components/AutoSearchInput';
import AutoSelect from '../../../../components/AutoSelect';
import CheckableTag from 'antd/lib/tag/CheckableTag';
// import '../../cases/components/patientCase.css';

const { getAllDiagnosis, getAllTreatments , setGlobalLoading } = globalAction;
const {  getPatientAllDiseaseInfo, getDoctorRecord } = casesAction;
const { Step } = Steps;
const { getAllWords, createPatientDiagnosis, updatePatientDiagnosis,getNewCaseDetail,  createNewPatientDiagnosis, updateNewPatientDiagnosis} = APIServices;
const { STEPS } = Constants;
const getDataType = {
    'CHIEF_COMPLAINT': 1,
    'INITIAL_DIAGNOSIS': 2,
    'CZ': 3
};

const wordType = [{
    title: '主诉',
    key: 'chiefComplaint',
}, {
    title: '诊断',
    key: 'diagnosis',
}, {
    title: '处置',
    key: 'cz',
},];

const initDefaultValue = {
    particularHistoryText: '无',
    presentIllnessHistory: '无',
    pastHistory: '无',
    allergyHistory: '无',
}
interface Props {
    record: any
    onClose: () => void
    getSmartSearch: (value: any) => void
    type: string
};

const AddCase = (props: Props) => {
    const { type, record, onClose, getSmartSearch } = props;
    const [value, setValue] = useState(0);
    const [chiefComplaintText, setChiefComplaintText] = useState('');
    const [chiefComplaintTag, setChiefComplaintTextTag] = useState('');
    const [chiefComplaintTags, setChiefComplaintTextTags] = useState([]);
    const [isSaveInfo, setIsSaveInfo] = useState(true);
    const [loading, setLoading] = useState(false);
    const [detailRecord, setDetailRecord] = useState({});
    const [allWords, setAllWords] = useState<any>({
        chiefComplaint: [],
        diagnosis: [],
        cz: []
    });
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [selectWord, setSelectWord] = useState<any>({});
    const [diagnosisText, setDiagnosisText] = useState('');
    const [diagnosisTag, setDiagnosisTag] = useState([]);
    const [treatmentsTag, setTreatmentsTag] = useState([]);
    const [smartSearch, setSmartSearch] = useState({
        chiefComplaint: record.chiefComplaint || '', // 有默认主诉，直接显示智能分析
        diagnosis: '',
    });

    const { currentDiagnosis, currentTreatments, wordCloud } = useSelector((state: any) => ({
        currentDiagnosis: state.global.currentDiagnosis,
        currentTreatments: state.global.currentTreatments,
        wordCloud: state.global.wordCloud,
    }));
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    // 添加病例
    const onFinish = async (values: any) => {
        setLoading(true)
        //  _diagnosisTag _treatments tag is from word cloud
        const _diagnosisTag = JSON.stringify(selectWord) !== '{}' && selectWord.diagnosis && selectWord.diagnosis.join(',') || '';
        const _treatmentsTag = JSON.stringify(selectWord) !== '{}' && selectWord.cz && selectWord.cz.join(',') || '';
        // save patient case data
        const { cardNumber, seeDoctor, recordDate, gender, race, patientName } = record;
        const { initialDiagnosis, cz, chiefComplaint} = values;
        const newData = {
            pId: record.id,
            gender, race, patientName,
            cardNumber,
            seeDoctor,
            recordDate: moment(new Date(), 'YYYY-MM-DD'),
            initialDiagnosis,
            cz,
            chiefComplaint,
            initialDiagnosisTags: chiefComplaintTag, // 主诉Tags
            diagnosis: currentDiagnosis,
            diagnosisTag: _diagnosisTag,
            treatments: currentTreatments,
            treatmentsTag: _treatmentsTag,
            particularHistoryText: values.particularHistoryText,  //特殊病
            presentIllnessHistory: values.presentIllnessHistory, //现病史
            pastHistory: values.pastHistory, //既往病史
            allergyHistory: values.allergyHistory, //过敏
            zdType: 1, // 标记就诊状态
        };
        const success = () => {
            setLoading(false);
            onClose();
            form.resetFields();
            setChiefComplaintTextTags([]);
            setDiagnosisTag([]);
            setTreatmentsTag([]);
            // 清空智能诊断选择
            dispatch(getAllDiagnosis('', ''),);
            dispatch(getAllTreatments('', ''));
            //  刷新病人列表
            dispatch(getPatientAllDiseaseInfo(record.id, {
                pageSize: 3,
                pageNum: 1,
                total: 0
            }))
            //刷新就诊
            dispatch(getDoctorRecord({
                patientName: '',
                startTime: '',
                endTime: '',
            }, {
                pageSize: 10,
                pageNum: 1,
                total: 0
            }));
            notification.success({
                message: '保存成功',
                placement: 'topRight',
            });
        }
        
        // 患者信息进入是添加
        if(type === 'add') {
            const res = await createNewPatientDiagnosis(newData);
            if(res) {
                success()
            }else {
                onClose();
                setLoading(false);
                notification.success({
                    message: '保存失败',
                    placement: 'topRight',
                });
            }
        }
        // 预诊记录进入是更新
        if(type === 'update') {
            const res = await updateNewPatientDiagnosis({...detailRecord ,...newData});
            if(res) {
                success()
            }else {
                onClose();
                setLoading(false);
                notification.success({
                    message: '保存失败',
                    placement: 'topRight',
                });
            }
        }
    };
    //点击添加词语
    const handleChange = (tag: any, checked: any, title: string) => {
        // 点击词云添加tag
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t: any) => t !== tag);
        setSelectedTags(nextSelectedTags);
        const _targetType: any = wordType.find((_type: any) => _type.key === title);
        if (_targetType) {
            const currentTag = selectWord[_targetType.key] || [];
            const nextSelectedTags2 = checked ? currentTag.concat([tag]) : currentTag.filter((t: any) => t !== tag);
            setSelectWord({
                ...selectWord,
                [_targetType.key]: nextSelectedTags2,
            })
        }

    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onFieldsChange = (changedFields: any, allFields: any) => {
        changedFields.map((item: any) => {
            // watch chiefComplaint value
            if (item.name[0] === 'chiefComplaint') {
                setChiefComplaintText(item.value)
                // setSmartSearch({
                //     ...smartSearch,
                //     chiefComplaint: AllChiefComplaintText
                // })
            }

            if (item.name[0] === 'initialDiagnosis') {
                setDiagnosisText(item.value)
            }
        })

    };

    const onBlurChange = (targetValue: any, key: string) => {
        // 改变搜索值
        // key === 'diagnosis' && setDiagnosisText(targetValue);
        if( key === 'diagnosis') {
            setDiagnosisText(targetValue);
            const newSmartSearch = { ...smartSearch, [key]: targetValue +  smartSearch.diagnosis};
            setSmartSearch(newSmartSearch);
        }
        // key === 'chiefComplaint' && setChiefComplaintText(targetValue);
        if( key === 'chiefComplaint') {
            setChiefComplaintText(targetValue);
            const newSmartSearch = { ...smartSearch, [key]: targetValue +  smartSearch.chiefComplaint};
            setSmartSearch(newSmartSearch);
        }
      
        // _text && dispatch(getAllDiagnosis(`${_text}${chiefComplaintTag}`));
    };
   
    const  getCurrentCaseDetail = async (id: string) => {
        const res = await  getNewCaseDetail(id);
        setDetailRecord(res[0])
    }
    // 获取诊断的数据
    useEffect(() => {
        const _wordCloudTag: any = JSON.stringify(selectWord) !== '{}' && selectWord.diagnosis && selectWord.diagnosis.join(',') || '';
        const _wordCloud: any = JSON.stringify(selectWord) !== '{}' && selectWord.diagnosis && selectWord.diagnosis.map((item: any) => {
            return {
                zd: item
            }
        }) || [];
        let currentDiagnosisTag = '';
        currentDiagnosis.map((item: any) => currentDiagnosisTag += item.zd)
        setDiagnosisTag(currentDiagnosis.concat(_wordCloud));
        const AllDiagnosisText = _wordCloudTag + diagnosisText + currentDiagnosisTag;
        setSmartSearch({
            ...smartSearch,
            diagnosis: AllDiagnosisText
        })
        // AllDiagnosisText ? dispatch(getAllTreatments(AllDiagnosisText)) : dispatch(getAllDiagnosis(''))
    }, [selectWord.diagnosis, currentDiagnosis, diagnosisText]);
    

    // 获取治疗处置的数据
    useEffect(() => {
        const _wordCloud: any = JSON.stringify(selectWord) !== '{}' && selectWord.cz && selectWord.cz.map((item: any) => {
            return {
                tName: item
            }
        }) || [];
        setTreatmentsTag(currentTreatments.concat(_wordCloud))
    }, [selectWord.cz, currentTreatments]);
   
    // 获取主诉的数据
    useEffect(() => {
        const _wordCloudTag: any = JSON.stringify(selectWord) !== '{}' && selectWord.chiefComplaint && selectWord.chiefComplaint.join(',') || '';
        const _wordCloudTags: any = JSON.stringify(selectWord) !== '{}' && selectWord.chiefComplaint && selectWord.chiefComplaint;
        setChiefComplaintTextTags(_wordCloudTags);
        setChiefComplaintTextTag(_wordCloudTag);
        const AllChiefComplaintText = _wordCloudTag + chiefComplaintText;
        // AllChiefComplaintText ? dispatch(getAllDiagnosis(AllChiefComplaintText)) : dispatch(getAllDiagnosis(''));
        setSmartSearch({
            ...smartSearch,
            chiefComplaint: AllChiefComplaintText
        })
    }, [selectWord.chiefComplaint, chiefComplaintText]);
    

    // 获取诊断 诊断 处置的搜索值 来改变智能分析的数据
    useEffect(() => {
        getSmartSearch(smartSearch);
    }, [smartSearch])
    
     // 获取病例详情
     useEffect(() => {
        console.log(record, '======record')
        getCurrentCaseDetail(record.id)
    }, [record.id])

    // 获取热词数据分类
    const refreshData = async () => {
        const data = await getAllWords() || []
        setAllWords({
            chiefComplaint: data[0].wordList,
            diagnosis: data[1].wordList,
            cz: data[2].wordList
        });
    };
    useEffect(() => {
        refreshData()
    }, [])

    return (
        <div className='patient-center '>
            <Spin spinning={loading}>
                <div className='case-title'>诊断</div>
                <div className='case-container'>
                    <div className='patient-info'>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 21 }}
                            onFinish={onFinish}
                            initialValues={initDefaultValue}
                            onFinishFailed={onFinishFailed}
                            onFieldsChange={onFieldsChange}
                            autoComplete="off"
                        >

                            <Form.Item
                                key={'chiefComplaint'}
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 21 }}
                                label={'主诉'}
                                name={'chiefComplaint'}
                                rules={[{ required: !(Boolean(chiefComplaintTag && chiefComplaintTag.length || 0) || Boolean(chiefComplaintText && chiefComplaintText.length || 0)), message: `${'主诉'}不能为空` }]}
                            // extra={chiefComplaintTags && chiefComplaintTags.map((item: any) => <Tag color="success" key={item}>{item}</Tag>) || ''}
                            >
                                <div>
                                    <AutoSelect 
                                        type={getDataType.CHIEF_COMPLAINT} 
                                        label={'主诉'} 
                                        defaultValue={record.chiefComplaint}
                                        onGetValue={(_value) => { 
                                            form.setFieldsValue({ 'chiefComplaint': _value }); 
                                            onBlurChange(_value, 'chiefComplaint') 
                                        }}
                                     />
                                    <div className='tags'>
                                        {allWords.chiefComplaint && allWords.chiefComplaint.map((tag: string) => (
                                            <CheckableTag
                                                key={tag}
                                                checked={selectedTags.indexOf(tag) > -1}
                                                onChange={checked => handleChange(tag, checked, 'chiefComplaint')}
                                            >
                                                {tag}
                                            </CheckableTag>
                                        ))}
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                key={'initialDiagnosis'}
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 21 }}
                                label={'诊断'}
                                name={'initialDiagnosis'}
                                rules={[{ required: Boolean(!diagnosisTag.length), message: `${'初步诊断'}不能为空` }]}
                            // extra={diagnosisTag && diagnosisTag.map((item: any) => <Tag color="success" key={item.zd}>{item.zd}</Tag>) || ''}
                            >
                                <div>
                                    <AutoSelect type={getDataType.INITIAL_DIAGNOSIS} label={'初步诊断'} onGetValue={(_value) => { form.setFieldsValue({ initialDiagnosis: _value }); onBlurChange(_value, 'diagnosis') }} />
                                    <div className='tags'>
                                        {currentDiagnosis && currentDiagnosis.map((tag: any) => (
                                           <Tag color="#108ee9">{tag.zd}</Tag>
                                        ))}
                                     </div>
                                    <div className='tags'>
                                        {allWords.diagnosis && allWords.diagnosis.map((tag: string) => (
                                            <CheckableTag
                                                key={tag}
                                                checked={selectedTags.indexOf(tag) > -1}
                                                onChange={checked => handleChange(tag, checked, 'diagnosis')}
                                            >
                                                {tag}
                                            </CheckableTag>
                                        ))}

                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item
                                key={'cz'}
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 21 }}
                                label={'处置'}
                                name={'cz'}
                                rules={[{ required: Boolean(!treatmentsTag.length), message: `${'处置'}不能为空` }]}
                            // extra={treatmentsTag && treatmentsTag.map((item: any) => <Tag color="success" key={item.tName} >{item.tName}</Tag>)}
                            >

                                <div>
                                    <AutoSelect type={getDataType.CZ} label={'处置'} onGetValue={(_value) => { form.setFieldsValue({ cz: _value }) }} />
                                    <div className='tags'>
                                        {currentTreatments && currentTreatments.map((tag: any) => (
                                           <Tag color="#108ee9">{tag.tName}</Tag>
                                        ))}
                                     </div>
                                    <div className='tags'>
                                        {allWords.cz && allWords.cz.map((tag: string) => (
                                            <CheckableTag
                                                key={tag}
                                                checked={selectedTags.indexOf(tag) > -1}
                                                onChange={checked => handleChange(tag, checked, 'cz')}
                                            >
                                                {tag}
                                            </CheckableTag>
                                        ))}

                                    </div>
                                </div>
                            </Form.Item>
                          
                            <Col span={24} key={'particularHistoryText'}>
                                <Form.Item
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 19 }}
                                    label={'特殊病史'}
                                    // hidden={!Boolean(value)}
                                    name={'particularHistoryText'}
                                    rules={[{ required: true, message: `特殊病史不能为空` }]}
                                >
                                    <Input.TextArea
                                        style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                        placeholder={`输入特殊病史`}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24} key={'presentIllnessHistory'}>
                                <Form.Item
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    label={'现病史'}
                                    name={'presentIllnessHistory'}
                                    rules={[{ required: true, message: `现病史不能为空` }]}
                                >
                                    <Input.TextArea
                                        style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                        placeholder={`输入现病史`}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24} key={'pastHistory'}>
                                <Form.Item
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    label={'既往史'}
                                    name={'pastHistory'}
                                    rules={[{ required: true, message: `既往史不能为空` }]}
                                >
                                    <Input.TextArea
                                        style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                        placeholder={`输入既往史`}
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={24} key={'allergyHistory'}>
                                <Form.Item
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    label={'过敏史'}
                                    name={'allergyHistory'}
                                    rules={[{ required: true, message: `过敏史不能为空` }]}
                                >
                                    <Input.TextArea
                                        style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                        placeholder={`输入过敏史`}

                                    />
                                </Form.Item>
                            </Col>
                            {/* submit */}
                            <Form.Item key={'submit'}
                                wrapperCol={{ span: 24 }}
                            >
                                {
                                    isSaveInfo &&
                                    <Button type="primary" htmlType="submit" className='btn'>
                                        保存
                                    </Button>
                                }
                            </Form.Item>
                            
                        </Form>

                    </div>
                </div>
            </Spin>
        </div>
    )
};

export default AddCase;
