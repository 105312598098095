
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { MapChart, EffectScatterChart ,BarChart,PictorialBarChart  } from 'echarts/charts';
import {  TooltipComponent, LegendComponent,TitleComponent  } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import APIServices from '../../../lib/APIServices';

const { geChartForTalentTrain } = APIServices;
echarts.use([MapChart, BarChart, PictorialBarChart ]);
echarts.use([CanvasRenderer, TooltipComponent , LegendComponent,TitleComponent ]);

const  TalentTrainFormChart = () =>{
    const ref: any = useRef(null);
    let mapInstance = null;
   
    const convertData = (initData: any, pName: string) => {
        return initData.map((item: any) => {
            if(item.children && item.children.length > 0) {
             return convertData(item.children, item.fullName).flat()
            }else {
                return {
                    ...item,
                    pName
                };
            }
        })
    }
    const renderChart = async () => {
    // 获取数据
    const _data = await geChartForTalentTrain();
    const _convertData = convertData(_data, '').flat();
    const xAxisData = _convertData.map((item: any) => item.fullName);
    const barData = _convertData.map((item: any) => item.countFile);

    // 基于准备好的dom，初始化echarts实例
    mapInstance = echarts.init(ref.current);
    mapInstance.setOption( {
        // backgroundColor:'#323a5e',
           tooltip: {
             trigger: 'axis',
             axisPointer: { // 坐标轴指示器，坐标轴触发有效
               type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
             },
             formatter: (params: any) => {
                const target = params[0];
                return `${target.name}:${target.value}`;
            },
           },
           grid: {
             left: '2%',
             right: '4%',
             bottom: '14%',
             top:'26%',
             containLabel: true
           },
           title: {
            show: true, //是否显示标题
            text: '人才培养', //标题名称
            left: 'center',
            textStyle: {
                // fontSize: 35,
                color: '#1890ff'
            },
            top: '10px'
            },
           xAxis: {
             type: 'category',
             data: xAxisData,
             axisLine: {
               lineStyle: {
                 color: '#1890ff'
   
               }
             },
             axisLabel: {
               // interval: 0,
               // rotate: 40,
               textStyle: {
                 fontFamily: 'Microsoft YaHei'
               }
             },
           },
   
           yAxis: {
             type: 'value',
            //  max:'20',
             axisLine: {
               show: false,
               lineStyle: {
                 color: '#1890ff'
               }
             },
             splitLine: {
               show: true,
               lineStyle: {
                 color: 'rgba(255,255,255,0.5)'
               }
             },
             axisLabel: {}
           },
          
           series: [{
             name: '人才培养',
             type: 'pictorialBar',
             barWidth: '55%',
             barCategoryGap: '-130%',
             // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
             symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
             itemStyle: {
               normal: {
                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                       offset: 0,
                       color: '#1890ff'
                   }, {
                       offset: 1,
                       color: '#fff'
                   }]),
                   barBorderRadius: 0,
               },
             },
             data: barData
           },
           ]
         })
    
  };

    useEffect(() => {
        renderChart();
    }, []);
  

    return (
        <div style={{  height: "200px",}} ref={ref}></div>
    );
}

export default  TalentTrainFormChart;