import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import znCN from 'antd/es/locale/zh_CN'
import moment from 'moment';
import 'moment/locale/zh-cn';
import Index from './pages/index/index';

import configureStore from './redux/configStore';
const reduxStore = configureStore();
moment.locale('zh-cn'); //全部默认中文组件
class App extends Component<any> {
    render() {
        return (
            <ConfigProvider locale={znCN}>
                <Provider store={reduxStore as any}>
                    <Index />
                </Provider >
            </ConfigProvider>
        );
    }
}

export default App;
