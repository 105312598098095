import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button, Tag, Divider, List, Card } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Common from '../../../../lib/Common';
import Constants from '../../../../lib/Constants';

// import CompositionList from './compositionList';
import APIServices from '../../../../lib/APIServices';
import '../../diagnosis.css';
import { spawn } from 'child_process';
// import Card from 'antd/lib/card/Card';

const { getAge } = Common;
const {GENDER_WOMAN} = Constants;
const { getNewCaseDetail } = APIServices;
interface Props {
    title: string
    visible: boolean
    record: { id: string }
    onCloseDetail: () => void
}
const DoctorRecordDetail = (props: Props) => {
    const [dataSource, setDataSource] = useState([]);
    const [recordValue, setRecordValue] = useState<any>({});
    const { title, visible, record, onCloseDetail } = props;
    const [loading, setLoading] = useState(true);
    const columns: any = [
        {
            title: '名称',
            dataIndex: 'yName',
            key: 'yName',
        },
        {
            title: '数量',
            dataIndex: 'kysl',
            valueType: 'digit',
        },
        {
            title: '单位',
            dataIndex: 'jldw',
            key: 'yId',
            render: (text: any, record: any) => <span>{text || '_'}</span>
        },
    ];
    const getCurrentRecord = async (_id: string) => {
        const value = await getNewCaseDetail(record.id);
        if(JSON.stringify(value[0]) !== '{}') {
            setRecordValue(value[0])
        }
        setLoading(false);
    };
    const getTags = (item: any, data: any) => {
        const { tagName } = item;
        return tagName.map((tag: any) => {
            if (typeof (data[tag]) === 'string') {
                const _tags = data[tag] && data[tag].split(',');
                return _tags && _tags.map((_item: string) => _item && <span key={_item} color="success">{_item}</span>)

            } else {
                return data[tag] && data[tag].map((_item: any) => <span key={_item.id} color="success">{_item.zd || _item.tName}</span>)
            }
        })
    }
    useEffect(() => {
        getCurrentRecord(record.id);
    }, [record])
    
    return (
        <Drawer
            title={`${title}`}
            placement="right"
            onClose={onCloseDetail}
            width={'80%'}
            open={visible}
        >
            {/* <Table rowKey='id' dataSource={dataSource} columns={columns} loading={loading} /> */}
            {
                recordValue
                && 
                <div className='case-detail-container'>
                <Row justify='center'>
                    <h2>病历</h2>
                </Row>
                <Row justify='space-between' className='detail-top'>
                    <Col span={12}><span>诊断时间： </span> {moment(recordValue.recordDate).format('YYYY-MM-DD hh:mm:ss') } </Col>
                    <Col span={12}><span>科室： </span> {recordValue.department} </Col>
                    
                </Row>
                <Divider style={{ backgroundColor: '#000'}} />
                {/* 患者信息 */}

                <Row justify='space-between' className='row'>
                    <Col span={6}><span className='row-label'>姓名： </span> {recordValue.patientName} </Col>
                    <Col span={6}><span className='row-label'>性别： </span> {Number(recordValue.gender)=== GENDER_WOMAN ? '女' : '男'} </Col>
                    <Col span={6}><span className='row-label'>年龄： </span> {recordValue.birthday ? getAge(recordValue.birthday): ''}岁 </Col>
                </Row>
                <Row>
                    
                </Row>
                {/* 病历 */}
                <Row wrap={false} className='row'>
                    {/* chiefComplaint 主诉  zsCy 主诉tags*/}
                    <Col className='row-label'>就诊卡号： </Col>
                    <Col>
                    {recordValue.cardNo || ''}
                    </Col>
                </Row>
                <Row wrap={false} className='row'>
                    {/* chiefComplaint 主诉  zsCy 主诉tags*/}
                    <Col className='row-label'>主诉： </Col>
                    <Col>
                    {recordValue.chiefComplaint}
                    {
                        // getTags({ tagName: ['zsCy'] }, recordValue)
                    }
                    </Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>特殊病史： </Col>
                    <Col>{recordValue.particularHistoryText}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>现病史： </Col>
                    <Col>{recordValue.presentIllnessHistory}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>既往史： </Col>
                    <Col>{recordValue.pastHistory}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>过敏史： </Col>
                    <Col>{recordValue.allergyHistory}</Col>
                </Row>
                
                {/* 添加历史字段*/}
                <Row wrap={false} className='row'>
                    <Col className='row-label'>月经史：  </Col>
                    <Col>{recordValue.menstruationHistory || '无'}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>婚育史： </Col>
                    <Col>{recordValue.marriageHistory || '无'}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>手术史： </Col>
                    <Col>{recordValue.operation || '无'}</Col>
                </Row>
                <Row wrap={false} className='row'>
                    <Col className='row-label'>家族史： </Col>
                    <Col>{recordValue.familyHistory || '无'}</Col>
                </Row>
        
                {/* 诊断集合 */}
                {
                     recordValue.szDiagnosis && recordValue.szDiagnosis.map((item: any, index: number) => {
                        return index==0 && <Card key={item.id}>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>诊断： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                    {item.zd}
                                    {
                                        // getTags({ tagName: ['zdCy', 'diagnosis'] }, item)
                                    }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='start' className='row'>
                            <Col className='row-label'>开药：  </Col>
                            {/* cz 处置 treatments treatmentsTag 处置tags */}
                            <Col span={18}>
                            { 
                                item.prescription
                                &&
                                <Table
                                    rowKey='yId'
                                    style={{ width: '100%'}}
                                    pagination={false}
                                    columns={columns}
                                    dataSource={item.prescription}
                                    // loading={!allDiagnosis.length}
                                />
                            }
                            </Col>
                        </Row>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>疾病描述： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                        <Col>
                                            <div dangerouslySetInnerHTML={{__html:  item.diseaseDefinition}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                       {/* 治疗 */}
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>中医治疗： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                            <div dangerouslySetInnerHTML={{__html:  item.zyScheme}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>西医治疗： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                            <div dangerouslySetInnerHTML={{__html:  item.xyScheme}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>民族医治疗： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                    {item.mzyScheme}
                                    {
                                        // getTags({ tagName: ['zlCy', 'treatments'] }, item)
                                    }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* 检查 */}

                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>建议检查： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                             <div dangerouslySetInnerHTML={{__html:  item.checksTag}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>建议查体： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                            <div dangerouslySetInnerHTML={{__html:  item.physicalexamsTag}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify='space-between' className='row'>
                            <Col>
                                <Row wrap={false} className='row'>
                                    <Col className='row-label'>重要体征： </Col>
                                    {/* cz 处置 treatments treatmentsTag 处置tags */}
                                    <Col>
                                            <div dangerouslySetInnerHTML={{__html:  item.signsTag}} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                     })

                }
            
              {/* 是否有 replyInfo 问诊问题*/}
              {
                    recordValue.szReply
                    &&
                    <List
                    size="small"
                    header={<div>问诊记录</div>}
                    // footer={<div>Footer</div>}
                    bordered
                    dataSource={recordValue.szReply}
                    renderItem={(item: any, index: number) => 
                    <List.Item key={item.quName}>
                        <div style={{ width: '70%'}}>{index + 1}. {item.quName}</div>
                        {
                           item.anName ? <p>答: {item.anName}</p> : <span></span>
                        }
                        
                    </List.Item>}
                    />
                }
            </div>

            }
           
        </Drawer>
    );
};

export default DoctorRecordDetail;