import React, { useEffect, useState } from 'react';
import { Layout, Input, Tabs, Button, message, Row, Col } from 'antd';

import PatientBasicInfo from './PatientBasicInfo';
import CaseList from './CaseList';
// import Record from './components/DoctorRecord';
// import StudyCenter from './components/StudyCenter';
// import MiniCase from './components/MiniCase/CaseList'
// import APIServices from '../../lib/APIServices';

import '../../diagnosis.css'

const { TabPane } = Tabs;
const AddPatientInfo = () => {
    const [patientInfo, setPatientInfo] = useState({});
    const getPatientBasicInfo = (_info: any) => {
        // setPatientInfo(_info)
    }
    return (
        <div className='zt-wrap'>
            <PatientBasicInfo getPatientBasicInfo={getPatientBasicInfo} />
            <CaseList />
        </div>
    )
};

export default AddPatientInfo;