
import React, { useState, useEffect } from 'react';
import { Drawer, Form, Button, Col, Row, Input, DatePicker, AutoComplete, notification, Select, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import WordCloud from './WordCloud';
import SmartAnalyse from './SmartAnalyse';
import { actions as globalAction } from '../../../../redux/modules/global';
import TreatmentDetail from '../../../treatment/components/treatmentDetail';

const { setTreatmentVisible, setTreatmentRecord } = globalAction;
import '../../diagnosis.css';
import { useDispatch, useSelector } from 'react-redux';
interface Props {
}
const StudyCenter = (props: Props) => {
    const [searchValue, setSearchValue] = useState<any>();
    const { globalLoading, treatmentVisible, treatmentRecord } = useSelector((state: any) => ({
        globalLoading: state.global.globalLoading,
        treatmentVisible: state.global.treatmentVisible,
        treatmentRecord: state.global.treatmentRecord,
    }));
    const dispatch = useDispatch();
    const onCloseDetail = (_visible: boolean) => {
        // setVisibleDetail(_visible)
        dispatch(setTreatmentVisible(false));
        dispatch(setTreatmentRecord({}));
    };
    const onChange = (key: string) => {
        console.log(key);
    };
    const getSearchValue = (value: any) => {
        setSearchValue(value);
    }
    return (
        <div className='container'>
            <Row gutter={20} className="xxpt">
                <WordCloud getSearchValue={getSearchValue} />
                <SmartAnalyse searchValue={searchValue} />
            </Row>
            {
                treatmentVisible && <TreatmentDetail visible={treatmentVisible} record={treatmentRecord} onCloseDetail={onCloseDetail} />
            }
        </div>
    )
};

export default StudyCenter;