import React, { useState, useEffect } from 'react';
import { Layout, Input, Tabs, Menu } from 'antd';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import { useLocation} from 'react-router'

import Diagnosis from '../diagnosis/diagnosis';
import Treatment from '../treatment/treatment';
import PatientCase from '../cases/patientCase';
import AvatarDropdown from './components/AvatarDropdown/index';
import DiagnosisIndex from '../diagnosis/diagnosisIndex';

import dataSet from '../../config/routerData';
import './layout.css';

const history = createBrowserHistory();
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;
const ILayout = (props: any) => {
    const [searchValue, setSearchValue] = useState('');
    const [defaultSelectedKeys ,  setDefaultSelectedKeys] = useState('');
    let navigate = useNavigate();
    const location = useLocation();
    const menuItems = dataSet.map((item: {
        path: string,
        label: string
    }) => {
        return {
            key: item.path,
            label: item.label,
        }
    })
    // 点击路由跳转
    const onClickHeaderMenu = (item: any) => {
        navigate(`/home/${item.key}`);
    };
    useEffect(() => {
     const _strRemoveHome = location.pathname.replaceAll('/home/', '');
     setDefaultSelectedKeys(_strRemoveHome)
    }, [location.pathname])

    useEffect(() => {
        // 刷token
        const accessToken = sessionStorage.getItem('token');
        if(!accessToken) {
            navigate(`/login`);
        }
    })
    return (
        <Layout>
            <Header>
                {/* <img src="/logo-txt.png" alt="" /> */}
                <span className='title'><img src="/logo.png" alt="" /> 民族医智慧医疗平台</span>
                {
                    defaultSelectedKeys.length > 0
                    &&
                    <Menu
                    // theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={[`${defaultSelectedKeys}`]}
                    items={menuItems}
                    className="header-navigate"
                    onClick={onClickHeaderMenu}
                />
                }
                <AvatarDropdown />
            </Header>
            <Content>
                {
                    props.children
                }
            </Content>
        </Layout>
    )
};

export default ILayout;
