
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Table, Spin, Empty, Button, Col, Row, Select, Form, Modal, Card, Input } from 'antd';
import APIServices from '../../../../../lib/APIServices';
import { actions as prescriptionAction } from '../../../../../redux/modules/prescription';

const { Option } = Select;
const { getPrescribeList,  getChineseMedicineTypeName, getChineseMedicineDosage, getChineseMedicineToxicology} = APIServices;
const {setPrescriptionSelectList, setPrescriptionNumberList, setPrescriptionModalVisible} = prescriptionAction;

interface Props {
    // onCancel: () => void
}


const PrescriptionModalList = (props: Props) => {
    const {  } = props;
    const [form] = Form.useForm();
     //redux
     const { 
        prescriptionSelectList,
        prescriptionModalIndex,
        prescriptionModalVisible
    } = useSelector((state: any) => {
        return {
             //model visible
             prescriptionSelectList: state.prescription.prescriptionSelectList,
             prescriptionModalIndex: state.prescription.prescriptionModalIndex,
             prescriptionModalVisible: state.prescription.prescriptionModalVisible,
        }
    });
    const dispatch = useDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const menuList: any = JSON.parse(sessionStorage.getItem("standardsMenu") || '') || [];
    const [allMedicine, setAllMedicine] = useState<any>([]);
    const [encode, setEncode] = useState<string>('standardszyyd');
    const [chineseMedicineStoreTypeName, setChineseMedicineStoreTypeName] = useState<any>([]); // 中药库类别名称
    const [chineseMedicineStoreDosage, setChineseMedicineStoreDosage] = useState<any>([]); // 中药库药品剂型
    const [chineseMedicineStoreToxicology, setChineseMedicineStoreToxicology] = useState<any>([]); // 中药库类药品毒理

    const [filter, setFilter] = useState<any>({
        encode: '', // 菜单类目
        title: '', // 药品名称
        dtypename: '', // 类别名称
        ypjx: '', // 药品计量
        dlfl: '',// 毒理分类
    });
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 10,
        total: 0,
    });
    const columnConfig: any = {
        standardszyyd: {
            name: 'zwTitle',
            unit: 'ly',
            yType: 1,
        },
        standardsmzfy: {
            name: 'cName',
            unit: '',
            yType: 2,
        },
        standardsjf: {
            name: 'title',
            unit: '',
            yType: 3,
        },
        standardsyzk: {
            name: 'name',
            unit: 'ji',
            yType: 4,
        },
        standardszyk: {
            name: 'ypmc',
            unit: 'ypJx',
            yType: 5,
        },
    }
    const columns: any = [
        {
            title: '名称',
            dataIndex: 'yName',
            key: 'yName',
            // render: (text: any, record: any) => <a target={'_blank'} href={`http://tbxt.mzyx.zydyfy.cn/anquan/details/${record.id}`}>{text}</a>
        },
        // {
        //     title: '单位',
        //     dataIndex: 'jldw',
        //     key: 'yId',
        //     render: (text: any, record: any) => <span>{text || '_'}</span>
        // },
    ];
   
    const rowSelection: any = {
        onChange: (_selectedRowKeys: React.Key[], selectedRows: [any]) => {
            // 选择当前类目的药品
            setSelectedRowKeys(_selectedRowKeys);
            dispatch(setPrescriptionSelectList({
                ...prescriptionSelectList,
                [prescriptionModalIndex]: {
                    ...prescriptionSelectList[prescriptionModalIndex],
                    [encode]: selectedRows
                }
            }))
        }
    };

    // 数据过滤器
    const onChangeSelect = (event: any) => {
        const key = event[0].name[0];
        const targetValue = event[0].value
        if(key == 'encode') {
            setEncode(targetValue)
            setPagination({
                ...pagination,
              pageNum: 1,

            })
        }
        setFilter({
            ...filter,
            [key]: targetValue,
        })
    }
    // 获取过滤值
    
    // 获取数据list
    const getList = async () => {
        if(encode === 'standardszyk') {
            // 中药库
            const data: any = await getPrescribeList({encode, ...pagination}, filter);
            const { list, pagination: _pagination } = data;
            const newList = list.map((item: any) => {
                return {
                    yId: item.id.toString(),
                    yName: item[columnConfig[encode].name],
                    kysl: 1,
                    yType: columnConfig[encode].yType,
                    encode,
                }
            })
            setAllMedicine(newList);
            setPagination({
                ...pagination,
                total: _pagination.records || 0
            })
        }else {
            const data: any = await getPrescribeList({encode, ...pagination}, filter);
            const { list, pagination: _pagination } = data || {};
            const newList = list && list.map((item: any) => {
                return {
                    yId: item.id.toString(),
                    yName: item[columnConfig[encode].name], // 药名
                    // jldw: item[columnConfig[encode].unit], // 单位
                    kysl: 1, // 数量
                    yType: columnConfig[encode].yType, // 类目分类
                    encode,
                }
            })
            setAllMedicine(newList || []);
            setPagination({
                ...pagination,
                total: _pagination &&_pagination.records || 0
            })
        }
    }
   
    const onCloseModal = () => {
        //关闭弹窗
        dispatch(setPrescriptionModalVisible(false))
        // onCancel();
    }
    const getFilterList =  async () => {
        const data =  await getChineseMedicineTypeName();
        setChineseMedicineStoreTypeName(data)
    }

    const getFilterList2 =  async () => {
        const data =  await getChineseMedicineDosage();
        setChineseMedicineStoreDosage(data)
    }

    const getFilterList3 =  async () => {
        const data =  await getChineseMedicineToxicology();
        setChineseMedicineStoreToxicology(data)
    }
    const onChangePagination = (_pagination: any) => {
        setPagination({
            ...pagination,
          pageNum: _pagination.current,
          pageSize: _pagination.pageSize,
        })
      };
    // 加载数据
    useEffect(() => {
        getList();
    },[encode, filter, pagination.pageNum]);
    
    useEffect(() => {
        // 默认选择已选择的值
        if(prescriptionSelectList[prescriptionModalIndex] && prescriptionSelectList[prescriptionModalIndex][encode]) {
            const selectList = prescriptionSelectList[prescriptionModalIndex][encode].map((item: any) => item.yId);
            setSelectedRowKeys(selectList);
        } else {
            setSelectedRowKeys([]);
        }
    }, [prescriptionModalVisible, prescriptionSelectList[prescriptionModalIndex], encode])
    
    // 获取分类数据
    useEffect(() => {
        getFilterList();
        getFilterList2();
        getFilterList3();
    },[])
    return (
        <Modal 
            okText="确认" 
            cancelText="取消" 
            width={'70%'} 
            title="药品集合"
            footer={[]} 
            visible={prescriptionModalVisible} 
            onOk={onCloseModal} 
            onCancel={onCloseModal}>
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="" bordered={false}>
                        <Form
                            form={form}
                            onFieldsChange={(_, allFields) => {
                                onChangeSelect(_,);
                            }}
                            initialValues={{
                            encode: 'standardszyyd'
                            }}
                        >
                            <Row gutter={8}>
                                <Col span={4} >
                                    <Form.Item label="类目:" name={'encode'}>
                                        <Select
                                            showSearch
                                            placeholder="选择类目"
                                            // defaultValue={'standardszyyd'}
                                            optionFilterProp="children"
                                            style={{width: '100%'}}
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                menuList
                                                &&
                                                menuList.map((item: any) => <Option key={item.enCode} value={item.enCode}>{item.fullName}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4} >
                                    <Form.Item label="名称:" name={'title'}>
                                         <Input  placeholder="请输入名称"/>
                                    </Form.Item>
                                </Col>
                                <Col span={4} hidden={ ! (encode === 'standardszyk')}>
                                        <Form.Item label="类别名称:" name='dtypename'>
                                            <Select
                                                showSearch
                                                placeholder="选择类目"
                                                optionFilterProp="children"
                                                style={{width: '100%'}}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                <Option key={0} value={''}>{'全部'}</Option>
                                                {
                                                    chineseMedicineStoreTypeName
                                                    &&
                                                    chineseMedicineStoreTypeName.map((name: any) => <Option key={name} value={name}>{name}</Option>)
                                                }
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4} hidden={!(encode === 'standardszyk')} >
                                        <Form.Item label="药品剂型:" name='ypjx'>
                                            <Select
                                                showSearch
                                                placeholder="选择药品剂型"
                                                optionFilterProp="children"
                                                // onChange={(value) => onChangeSelect(value, 'ypjx')}
                                                // onSearch={onSearch}
                                                style={{width: '100%'}}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                 <Option key={0} value={''}>{'全部'}</Option>
                                                {
                                                    chineseMedicineStoreDosage
                                                    &&
                                                    chineseMedicineStoreDosage.map((name: any) => <Option key={name} value={name}>{name}</Option>)
                                                }
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4} hidden={!(encode === 'standardszyk')}>
                                        <Form.Item label="毒理分类:" name='dlfl'>
                                            <Select
                                                showSearch
                                                placeholder="选择毒理分类"
                                                optionFilterProp="children"
                                                // onChange={(value) => onChangeSelect(value, 'dlfl')}
                                                // onSearch={onSearch}
                                                style={{width: '100%'}}
                                                filterOption={(input, option) =>
                                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                 <Option key={0} value={''}>{'全部'}</Option>
                                                {
                                                    chineseMedicineStoreToxicology
                                                    &&
                                                    chineseMedicineStoreToxicology.map((name: any) => <Option key={name} value={name}>{name}</Option>)
                                                }
                                            </Select>
                                    </Form.Item>
                                </Col>
                                
                            </Row> 
                        </Form>
                        <div className='smart-content smart-content-short'>
                            {/* {默认值是第一个值} */}
                            {
                                columns.length && allMedicine && allMedicine.length
                                    ? <Table
                                        rowKey='yId'
                                        rowSelection={{
                                            type: 'checkbox',
                                            ...rowSelection,
                                            selectedRowKeys:selectedRowKeys,  
                                        }}
                                        pagination={pagination}
                                        onChange={onChangePagination}
                                        columns={columns}
                                        dataSource={allMedicine}
                                        // loading={!allDiagnosis.length}
                                    />
                                    : <Empty description={'没有数据'} />
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
       </Modal>
      
    )
};

export default PrescriptionModalList;
