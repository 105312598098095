import axios from '../utils/axiosSetting';
import CONFIG_HOST from '../utils/Config';
const {rightHost} = CONFIG_HOST;

export default {
    
    // get diagnosis data
    getSearchDiagnosis(value: string) {
        return axios.get(`/SearchData/diagnosis/${value}`);
    },
    // get treatment data
    getSearchTreatment(value: string) {
        return axios.get(`/SearchData/0/${value}`);
    },
    // get permissions
    getAuthorize(value: string | number,) {
        // return axios.get(`/Authorize/module`);
        return axios.get(`/Permissions/${value}/all`, {
            baseURL: rightHost
        });
    },
    //智慧医疗
    // get patient data by patient card id
    getPatientInfo(id: string) {
        return axios.get(`/PeopleInfo/${id}/people`);
    },
    // get patient All data by patientId
    getPatientAllDiseaseInfo(id: string, pagination: any) {
        // return axios.get(`/DiseaseInformation/${id}/diseaseinfo`);
        return axios.get(`/CaseInfo/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageNum * pagination.pageSize}/${id}/all`);
    },
    //get case detail 
    getCaseDetail(id: string) {
        // return axios.get(`/DiseaseInformation/${id}/diseaseinfo`);
        return axios.get(`/CaseInfo/${id}/one`);
    },
    // get diagnosis data by id and chiefComplaint
    getDiagnosisByChiefComplaint(chiefComplaint: string, filter: any) {

        if (filter) {
            return axios.get(`/DiseaseInformation/diagnosis/${chiefComplaint}?nl1=${filter.nl1 || ''}&nl2=${filter.nl2 || ''}&xb=${filter.xb || ''}&year=${filter.year || ''}`,);
        } else {
            return axios.get(`/DiseaseInformation/diagnosis/${chiefComplaint}`,);
        }

    },
    getTreatmentByDiagnosis(diagnosisText: string, filter: any) {
        return axios.get(`/DiseaseInformation/treatmentplan/${diagnosisText}?mzyId=${filter.mzyId || ''}`);
    },
    // add patient disease data 创建患者信息
    createPatientInfo(data: any) {
        // return axios.put(`/DiseaseInformation/inspatient`, data);
        return axios.put(`/PeopleInfo/inspeoplehd`, data)
    },
    updatePatientInfo(data: any) {
        // return axios.post(`/DiseaseInformation`, data);
        return axios.post(`/PeopleInfo/updpeoplehd`, data)
    },
    // add patient disease data 创建病例
    createPatientDiagnosis(data: any) {
        // return axios.put(`/DiseaseInformation/insdisease`, data);
        return axios.put(`/CaseInfo`, data);
    },
    // update patient cases data 更新病例
    updatePatientDiagnosis(data: any) {
        return axios.post(`/CaseInfo`, data);
    },
    getAllWords(type?: any) {
        if (type) {
            return axios.get(`/DiseaseInformation/commonwords?type=${type}`);
        } else {
            return axios.get(`/DiseaseInformation/commonwords`);
        }
    },
    // 关键字查询
    getQueryMainKey(type: number, key: string) {
        return axios.get(`/DiseaseInformation/dataitem/${type}/${key}`);
    },
    // 诊断记录
    getAllPatientsCases(params: any, pagination?: any) {
        // return axios.get(`/DiseaseInformation/patientinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    
        return axios.get(`/CaseInfo/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageSize}/zdcaseinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    },
    //  预诊记录
    getAllMiniCases(params: any, pagination?: any) {
        // return axios.get(`/CaseInfo/yzcaseinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
        return axios.get(`/CaseInfo/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageSize}/yzcaseinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    },
    // 身份证查询
    getQueryUserCardNumber(key: string) {
        // return axios.get(`/DiseaseInformation/userinfo/${key}`);
        return axios.get(`/PeopleInfo/userinfo/${key}`)
    },
    changeUserPassword(userId: string, value: string, ftpValue: any) {
        return axios.post(`/User/${userId}/${value}/${ftpValue}/reset`, {}, {
            baseURL: rightHost,
        });
    },
    // 获取民族医数据 
    getMinZuYiData() {
        return axios.get(`/DiseaseInformation/mzysjzd`,);
    },

    //人才培养菜单树
    getTalentTrainMenuTree() {
        return axios.get(`/Culture/rcpycd`);
    },
    getTalentTrainMenuList() {
        return axios.get(`/Culture/rcpyrylist`);
    },
    //人才培养列表
    getTalentTrainNewList(params: any) {
        const {offset, size , subjectid, keywords} = params;
        return axios.get(`/Culture/${offset}/${size}/rclist?subjectid=${subjectid}&keywords=${keywords}`);
    },
     //成功转化菜单树
     getResultTransformMenuTree() {
        return axios.get(`/Culture/cgzhcd`);
    },
     // 成果转化列表
    getResultTransformList() {
        return axios.get(`/Culture/cgzhlist`);
    },
    //成果转化模块数据统计图表
    // 地图统计登记本人数
    getMapChartForRecord() {
        return axios.get(`/Culture/bookrycount`);
    },
    // 人才培养人数统计
    geChartForTalentTrain() {
        return axios.get(`/Culture/rcpycount`);
    },
    // 标准制定统计
    geChartForStandards() {
        return axios.get(`/Culture/bzzdcount`);
    },
    
    //成果转化统计
    geChartForResultTrans() {
        return axios.get(`/Culture/cgzhcount`);
    },
    //共性技术统计
    geChartForSameTech() {
        return axios.get(`/Culture/gxjscount`);
    },
     //标准制定菜单树
    getStandardsMenuTree() {
        return axios.get(`/Culture/bzzdcd`);
    },

    // 根据id 查询方药详情
    getFangYaoDetail(fId: any) {
        return axios.get(`/Culture/${fId}/fyyygf`);
    },
    // 根据id 查询技法(团体标准)
    getJiFaDetail(fId: any) {
        return axios.get(`/Culture/${fId}/ttbz`);
    },
    //标准制定根据菜单目录获取列表
    getStandardsListByMenu(params: any) {
        const {offset,size, enCode} = params;
        return axios.get(`/Culture/${offset}/${size}/${enCode}/bzzdlist`);
    },
    // 开药数据
    getPrescribeList(params: any, filter?: any) {
        const {pageNum:offset,pageSize:size, encode} = params;
        return axios.get(`/Culture/${(offset - 1) * size || 0}/${size || 10}/${encode}/bzzdcd?title=${filter.title}&dtypename=${filter.dtypename}&ypjx=${filter.ypjx}&dlfl=${filter.dlfl}`);
        
    },
   getTalentTrainList(params: any) {
    const {offset, size , subjectid, keywords} = params;
    return axios.get(`/Culture/${offset}/${size}/list?subjectid=${subjectid}&keywords=${keywords}`);
    },

    // 文件预览
    getTalentFilePreview(fileId: string) {
        return axios.get(`/Culture/${fileId}/detail`, {
            responseType:'blob',
            timeout: 5 * 100000// 5分钟,  处理大文件超时下载
        });
    },
    // ----- 共性技术-----
    // 共性技术民族菜单树
    getSameTechMenuTree() {
        return axios.get(`/Generality/minzu`);
    },
    // 共性治疗疾病
    getSameTreatDisease(id?: string | null) {
        return axios.get(`/Generality/disease?mid=${id || ''}`);
    },
    // 共性准备材料
    getSameMaterial(id?: string | null) {
        return axios.get(`/Generality/cailiao?mid=${id || ''}`);
    },
    // 共性操作方法
    getSameOperation(id?: string | null) {
        return axios.get(`/Generality/caozuo?mid=${id || ''}`);
    },
    // 共性禁忌症
    getSameContraindications(id?: string | null) {
        return axios.get(`/Generality/jinji?mid=${id || ''}`);
    },
    // 共性处方组成
    getSameRecipe(id?: string | null) {
        return axios.get(`/Generality/zucheng?mid=${id || ''}`);
    },
    
    //新版辅助诊断模块

    // 根据身份证号查询患者列表
    getNewQueryUserCardNumber(key: string) {
        // return axios.get(`/DiseaseInformation/userinfo/${key}`);
        return axios.get(`/SZCase/${key}/userinfo`)
    },

    //根据身份证号码获取用户信息
    getNewPatientInfo(id: string) {
        return axios.get(`/SZCase/${id}/getidnum`);
    },

    // 根据患者id 查询患者信息
    getNewPatientInfoById(id: string) {
        return axios.get(`/SZCase/${id}/peopleid`);
    },

    // 根据患者id删除患者信息
    deleteNewPatientInfoById(id: string) {
        return axios.post(`/SZCase/${id}/deletepeople`);
    },

    //根据患者信息id 获取病例信息
    getNewPatientAllDiseaseInfo(id: string, pagination: any) {
        // return axios.get(`/DiseaseInformation/${id}/diseaseinfo`);
        return axios.get(`/SZCase/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageNum * pagination.pageSize}/${id}/case`);
    },

    // 获取病例详情
    getNewCaseDetail(id: string) {
        return axios.get(`/SZCase/${id}/one`);
    },

    // 诊断记录
    getNewAllPatientsCases(params: any, pagination?: any) {
        // return axios.get(`/DiseaseInformation/patientinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    
        return axios.get(`/SZCase/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageSize}/zdcase?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    },
    //  预诊记录
    getNewAllMiniCases(params: any, pagination?: any) {
        // return axios.get(`/CaseInfo/yzcaseinfo?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
        return axios.get(`/SZCase/${(pagination.pageNum -1) * pagination.pageSize}/${pagination.pageSize}/yzcase?patientName=${params.patientName}&startTime=${params.startTime}&endTime=${params.endTime}`);
    },
    // 编辑患者信息
    updateNewPatientInfo(data: any) {
        // return axios.post(`/DiseaseInformation`, data);
        return axios.post(`/SZCase/updpeople`, data)
    },
    // 添加病例
    createNewPatientDiagnosis(data: any) {
        // return axios.put(`/DiseaseInformation/insdisease`, data);
        return axios.put(`/SZCase`, data);
    },
    // update patient cases data 更新病例
    updateNewPatientDiagnosis(data: any) {
        return axios.post(`/SZCase`, data);
    },

     // update patient cases data 更新病例
     deleteNewPatientDiagnosis(id: string) {
        return axios.post(`/SZCase/${id}/deletecase`);
    },
    // 开药药品筛选
    // 中药典籍类别名称
    getChineseMedicineTypeName() {
        return axios.get(`/Culture/zyklbmclist`);
    },
    // 中药典籍药品剂型
    getChineseMedicineDosage() {
        return axios.get(`/Culture/zykypjxlist`);
    },
    // 中药典籍药品毒理分类
    getChineseMedicineToxicology() {
        return axios.get(`/Culture/zykdlfllist`);
    },
    //选择医院就诊
    getHospitalList(yname: string ) {
        return axios.get(`/Hospital/hospital?yname=${yname}`);
    },
    
    //扫码添加病例
    onScanCodeAddCaseInfo(values: any) {
        return axios.put(`/Hospital`, values);
    },

    // 根据主诉 获取诊断
    onChiefComplaintGetDiagnosis(values: any) {
        return axios.get(`/Hospital/diagnosis/${values}`)
        // return axios.put(`/Hospital`, values);
    },
    // 根据诊断 获取治疗
    onDiagnosisGetTreatment(values: any) {
        return axios.get(`/Hospital/treatmentplan/${values}`)
        // return axios.put(`/Hospital`, values);
    },
};