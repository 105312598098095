import React, {useEffect, useState} from 'react';
import {Spin, Alert, Form, Input, Button, message} from 'antd';
import {Navigate} from 'react-router-dom';
import QRCode from 'qrcode.react';

import Common from '../../lib/Common';
import APIServices from '../../lib/APIServices';
import Config from '../../utils/Config';
import './scanCode.css';


const {getData} = Common;
const {getAuthorize} = APIServices;
const { host } =Config;
const CodeIndex = () => {
    return <div className='code'>
        <QRCode
                id='qrid'
                value={`${host}scanCode/hospital`}
                size={250}
        />
    </div>
};

export default CodeIndex;
