const config = {
    version: '0.0.1',
    // API 接口
    // AdminHost: 'http://192.168.2.61:5300/' // 开发
    // AdminHost: 'http://gymzy.kg.api.wiimedia.cn', // 测试
    AdminHost: 'http://kg.mzyx.zydyfy.cn', // 生产

    // 单点登录
    // signLoginHost: 'https://gymzy.is.wiimedia.cn/', // 测试
    signLoginHost: 'https://tydl.mzyx.zydyfy.cn/', // 生产

    // 项目地址
    // host: 'http://gymzy.jc.wiimedia.cn/',// 测试
    host: 'http://jcxt.mzyx.zydyfy.cn/',// 生产

    // 授权API
    // rightHost: 'http://gymzy.rm.api.wiimedia.cn', //测试
    rightHost: 'http://rm.mzyx.zydyfy.cn', //生产

    //跳转地址
    // jumpTxfp: 'http://gymzy.txfp.wiimedia.cn', //测试
    jumpTxfp: 'http://tbxt.mzyx.zydyfy.cn', //生产

};

export default config;
