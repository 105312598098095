
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, DatePicker, AutoComplete, notification, Select, Tabs, Spin, Table, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

import { actions as casesAction } from '../../../../redux/modules/cases';
import DiagnosisCaseList from './DiagnosisCaseListDetail';
import APIServices from '../../../../lib/APIServices';
import Common from '../../../../lib/Common';
import Constants from '../../../../lib/Constants';
const { getAge } = Common;
const {GENDER_WOMAN} = Constants;
interface Props {
}

const { getDoctorRecord } = casesAction;
const { getAllPatientsCases } = APIServices;
const DoctorRecord = (props: Props) => {
    const [visibleDetail, setVisibleDetail] = useState(false);
    const dispatch = useDispatch();
    const [defaultRecord, setDefaultRecord] = useState<any>();
    const [loadingStatus, setLoadingStatus] = useState<any>();
    const [recordData, setRecordData] = useState<any>([]);
    const [pagination, setPagination] = useState<any>({
        pageSize: 10,
        pageNum: 1,
        total: 0
    });
    const [searchValue, setSearchValue] = useState<any>({
        patientName: '',
        startTime: '',
        endTime: '',
    });

    const { doctorRecordList } = useSelector((state: any) => ({
        doctorRecordList: state.cases.doctorRecordList,
    }));
    const columns: any = [
        {
            title: '姓名',
            dataIndex: 'patientName',
            key: 'patientName',
            // align: 'center',
            render: (text: any) => <span >{text}</span>,
        },
        {
            title: '年龄',
            dataIndex: 'birthday',
            key: 'birthday',
            // align: 'center',
            render: (text: any) => <span >{text ? getAge(text): 0}岁</span>,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            // align: 'center',
            render: (text: any) => <span >{Number(text)=== GENDER_WOMAN ? '女' : '男'}</span>,
        },
        {
            title: '主诉',
            dataIndex: 'chiefComplaint',
            key: 'chiefComplaint',
            // align: 'center',
            render: (text: any) => <span >{text}</span>,
        },
         {
            title: '所属医院',
            dataIndex: 'hospitalName',
            key: 'hospitalName',
        },
        {
            title: '诊断时间',
            dataIndex: 'recordDate',
            key: 'recordDate',
            // align: 'center',
            render: (text: any) => <span >{text ? moment(text).format('YYYY-MM-DD'): ''}</span>,
        },
        {
            title: '详情',
            key: 'ttbw',
            // align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage = () => {
                    setDefaultRecord(record);
                    setVisibleDetail(true);
                }
                return (<Space size="middle" onClick={openDetailPage}>
                    <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} />
                </Space>)
            },
        }
    ];

    const AllData = async () => {
        // const data = await getAllPatientsCases(searchValue) || [];
        dispatch(getDoctorRecord(searchValue, pagination))
        // setRecordData(data);
    };
    const onChange = (date: any, dateString: any, title: any) => {
        setSearchValue({
            ...searchValue,
            [title]: dateString,
        })

    };
    const onChangeName = (e: any) => {
        setSearchValue({
            ...searchValue,
            patientName: e.target.value,
        })
    }

    
    const onChangePagination = (_pagination: any) => {
        setPagination({
            ...pagination,
          pageNum: _pagination.current,
          pageSize: _pagination.pageSize,
        })
      };
    useEffect(() => {
        if(doctorRecordList.pagination) {
            setPagination({
                ...pagination,
                total: doctorRecordList.pagination.records
            })
        }
        
    }, [doctorRecordList.pagination])
    useEffect(() => {
        AllData()
    }, [pagination.pageNum, searchValue])
    return (
        <div className='container'>
            <Form
                layout='inline'
            >
                <Form.Item label="患者姓名:">
                    <Input placeholder='输入人名搜索' onChange={onChangeName} />
                </Form.Item>
                <Form.Item label="开始时间:">
                    {
                        // @ts-ignore
                        <DatePicker
                            format={'YYYY-MM-DD'}
                            placeholder='选择时间'
                            onChange={(data, dataString) => onChange(data, dataString, 'startTime')} />
                    }
                </Form.Item>
                <Form.Item label="结束时间:">
                    {
                        // @ts-ignore
                        <DatePicker
                            format={'YYYY-MM-DD'}
                            placeholder='选择时间'
                            onChange={(data, dataString) => onChange(data, dataString, 'endTime')} />
                    }
                </Form.Item>
            </Form>
            {
                loadingStatus
                    ? <Spin tip={'数据加载中...'} size="large" spinning={loadingStatus} className='tip'>
                    </Spin>
                    :
                    <Table 
                    loading={loadingStatus} 
                    rowKey='id' 
                    dataSource={doctorRecordList.list} 
                    columns={columns} 
                    style={{marginTop:10}}
                    pagination={pagination}
                    onChange={onChangePagination}
                    />
            }
            {
                visibleDetail
                &&
                <DiagnosisCaseList
                    title='诊断记录详情'
                    visible={visibleDetail}
                    record={defaultRecord}
                    onCloseDetail={() => setVisibleDetail(false)}

                />

            }
        </div>
    )
};

export default DoctorRecord;