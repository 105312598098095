import React, { useState ,useEffect} from 'react';
import { Modal, Spin, Image } from 'antd';
import APIServices from '../../../lib/APIServices';
import FileViewer from '../../../components/FileViewer';
import Tiff from 'tiff.js';
import { Player, } from 'video-react'
import ReactPlayer from 'react-player'
import { download } from '../../../utils/download';

const videoFileType = ['mp4', 'mpg']
const { getTalentFilePreview} = APIServices;
const PreviewForm = (props: any) => {
  const {
    onCancel,
    previewModalVisible,
    values,
  } = props;
  const [file, setFile] = useState<any>('');
  const [tiffSrc, setTiffSrc] = useState<any>('');
  const [videoSrc, setVideoSrc] = useState<any>('');
  const getFilePreview = async (fileId: string, fileType: string, fileSize: number) => {
    // 从后端下载文件流
    // 视频文件过大处理
    // download(fileId,fileType, fileSize)
    getTalentFilePreview(fileId).then(async (_file: any) => {
        const {type} = _file;
        // //tiff 图片格式处理
        if(type === 'image/tiff') {
            const buffer = await _file.arrayBuffer();
             Tiff.initialize({ // 这个 Tiff 是一个插件
                TOTAL_MEMORY: 200000000,
              });
            const tiff = new Tiff({buffer: buffer});
            const src  = tiff.toDataURL();
            setTiffSrc(src)
        } else {
            // Blob 格式的文件流 转化成链接---核心
            const blob = new Blob([_file])
            const url = URL.createObjectURL(blob)
            setFile(url);
        }
        
    }).catch((err) => {
        console.log(err, '====err')
    })
  };

  useEffect(() => {
    //非视频文件 根据组件
    if(values && values.id && !videoFileType.includes(values.fileType)) {
        getFilePreview(values.id, values.fileType, values.fileSize)
    }
  }, [values])
  
  return (
    <Modal
      destroyOnClose
      width={1000}
      title={values.fileName}
      visible={previewModalVisible}
      onCancel={() => onCancel()}
      footer={null}
    >
        {
            // 视频文件
            videoFileType.includes(values.fileType)
            ?
            <video controls autoPlay width={'100%'}>
               <source src={`https://gymzy.kg.api.wiimedia.cn/Culture/${values.id}/detail`}></source>
            </video>
        : (tiffSrc
            ?
            <Image
               width={800}
            //    height={800}
               preview={false}
               src={tiffSrc}
            />
            :
            (file
                ? 
                <FileViewer file={file} type={values.fileType} id={values.id}/>
                :
                <div className='spin-box'>
                    <Spin />
                </div>))
        }
    </Modal>
  );
};

export default PreviewForm;
