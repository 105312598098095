import {  Col, Form, Input, DatePicker, Radio } from 'antd';
import '../preCase.css'
interface Props {
    record: any
}

const CaseBasic = (props: Props) => {
    return (
        <>
            <Col span={3}>
               <Form.Item
                 name="recordDate"
                 label="日期"
                 rules={[{ required: false, message: '就诊日期' }]}
               >
                 <Input disabled/>
                 {/* <DatePicker/> */}
               </Form.Item>
             </Col>
             <Col span={4}>
               <Form.Item
                 name="cardNo"
                 label="卡号"
               >
                 <Input placeholder="请输入就诊卡号" disabled style={{ width: '100%'}} />
               </Form.Item>
             </Col>
             <Col span={4}>
               <Form.Item
                 name="department"
                 label="科室"
               >
                 <Input placeholder="请输入科室" disabled />
               </Form.Item>
             </Col>
             <Col span={3}>
               <Form.Item
                 name="patientName"
                 label="姓名"
                 rules={[{ required: true, message: '姓名' }]}
               >
                 <Input  placeholder="请输入姓名" onPressEnter={(e) => e.preventDefault()} />
               </Form.Item>
             </Col>
             <Col span={3}>
               <Form.Item
                 name="gender"
                 label="性别"
                 rules={[{ required: true, message: '性别' }]}
               >
                  <Radio.Group style={{ width: '100%', 'whiteSpace': 'nowrap'}}>
                    <Radio value="2"> 男 </Radio>
                    <Radio value="1"> 女 </Radio>
                </Radio.Group>
               </Form.Item>
             </Col>
             <Col span={4}>
               <Form.Item
                 name="birthday"
                 label="生日"
                 rules={[{ required: true, message: '生日' }]}
               >
                   {
                   //@ts-ignore
                    <DatePicker  style={{ width: '100%'}}/>
                   }
               </Form.Item>
             </Col>
             <Col span={3}>
               <Form.Item
                 name="age"
                 label="年龄"
                 rules={[{ required: false, message: '年龄' }]}
               >
                 <Input disabled />
               </Form.Item>
             </Col>
        </>
    );
};

export default CaseBasic;