import { createAction, handleActions } from 'redux-actions';
import { notification } from 'antd';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/Constants';
import COMMON from '../../lib/Common';

const {
    GET_DIAGNOSIS_DATA,
    GET_ALL_DIAGNOSIS,
    GET_PATIENT_DIAGNOSIS,
    GET_ALL_TREATMENTS,
    GET_PATIENT_TREATMENTS,
    GET_PATIENT_ALL_DISEASE_INFO,
    GET_PATIENT_CURRENT_DISEASE_INFO,
    GET_LOADING,
    SET_WORD_CLOUD,
    TREATMENT_VISIBLE,
    TREATMENT_RECORD,
    DOCTOR_RECORD_LIST,
    MINI_CASE_LIST,
    STUDY_CENTER_DIAGNOSIS,
    STUDY_CENTER_TREATMENTS,
    SET_CURRENT_PATIENT_ID
} = CONSTANTS;
// initialState
const initialState = {
    diagnosisData: {},
    allDiagnosis: [],
    currentDiagnosis: [],
    allTreatments: [],
    currentTreatments: [],
    patientAllDiseaseInfo: {},
    patientCurrentDiseaseInfo: {},
    globalLoading: false,
    wordCloud: {},
    treatmentVisible: false, //
    treatmentRecord: {}, // 推荐治疗
    doctorRecordList:{}, // 就诊
    miniCaseList: {}, // 预诊
    studyCenterDiagnosis: [], //学习中心诊断
    studyCenterTreatments: [], // 学习中心治疗
    currentPatientId:'', //当前就诊者id
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        [GET_DIAGNOSIS_DATA](state: State, action: Action) {
            // 
            return {
                ...state,
                diagnosisData: action.payload,
            };
        },
        [GET_ALL_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                allDiagnosis: action.payload,
            };
        },
        [GET_PATIENT_DIAGNOSIS](state: State, action: Action) {
            let newData = action.payload;
            if (newData) {
                newData = newData.map((item: any) => {
                    return {
                        ...item,
                        dId: item.id,
                        Jf: item.ff
                    }
                })
            }
           
            return {
                ...state,
                currentDiagnosis: newData, // 当前疾病
            };
        },
        [GET_ALL_TREATMENTS](state: State, action: Action) {
            // 
            return {
                ...state,
                allTreatments: action.payload,
            };
        },
        [GET_PATIENT_TREATMENTS](state: State, action: Action) {

            let newData = action.payload;
            if (newData) {
                newData = newData.map((item: any) => {
                    return {
                        ...item,
                        jId: item.id,
                    }
                })
            }
            console.log(newData, '====newData')
            return {
                ...state,
                currentTreatments: newData, // 当前诊断
            };
        },
        [GET_PATIENT_ALL_DISEASE_INFO](state: State, action: Action) {
            return {
                ...state,
                patientAllDiseaseInfo: action.payload,
            };
        },
        [GET_PATIENT_CURRENT_DISEASE_INFO](state: State, action: Action) {
            return {
                ...state,
                patientCurrentDiseaseInfo: action.payload,
            };
        },
        [GET_LOADING](state: State, action: Action) {
            return {
                ...state,
                globalLoading: action.payload,
            };
        },
        [SET_WORD_CLOUD](state: State, action: Action) {
            return {
                ...state,
                wordCloud: action.payload, // 词云
            };
        },
        [TREATMENT_VISIBLE](state: State, action: Action) {
            return {
                ...state,
                treatmentVisible: action.payload,

            };
        },
        [TREATMENT_RECORD](state: State, action: Action) {
            return {
                ...state,
                treatmentRecord: action.payload,
            };
        },
        [DOCTOR_RECORD_LIST](state: State, action: Action) {
            return {
                ...state,
                doctorRecordList: action.payload, //诊断
            };
        },
        [MINI_CASE_LIST](state: State, action: Action) {
            return {
                ...state,
                miniCaseList: action.payload, //预诊
            };
        },
        //学习平台诊断和处置
        [STUDY_CENTER_DIAGNOSIS](state: State, action: Action) {
            return {
                ...state,
                studyCenterDiagnosis: action.payload, //诊断
            };
        },
        [STUDY_CENTER_TREATMENTS](state: State, action: Action) {
            return {
                ...state,
                studyCenterTreatments: action.payload, //治疗
            };
        },
        [SET_CURRENT_PATIENT_ID](state: State, action: Action) {
            return {
                ...state,
                currentPatientId: action.payload, //治疗
            };
        },
    },
    initialState
);

export const actions = {
    getSearchDiagnosis: createAction(GET_DIAGNOSIS_DATA, async (params: { type: string, searchType?: string | any, searchValue?: string | any, searchId?: string | any }) => {
        const { type, searchType, searchValue, searchId } = params;
        let _data = await APIServices.getSearchDiagnosis(searchValue);
        return _data;
    }),
    getAllDiagnosis: createAction(GET_ALL_DIAGNOSIS, async (searchValue: string | undefined, filter: any) => {
        let _data = searchValue ? await APIServices.getDiagnosisByChiefComplaint(searchValue, filter) : [];
        return _data;
    }),
    getCurrentDiagnosis: createAction(GET_PATIENT_DIAGNOSIS),
    getAllTreatments: createAction(GET_ALL_TREATMENTS, async (searchValue: string | undefined, filter: any) => {
        let _data = searchValue ? await APIServices.getTreatmentByDiagnosis(searchValue, filter) : [];
        return _data;
    }),
    getCurrentTreatments: createAction(GET_PATIENT_TREATMENTS),
    getPatientAllDiseaseInfo: createAction(GET_PATIENT_ALL_DISEASE_INFO, async (id: string, pagination: any) => {
        let _data = id ? await APIServices.getPatientAllDiseaseInfo(id, pagination) : [];
        return _data;
    }),
    getPatientCurrentDiseaseInfo: createAction(GET_PATIENT_CURRENT_DISEASE_INFO),
    setGlobalLoading: createAction(GET_LOADING),
    setWordCloud: createAction(SET_WORD_CLOUD),
    setTreatmentVisible: createAction(TREATMENT_VISIBLE),
    setTreatmentRecord: createAction(TREATMENT_RECORD),
    //获取预诊病例
    getDoctorRecord:  createAction(DOCTOR_RECORD_LIST, async (params: any, pagination: any) => {
        return await APIServices.getAllPatientsCases(params, pagination);
    }),
    //获取就诊病例
    getMiniCaseList:  createAction(MINI_CASE_LIST, async (params: any, pagination: any) => {
        return await APIServices.getAllMiniCases(params, pagination);
    }),
    // 学习平台
    setStudyCenterDiagnosis: createAction(STUDY_CENTER_DIAGNOSIS),
    setStudyCenterTreatments: createAction(STUDY_CENTER_TREATMENTS),
    //就诊者id
    setCurrentPatientId: createAction(SET_CURRENT_PATIENT_ID),
};
