
import React, { useState, useEffect } from 'react';
import {
    List,
    CheckList,
    Ellipsis,
} from "antd-mobile";
import { AntOutline } from 'antd-mobile-icons'
import {
LeftOutline
} from 'antd-mobile-icons'
import moment from 'moment';

import { actions as globalAction } from '../../redux/modules/global';
import { useLocation, useNavigate } from 'react-router-dom';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import Common from '../../lib/Common';
const { TYPE_NAME } = Constants;
const { onChiefComplaintGetDiagnosis, onDiagnosisGetTreatment } = APIServices;

const { } = globalAction;
const { NATIONS, GENDER_WOMAN, } = Constants;
const { getAge } = Common;
interface Props {
}

const RACE = NATIONS.map((item) => ({ label: item, value: item }))
const ResultInfo = (props: Props) => {
    const { state }: any = useLocation();
    let navigate = useNavigate();
    const { baseInfo } = state;
    const [diseaseInfo, setDiseaseInfo] = useState<any>([]); // 疾病的数组
    const [diseaseString, setDiseaseString] = useState<any>('')
    const [treatmentInfo, setTreatmentInfo] = useState([]); // 治疗的数组

    // 根据主诉获取疾病和治疗
    useEffect(() => {
        const { chiefComplaint } = state.baseInfo;
        onChiefComplaintGetDiagnosis(chiefComplaint).then((diseaseList: any) => {
            if (diseaseList.length > 3) {
                setDiseaseInfo(diseaseList.slice(0, 3));
            } else {
                setDiseaseInfo(diseaseList);

            }

            diseaseList.length && setDiseaseString(diseaseList[0].zd)
        });
    }, [state]);

    useEffect(() => {
        if (diseaseString.length) {
            onDiagnosisGetTreatment(diseaseString).then((diseaseList: any) => {
                if (diseaseList.length > 3) {
                    setTreatmentInfo(diseaseList.slice(0, 3));
                } else {
                    setTreatmentInfo(diseaseList);

                }
            });
        }
    }, [diseaseString]);

    const backHome = () => {
        navigate(`/scanCode/hospital`);
    };
    useEffect(() => {
        window.document.title = '诊断详情';
    });

    return (
        <div className="mobile-bg">
            <div className='back-hospital' onClick={backHome}>
                <LeftOutline fontSize={30} />
                <span className='back-hospital-text'>返回首页</span>
            </div>
            <div className='patient-info'>
                <div className='patient-bd'>
                    <div className='name'>{baseInfo.patientName}</div>
                    <div className='des'><span>性别: {Number(baseInfo.gender)=== GENDER_WOMAN ? '女' : '男' }</span> <span>年龄: {baseInfo.birthday ? getAge(baseInfo.birthday): 0 }岁</span></div>
                </div>
                <div className='zs'>主诉: {baseInfo.chiefComplaint}</div>
            </div>

            
            <div className='disease-box'>
                <div className='block-title'>疑似诊断</div>
                {
                    diseaseInfo.length
                        ?
                        <CheckList defaultValue={[diseaseInfo[0].zd]} onChange={(val) => setDiseaseString(val)}>
                            {diseaseInfo.map((item: any, index: number) =>
                                <CheckList.Item key={index} value={item.zd}>
                                    <div className='tjd'><div className='d'>{item.tjd} <span>%</span></div><div className='t'>推荐度</div></div>
                                    <div className='name'>{item.zd}</div>
                                </CheckList.Item>)
                            }
                        </CheckList> :
                        <div className='empty-tip'>暂无推荐</div>
                }
            </div>

            <div className='zlfa-box'>
                <div className='block-title'>治疗方案推荐</div>
                <List className='list'>
                    {
                        treatmentInfo.length
                            ?
                            treatmentInfo.map((item: any, index: number) => <List.Item key={index}>
                                <div>
                                    <div className='name'>药名: {item.tName}</div>
                                    <div className='d'>类别: {TYPE_NAME[Number(item.type) - 1]}</div>
                                </div>
                                <div className='des'>
                                    <Ellipsis
                                        content={item.definition}
                                        defaultExpanded={false}
                                        rows={3}
                                        expandText='展开'
                                        collapseText='收起'
                                    /></div>
                            </List.Item>)
                            : <div className='empty-tip'>暂无推荐</div>
                    }
                </List>
            </div>
        </div>
    )
};

export default ResultInfo;
