import { createAction, handleActions } from 'redux-actions';
import { notification } from 'antd';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/Constants';
import COMMON from '../../lib/Common';

const {
    WORD_CLOUD_LIST,
    WORD_CLOUD_SELECT_TAGS,
} = CONSTANTS;
// initialState
const initialState = {
    //主诉词云list
    wordChiefComplaintTagList: [],
    //诊断词云list
    wordDiagnosisTagList: [],
    //治疗词云list
    wordTreatmentTagList: [],
    // 点击选择的tags
    wordClickTags: {
        zsCy: [], //主诉词云tags
        zdCy: {}, // 诊断词云tags
        zlCy: {} // 治疗词云tags
    }
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        //词云list
        [WORD_CLOUD_LIST](state: State, action: Action) {
            let newData = action.payload;
            return {
                ...state,
                //主诉tags
                wordChiefComplaintTagList: newData[0].wordList || [],
                //诊断tags
                wordDiagnosisTagList:  newData[1].wordList || [],
                //治疗tags
                wordTreatmentTagList:  newData[2].wordList || [],
            };
        },
        //治疗列表推荐
        [WORD_CLOUD_SELECT_TAGS](state: State, action: Action) {
            return {
                ...state,
                wordClickTags: action.payload, 
            };
        },
    },
    initialState
);

export const actions = {
    getWordCloudList: createAction(WORD_CLOUD_LIST, async () => {
        return await APIServices.getAllWords();
    }),
    setWordCloudTags: createAction(WORD_CLOUD_SELECT_TAGS)
};
