import {Spin, Alert, Form, Input, Button, Modal, message} from 'antd';
import React, {useState, useEffect} from 'react';
import APIServices from '../../../../lib/APIServices';
import md5 from 'js-md5';

const {changeUserPassword} = APIServices;
const ChangePassword = (props) => {
    const [form] = Form.useForm();
    const {openModal, profile, onCloseModal} = props;
    const onSubmitOk = () => {
        const _profile = JSON.parse(profile);
        const _userId = _profile && _profile.sub || ''; // 当前登录的user id
        form.validateFields().then((value) => {
            // 修改密码
            changeUserPassword(_userId, md5(value.password), value.password).then((res) => {
                message.success('密码修改成功');
            }).catch((err) => {
                message.error('密码修改失败');
            })
            onCloseModal();
        })


    }
    return (
        <Modal title="修改密码"
            okText='确定'
            cancelText='取消'
            visible={openModal}
            onOk={onSubmitOk}
            onCancel={onCloseModal}>
            <Form
                name="basic"
                form={form}
                labelCol={{span: 4}}
                wrapperCol={{span: 20}}
            >
                <Form.Item
                    label="新密码"
                    name="password"
                    rules={[{required: true, message: '新密码不能为空!'}]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="确认密码"
                    name="confirmPassword"
                    rules={[
                        {required: true, message: '确认密码不能为空!'},
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('两次密码输入不一样!')
                            }
                        })
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangePassword;
