
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Table, Spin, Empty, Button, Col, Row, Select, DatePicker, Space, Tag, Form, Modal, Card } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

// import SmartAnalysesItemTable from './SmartAnalysesItemTable';
import APIServices from '../../../../../lib/APIServices';
import Constants from '../../../../../lib/Constants';
import { actions as globalAction } from '../../../../../redux/modules/global';
import { actions as smartAction } from '../../../../../redux/modules/smart';
import SmartAnalysesItemTable from '../../studyCenter/SmartAnalysesItemTable';
import DiagnosisCaseList from '../../diagnosisCase/DiagnosisCaseListDetail';

import '../preCase.css'
const { Option } = Select;
const { getDiagnosisByChiefComplaint, getTreatmentByDiagnosis, getMinZuYiData } = APIServices;
const { TYPE_NAME, CASE_TYPE, CASE_TREATMENT_TYPE } = Constants;

const { Panel } = Collapse;
const {  
    setTreatmentVisible, 
    setTreatmentRecord,
} = globalAction;

const {
    setPreClinicalCurrentDiagnosis, // 当前选择的诊断
    setPreClinicalCurrentTreatment,// 当前选择的治疗
    setPreClinicalDeleteDiagnosis, // 当前取消选择的诊断
    setPreClinicalDeleteTreatment, //当前取消选择的诊断
    setSmartDiagnosisVisible, // 诊断可见
    setSmartTreatmentVisible, // 治疗可见
    setSmartDiagnosisCurrentList, // 选择的list
    setSmartTreatmentsCurrentList, // 选择的list
} = smartAction;

interface Props {
    type?: string
    searchValue: {
        diagnosis: string
        chiefComplaint: string
    }
    initForm: any
}
const PreSmartAnalyses = (props: Props) => {
    const { type, searchValue, initForm } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [selectedRowKeys2, setSelectedRowKeys2] = useState<any>([]);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleCaseDetail, setVisibleCaseDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>([]);
    const [allDiagnosis, setAllDiagnosis] = useState<any>([]);
    const [allTreatments, setAllTreatments] = useState<any>([]);
    const [filterDiagnosis, setFilterDiagnosis] = useState<any>({
        nl1: '',
        nl2: '',
        xb: '',
        year: ''
    });
    const [filterTreatments, setFilterTreatments] = useState<any>({
        mzyId: '',
    });
    const [mzysjzd, setMzysjzd] = useState<any>([]);
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 3,
        total: 0,
    });
    const { 
        smartDiagnosisVisible,
        smartTreatmentVisible,
        preClinicalDeleteValue,preClinicalCurrentDiagnosis,
        preClinicalCurrentTreatment,preClinicalDeleteDiagnosis,
        preClinicalDeleteTreatment, smartModalIndex,
        smartChiefComplaint,
        smartDiagnosisCurrentList,
        smartTreatmentCurrentList
    } = useSelector((state: any) => {
        return {
             //model visible
            smartDiagnosisVisible: state.smart.smartDiagnosisVisible,
            smartTreatmentVisible: state.smart.smartTreatmentVisible,
            preClinicalDeleteValue: state.smart.preClinicalDeleteValue,
            preClinicalCurrentDiagnosis: state.smart.preClinicalCurrentDiagnosis,
            preClinicalCurrentTreatment: state.smart.preClinicalCurrentTreatment,
            preClinicalDeleteDiagnosis: state.smart.preClinicalDeleteDiagnosis,
            preClinicalDeleteTreatment: state.smart.preClinicalDeleteTreatment,
            smartModalIndex: state.smart.smartModalIndex,
            smartChiefComplaint:  state.smart.smartChiefComplaint, //主诉
            // smart select list
            smartDiagnosisCurrentList: state.smart.smartDiagnosisCurrentList,
            smartTreatmentCurrentList: state.smart.smartTreatmentCurrentList,

        }
    });
    const dispatch = useDispatch();

    const columns: any = [
        {
            title: '诊断',
            dataIndex: 'zd',
            key: 'zd',
            // render: (text: any, record: any) => <a target={'_blank'} href={`http://tbxt.mzyx.zydyfy.cn/anquan/details/${record.id}`}>{text}</a>
        },
        {
            title: '推荐度',
            dataIndex: 'tjd',
            key: 'tjd',
            render: (text: any) => <span>{text}%</span>
        },
        {
            title: '参考病历',
            key: 'generalList',
            align: 'center',
            render: (data: any, record: any) => {
                const openDetailPage = () => {
                    setDefaultRecord(record.generalList);
                    setVisibleDetail(true);
                }

                
                // 打开决策的病例
                const onOpenPreCase = (currentCase: any) => {
                    setVisibleCaseDetail(true);
                    setDefaultRecord(currentCase);
                };

                const _data = record.generalList.length > 3 ? record.generalList.slice(0, 3) : record.generalList;
                return <div className='custom-table'>
                    {
                        _data.map((item: any) => <Row align='middle' key={item.id}>
                            <Col span={4}><Tag color="success">{item.patientName}</Tag> </Col>
                            <Col span={3}><Tag color="orange">{item.nl}岁</Tag></Col>
                            <Col span={3}><Tag color="processing">{item.xb}</Tag></Col>
                          {/* 填报病例 1 决策病例 2*/}
                            <Col span={14} className='overflow'>
                                {
                                    item.type == CASE_TYPE.TB &&
                                    <a target={'_blank'} href={`http://tbxt.mzyx.zydyfy.cn/anquan/details/${item.aId}`}>{item.mainTitle}</a>
                                }
                                {
                                    item.type == CASE_TYPE.JC &&
                                    <Button type='link'  style={{ color: '#576d95'}} onClick={() => onOpenPreCase(item)} >{item.mainTitle}</Button>
                                }
                            </Col>
                        </Row>)
                    }
                    {
                        record.generalList.length > 3
                        &&
                        <Row align='middle' wrap={false} >
                            <Button type='text' className='custom-table-btn' onClick={openDetailPage} >更多</Button>
                        </Row>
                    }

                </div>
            },
        }
    ];

    const columns2: any = [
        {
            title: '名称',
            dataIndex: 'tName',
            key: 'tName',
            // align: 'center',
            width: 160,
            render: (text: any, record: any) =>
                <Button
                    type='link'
                    onClick={() => {
                        // 1. 技法 2.发药 3. 诊断(跳转诊断病例)
                        if (record.type === CASE_TREATMENT_TYPE.ZD) {
                            setVisibleCaseDetail(true);
                            setDefaultRecord(record);
                        } else {
                            dispatch(setTreatmentVisible(true));
                            dispatch(setTreatmentRecord(record));
                        }
                    }} >
                    
                    <span className={`tName-${record.type}`}>{text}</span>
                </Button>
        },
        {
            title: '分类',
            dataIndex: 'type',
            key: 'type',
            // align: 'center',
            width: 80,
            render: (text: number) => <span>{TYPE_NAME[Number(text) - 1]}</span>,
        },
        {
            title: '范围',
            dataIndex: 'rane',
            key: 'rane',
            // align: 'center',
            render: (text: any) => <span>{text || '无'}</span>,
        },
    ];

    const rowSelection: any = {
        hideSelectAll: true,
        onChange: (_selectedRowKeys: React.Key[], selectedRows: [any]) => {
            // 选择当前患者的诊断
            setSelectedRowKeys(_selectedRowKeys);
            // setSearchDiagnosisValue(selectedRows.map((item => item.zd)).join(','))
            dispatch(setSmartDiagnosisCurrentList({
                ...smartDiagnosisCurrentList,
                [smartModalIndex]:selectedRows
            }))
        },
        onSelect:(record: any, selected: boolean) => {
            if(selected) {
                // 设置当前选择的诊断, 同时删除删除数组中的当前值
                dispatch(setPreClinicalCurrentDiagnosis({
                    ...preClinicalCurrentDiagnosis,
                    [smartModalIndex]:record
                }))
                const filterDeleteTarget = preClinicalDeleteDiagnosis[smartModalIndex];
                if(filterDeleteTarget && filterDeleteTarget.zd == record.zd ) {
                      dispatch(setPreClinicalDeleteDiagnosis({
                        ...preClinicalDeleteDiagnosis,
                        [smartModalIndex]: {}
                    }))
                }
            }else {
                // 去掉选择的诊断
                dispatch(setPreClinicalDeleteDiagnosis({
                    ...preClinicalDeleteDiagnosis,
                    [smartModalIndex]: record
                }))
            }
            
        },
        getCheckboxProps: (record: any) => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
    const rowSelection2: any = {
        hideSelectAll: true,
        onChange: (_selectedRowKeys: React.Key[], selectedRows: [any]) => {
            // 选择当前患者的诊断
            setSelectedRowKeys2(_selectedRowKeys);
            dispatch(setSmartTreatmentsCurrentList({
                ...smartTreatmentCurrentList,
                [smartModalIndex]: selectedRows
            }))
        },
        onSelect:(record: any, selected: boolean) => {
            if(selected) {
                dispatch(setPreClinicalCurrentTreatment({
                    ...preClinicalCurrentTreatment,
                    [smartModalIndex]: record
                }))
            }else {
                dispatch(setPreClinicalDeleteTreatment({
                    ...preClinicalDeleteTreatment,
                    [smartModalIndex]: record
                }))
            }
            
        },
        getCheckboxProps: (record: any) => ({
            // disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onChange = (date: any, dateString: any, title: any) => {
        setFilterDiagnosis({
            ...filterDiagnosis,
            year: dateString,
        })

    };

    const onChangeSelect = (_value: any, key: string) => {
        if (key === 'mzyId') {
            setFilterTreatments({ mzyId: _value })
        } else {
            if (key === 'nl') {
                if (_value) {
                    const nl1 = _value > 80 ? 80 : _value - 20;
                    const nl2 = _value > 80 ? 200 : _value;
                    setFilterDiagnosis({
                        ...filterDiagnosis,
                        nl1,
                        nl2
                    })
                } else {
                    setFilterDiagnosis({
                        ...filterDiagnosis,
                        nl1: '',
                        nl2: ''
                    })
                }

            } else {
                setFilterDiagnosis({
                    ...filterDiagnosis,
                    [key]: _value,
                })
            }
        }



    }
    const getAllDiagnosis = async (_searchValue: string, filter: any) => {
        let _data = _searchValue ? await getDiagnosisByChiefComplaint(_searchValue, filter) : [];
        setAllDiagnosis(_data);
    }
    const getAllTreatments = async (_searchValue: string, filter: any) => {
        let _data = _searchValue ? await getTreatmentByDiagnosis(_searchValue, filter) : [];
        setAllTreatments(_data);
    }
    const getMinZuYi = async () => {
        const data = await getMinZuYiData();
        setMzysjzd(data)
    }
    //点击取消
    const onCloseModal = () => {
        //关闭窗口
        if(smartDiagnosisVisible) {
            dispatch(setSmartDiagnosisVisible(false));
            // dispatch(setSmartDiagnosisCurrentList(currentDiagnosisList))
            setSelectedRowKeys([]);
        } 
        if(smartTreatmentVisible) {
            dispatch(setSmartTreatmentVisible(false));
            // dispatch(setSmartTreatmentsCurrentList(currentTreatmentList))
            setSelectedRowKeys2([]);
        }
        // onCancel();
    }
    useEffect(() => {
        getMinZuYi();
    }, [])
    // 获取诊断数组
    useEffect(() => {
        // 通过主诉获取诊断
        getAllDiagnosis(smartChiefComplaint, filterDiagnosis);
    }, [smartChiefComplaint, filterDiagnosis])
    
    // 默认选择的选项
    useEffect(() => {
        // 选择的集合
        if(smartDiagnosisCurrentList[smartModalIndex] && smartDiagnosisCurrentList[smartModalIndex].length) {
            const selectList = smartDiagnosisCurrentList[smartModalIndex].map((item: any) => item.zd);
            setSelectedRowKeys(selectList);
        } else {
            setSelectedRowKeys([]);
        }
    }, [smartDiagnosisVisible, smartDiagnosisCurrentList[smartModalIndex]])
    useEffect(() => {
        // 选择的集合
        if(smartTreatmentCurrentList[smartModalIndex] && smartTreatmentCurrentList[smartModalIndex].length) {
            const selectList = smartTreatmentCurrentList[smartModalIndex].map((item: any) => item.tName);
            setSelectedRowKeys2(selectList);

        } else {
            setSelectedRowKeys2([]);
        }
        
    }, [smartTreatmentVisible , smartTreatmentCurrentList[smartModalIndex]])

    // 获取治疗处置数组
    useEffect(() => {
        if(smartTreatmentVisible) {
            const szDiagnosis = initForm.getFieldValue('szDiagnosis');
            const target = szDiagnosis[smartModalIndex];
            if(target && target.zd) {
                getAllTreatments(target.zd, filterTreatments);
            }
        }
        
    }, [smartTreatmentVisible, smartModalIndex, filterTreatments, ])
    return (
        <Modal 
            okText="确认" 
            cancelText="取消" 
            width={'70%'} 
            title="智能推荐" 
            visible={smartDiagnosisVisible || smartTreatmentVisible} 
            onOk={onCloseModal} 
            footer={[]}
            onCancel={onCloseModal}>
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="智能诊断" bordered={false}  style={{ display: smartDiagnosisVisible ? 'block' : 'none' }}>
                        <Form
                            layout='inline'
                        >
                            <Row gutter={16} style={{ width: '100%'}}>
                                <Col span={8} >
                                        <Form.Item label="年龄:">
                                        <Select
                                            showSearch
                                            placeholder="选择年龄段"
                                            optionFilterProp="children"
                                            onChange={(value) => onChangeSelect(value, 'nl')}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            <Option value="">全部</Option>
                                            <Option value="20">20岁以下</Option>
                                            <Option value="40">20-40岁</Option>
                                            <Option value="60">40-60岁</Option>
                                            <Option value="80">60-80岁</Option>
                                            <Option value="200">80岁以上</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                        <Form.Item label="性别:">
                                        <Select
                                            placeholder="选择性别"
                                            onChange={(value) => onChangeSelect(value, 'xb')}
                                        >
                                            <Option value="">全部</Option>
                                            <Option value="男">男</Option>
                                            <Option value="女">女</Option>
                                        </Select>
                                    </Form.Item >
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="年份:">
                                    {
                                        // @ts-ignore
                                        <DatePicker
                                            picker="year"
                                            placeholder='选择时间'
                                            onChange={(data: any, dataString: any) => onChange(data, dataString, 'yarn')} />
                                    }
                                    </Form.Item>
                                </Col>
                            </Row> 
                        </Form>
                        <div className='smart-content smart-content-short'>
                            {/* {默认值是第一个值} */}
                            {
                                allDiagnosis && allDiagnosis.length
                                    ? <Table
                                        rowKey='zd'
                                        rowSelection={{
                                            type: 'checkbox',
                                            ...rowSelection,
                                            selectedRowKeys:selectedRowKeys,  
                                        }}
                                        pagination={pagination}
                                        columns={columns}
                                        dataSource={allDiagnosis}
                                        // loading={!allDiagnosis.length}
                                    />
                                    : <Empty description={'没有数据'} />
                            }
                        </div>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title="治疗方案推荐" bordered={false} style={{ display: smartTreatmentVisible ? 'block' : 'none' }}>
                    <div className='smart-content smart-content-long'>
                            <Form
                                layout='inline'
                            >
                                <Form.Item label="民族医:">
                                    <Select
                                        showSearch
                                        placeholder="选择民族医"
                                        optionFilterProp="children"
                                        onChange={(value) => onChangeSelect(value, 'mzyId')}
                                        // onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        <Option value={''}>全部</Option>
                                        {
                                            mzysjzd && mzysjzd.map((option: any) =>
                                                <Option key={option.itemId} value={option.itemId}>{option.itemName}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                            {
                                allTreatments && allTreatments.length
                                    ? <Table
                                        rowKey='tName'
                                        rowSelection={{
                                            type: 'checkbox',
                                            ...rowSelection2,
                                            selectedRowKeys: selectedRowKeys2,

                                        }}
                                        pagination={pagination}
                                        columns={columns2}
                                        // loading={!allTreatments.length}
                                        dataSource={allTreatments}
                                    />
                                    : <Empty description={'没有数据'} />
                            }
                        </div>
                    </Card>
                </Col>
            </Row>
            {
                visibleDetail
                &&
                <SmartAnalysesItemTable
                    visible={visibleDetail}
                    title='就诊'
                    onCloseDetail={() => setVisibleDetail(false)}
                    record={defaultRecord}
                />
            }

            {
                visibleCaseDetail
                &&
                <DiagnosisCaseList
                    title='病历详情'
                    visible={visibleCaseDetail}
                    record={defaultRecord}
                    onCloseDetail={() => setVisibleCaseDetail(false)}

                />

            }
       </Modal>
      
    )
};

export default PreSmartAnalyses;
