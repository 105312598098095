import {Table, Upload, message, Form} from 'antd';
import React, {useState, useEffect, useRef} from 'react';
import E from 'wangeditor';
// import {UploadConfig} from '@/utils/utils.js';
// import {processEnv} from '@/utils/config';

const Weditor = (props: any) => {
    const {dispatch} = props;
    const editorW: any = useRef();
    const [initialHtml, setInitialHtml] = useState("");

    useEffect(() => {
        if (props.initialHtml) {
            setInitialHtml(props.initialHtml);
        } else {
            initEditor();
        }
    }, [])

    // const backfill = (id: any) => {
    //     setInitialHtml(res.context);
    // }
    useEffect(() => {
        if (initialHtml) {
            initEditor();
        }
    }, [initialHtml])

    // 富文本初始化
    const initEditor = () => {
        let editor = new E(editorW.current)
        editor.config.withCredentials = true;
        editor.config.height = 100; // 设置高度
        // editor.config.uploadImgServer = UploadConfig.action;
        // editor.config.uploadFileName = UploadConfig.name;
        // editor.config.uploadImgHeaders = UploadConfig.headers;
        // editor.config.uploadImgMaxSize = 100;
        // editor.config.uploadImgParams = { // 上传图片时可自定义传递一些参数, 参数会被添加到formdata中
        //     appName: "HMYJ", // 配置AppName
        // };
        editor.config.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'list',  // 列表
        ];
        /* 定义上传图片的默认名字 */
        // eslint-disable-next-line camelcase
        // editor.config.uploadImgHooks = {
        //     customInsert: function (insertImg, result, editor) {
        //         if (result) {
        //             result && result.map((url) => {
        //                 insertImg(`${processEnv.IMAGE_HOST}${url}`);
        //             })
        //         } else {
        //             message.info('文件上传失败');
        //         }
        //     },
        //     error: (response) => {
        //     },
        // };
        editor.config.onchange = (html: any) => {
            props.editorC(props.name, html)
            props.editorText(props.textName, editor.txt.text())
        };
        editor.create();
        editor.txt.html(initialHtml);
    }

    return (
        <div className='rich-text-field' ref={editorW}></div>

    );
};
export default Weditor;
