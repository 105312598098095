import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button, Tag } from 'antd';
import { useSelector } from 'react-redux';

import SmartAnalyse from '../../../cases/components/SmartAnalyse';
import UpdatePatientCase from '../clinicPlatform/AddPatientCase';
import APIServices from '../../../../lib/APIServices';
const {  } = APIServices;
interface Props {
    visible: boolean
    record: any
    onClose: () => void
    title: string
}
const UpdateCase = (props: Props) => {
    const { visible, record, onClose, title } = props;
    const [smartSearch, setSmartSearch] = useState({
        chiefComplaint: '',
        diagnosis: '',
    });

    const getSmartSearch = (_value: any) => {
        setSmartSearch(_value);
    };

    return (
        <Drawer
            title={`${title}诊断`}
            placement="right"
            onClose={onClose}
            width={'80%'}
            visible={visible}
        >
            <div className='treatment-index-layout'>
                <UpdatePatientCase type="update" record={record} onClose={onClose} getSmartSearch={getSmartSearch}  />
                {/* <WordCloud /> */}
                <SmartAnalyse smartSearch={smartSearch} type='add-center-right' />
            </div>
        </Drawer>
    );
};

export default UpdateCase;