import React, {useEffect, useState} from 'react';
import {Layout, Input, Tabs, Button, message, Row, Col} from 'antd';

import DiagnosisCaseList from './components/diagnosisCase/DiagnosisCaseList';
import StudyCenter from './components/studyCenter/StudyCenter';
import PreCaseList from './components/preCase/PreCaseList';
import ClinicPlatform from './components/clinicPlatform/clinicPlatform';
import APIServices from '../../lib/APIServices';

import './diagnosis.css'
const {getAllWords} = APIServices;
const {TabPane} = Tabs;
const AuxiliaryDiagnosis = () => {
    const [patientInfo, setPatientInfo] = useState({});
    const getPatientBasicInfo = (_info: any) => {
        setPatientInfo(_info)
    }
    const onChange = () => { };
    useEffect(() => {
        // 控制页面无操作，缓存没有token 不能跳转登录页面
        getAllWords()
    }, [])
    return (
        <Tabs defaultActiveKey="0" type="card" size='large' onChange={onChange}>
            <TabPane tab={<div className='tab-item'><div style={{margin: '0 10px'}}><img src='/i-zdjl.png' /></div>预诊信息</div>} key="0">
                <PreCaseList />
            </TabPane>
            {
                // <TabPane tab={<div className='tab-item'><div style={{margin: '0 10px'}}><img src='/i-hzxx.png' /></div>就诊平台</div>} key="1">
                //     {/* <div className='zt-wrap'>
                //      <PatientBasicInfo getPatientBasicInfo={getPatientBasicInfo} />
                //      <CaseList />
                //  </div> */}
                //     <ClinicPlatform />
                // </TabPane>
            }
            <TabPane tab={<div className='tab-item'><div style={{margin: '0 10px'}}><img src='/i-zdjl.png' /></div>诊断记录</div>} key="2">
                <DiagnosisCaseList />
            </TabPane>
            <TabPane tab={<div className='tab-item'><div style={{margin: '0 10px'}}><img src='/i-xxpt.png' /></div>学习平台</div>} key="3">
                <StudyCenter />
            </TabPane>
        </Tabs>
    )
};

export default AuxiliaryDiagnosis;