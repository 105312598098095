import { Button, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { text } from 'stream/consumers';
import APIServices from '../lib/APIServices';
import Constants from '../lib/Constants';
import { actions as smartAction } from '../redux/modules/smart';

interface Props {
    onGetValue: (value: any) => void
    type: number
    label: string
    defaultValue?: string
    wordList?: Array<any>
    smartList?: Array<any>
    form: any
    indexNumber: number
};
const { Option } = Select;
const { getQueryMainKey } = APIServices;
const {PRE_CLINICAL_DELETE_TYPE} = Constants;
const { setPreClinicalDeleteValue , setSmartDiagnosisCurrentList , setSmartTreatmentsCurrentList} = smartAction;
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState<any>();
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = async (value: any) => {
            // 多个空格
            const newValueArr = value.split(' ');
            const showOption = newValueArr.some((item: any) => item);
            if (showOption) {
                fetchRef.current += 1;
                const fetchId = fetchRef.current;
                setOptions([]);
                setFetching(true);
                fetchOptions(value, props.type)
                    .then((newOptions: any) => {
                        if (fetchId !== fetchRef.current) {
                            // for fetch callback order
                            return;
                        }
                        const _selfValue: any = [{ value }];
                        value && setOptions(_selfValue.concat(newOptions));
                        setFetching(false);
                    });
            }
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
       <>
        <Select
            labelInValue
            defaultValue={['']}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
        />
       </>
    );
} // Usage of DebounceSelect

async function fetchUserList(_value: any, _type: number) {
    const data = _value && await getQueryMainKey(_type, _value) || [];
    const newValue = data.map((item: any) => {
        return {
            value: item.itemName
        }
    }) || [];
    return newValue;

}

const AutoSelect = (props: Props) => {
    const { label, type, onGetValue, defaultValue, wordList, form , indexNumber} = props;
    const [value, setValue] = useState<any>([]);
    const [valueText, setValueText] = useState<any>([]);
    const [options, setOptions] = useState([]);
    
    const { preClinicalCurrentDiagnosis, preClinicalCurrentTreatment, 
        preClinicalDeleteDiagnosis, preClinicalDeleteTreatment,
        smartModalIndex, preClinicalDeleteValue, 
        smartDiagnosisCurrentList, smartTreatmentCurrentList,
        smartDiagnosisVisible,
        smartTreatmentVisible
    } = useSelector((state: any) => {
        return {
            preClinicalCurrentDiagnosis: state.smart.preClinicalCurrentDiagnosis,
            preClinicalCurrentTreatment: state.smart.preClinicalCurrentTreatment,
            preClinicalDeleteDiagnosis: state.smart.preClinicalDeleteDiagnosis,
            preClinicalDeleteTreatment: state.smart.preClinicalDeleteTreatment,
            preClinicalDeleteValue: state.smart.preClinicalDeleteValue,
            smartModalIndex: state.smart.smartModalIndex,
            smartDiagnosisCurrentList: state.smart.smartDiagnosisCurrentList,
            smartTreatmentCurrentList: state.smart.smartTreatmentCurrentList,
            //model visible
            smartDiagnosisVisible: state.smart.smartDiagnosisVisible,
            smartTreatmentVisible: state.smart.smartTreatmentVisible,
        }
    });
    const dispatch = useDispatch();
    // 点击按钮动态添加值
    const onClickToSelect = (text: string) => {
        if(valueText.includes(text)) {
            // 删除
            const filterValue = value.filter((item: any) => item.value !== text);
            setValue(filterValue)
        } else {
            const _default = {
                disabled: false,
                value: text,
                label: text,
                key: text ,// 随机数字
            }
            setValue([...value, _default])
        }
    }

    const addNewValueToForm = (newText: string) => {
        // 诊断
        const szDiagnosis = form.getFieldValue('szDiagnosis')
        // console.log(szDiagnosis, '=====szDiagnosis')
        if(type === PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS ) {
            const newSzDiagnosis = szDiagnosis.map((item: any, index: number) => {
                if(index === smartModalIndex) {
                    return {
                        ...item,
                        zd: newText.replaceAll('#', '')
                    }
                }else {
                    return item
                }
            })
            //获取疑似诊断字段值
            form.setFieldsValue({
                szDiagnosis:newSzDiagnosis
            })
        }
        //治疗
        if(type === PRE_CLINICAL_DELETE_TYPE.TREATMENT) {
            const newSzDiagnosis = szDiagnosis.map((item: any, index: number) => {
                if(index === smartModalIndex) {
                    return {
                        ...item,
                        mzyScheme: newText.replaceAll('#', '')
                    }
                }else {
                    return item
                }
            })
            //获取疑似诊断字段值
            form.setFieldsValue({
                szDiagnosis:newSzDiagnosis
            })
        }
    }

    useEffect(() => {
        if(defaultValue) {
            const _defaultValue = defaultValue.split(',');
            const _default = _defaultValue.map((text: string) => {
                return  {
                    disabled: false,
                    value: text,
                    label: text,
                    key: text ,// 随机数字
                }
            })
            setValue(_default)
        }
    }, [])
    
    // 将标签插入select 控制select的值
    useEffect(() => {
       const newValueString = value.map((item: any) => item.value).join(',');
       const newValueText  = value.map((item: any) => item.value);
       setValueText(newValueText);
       // 根据form 动态添加值
       addNewValueToForm(newValueString)
       onGetValue(newValueString);
    }, [value])

      // 将标签插入select 控制select的值
      useEffect(() => {
        if(indexNumber === smartModalIndex && smartDiagnosisVisible) {
            if(preClinicalCurrentDiagnosis[smartModalIndex] &&  JSON.stringify(preClinicalCurrentDiagnosis[smartModalIndex]) !== '{}' && type === PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS) {
                const newSmartList = {
                    disabled: false,
                    value: `#${preClinicalCurrentDiagnosis[smartModalIndex].zd}#`,
                    label: `#${preClinicalCurrentDiagnosis[smartModalIndex].zd}#`,
                    key: `#${preClinicalCurrentDiagnosis[smartModalIndex].zd}#` ,// 随机数字
                }
                setValue([...value, newSmartList])
            }
        }
 
     }, [indexNumber, preClinicalCurrentDiagnosis])
     
     useEffect(() => {
        if(indexNumber === smartModalIndex && smartTreatmentVisible) {
            if(preClinicalCurrentTreatment[smartModalIndex] && JSON.stringify(preClinicalCurrentTreatment[smartModalIndex]) !== '{}' && type === PRE_CLINICAL_DELETE_TYPE.TREATMENT) {
                const newSmartList = {
                    disabled: false,
                    value: `#${preClinicalCurrentTreatment[smartModalIndex].tName}#`,
                    label: `#${preClinicalCurrentTreatment[smartModalIndex].tName}#`,
                    key: `#${preClinicalCurrentTreatment[smartModalIndex].tName}#` ,// 随机数字
                }
                setValue([...value, newSmartList])
            }
        }
     
     }, [indexNumber, preClinicalCurrentTreatment])
    // 智能删除tag 联动auto select的值
    useEffect(() => {
        // 诊断
        if(indexNumber === smartModalIndex) {
            if(preClinicalDeleteDiagnosis[smartModalIndex] && JSON.stringify(preClinicalDeleteDiagnosis[smartModalIndex]) !== '{}') {
                const filterValues = value.filter((item: any) => item.value !== `#${preClinicalDeleteDiagnosis[smartModalIndex].zd}#`)
                setValue(filterValues)
            }
        }
    }, [indexNumber, preClinicalDeleteDiagnosis[smartModalIndex]])

    useEffect(() => {
        // 治疗
        if(indexNumber === smartModalIndex) {
            if(preClinicalDeleteTreatment[smartModalIndex] && JSON.stringify(preClinicalDeleteTreatment[smartModalIndex]) !== '{}') {
                const filterValues = value.filter((item: any) => item.value !== `#${preClinicalDeleteTreatment[smartModalIndex].tName}#`)
                setValue(filterValues)
            }
        }
    }, [preClinicalDeleteTreatment[smartModalIndex]])
    return (
       <>
        <DebounceSelect
            mode="multiple"
            type={type}
            value={value}
            placeholder={`输入${label}`}
            fetchOptions={fetchUserList}
            onChange={(newValue: any, e: any) => {
                // 过滤出删除的#项目
                if(newValue.length < value.length) {
                    const newValueString = newValue.map((item: any) => item.value);
                    const deleteTarget = value.find((item: any) => ! newValueString.includes(item.value))
                    if(deleteTarget.value.includes('#')) {
                        // tags 删除诊断 联动智能分析
                        if(type === PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS) {
                            const newKeys = smartDiagnosisCurrentList[smartModalIndex] && smartDiagnosisCurrentList[smartModalIndex].filter((item: any) => `#${item.zd}#` !== deleteTarget.value )
                            dispatch(setSmartDiagnosisCurrentList({
                               ...smartDiagnosisCurrentList,
                               [smartModalIndex]:newKeys
                           }))
                        }
                       // tags 删除治疗 联动智能分析
                        if(type === PRE_CLINICAL_DELETE_TYPE.TREATMENT) {
                            const newKeys = smartTreatmentCurrentList[smartModalIndex] && smartTreatmentCurrentList[smartModalIndex].filter((item: any) => `#${item.tName}#` !== deleteTarget.value )
                            dispatch(setSmartTreatmentsCurrentList({
                               ...smartTreatmentCurrentList,
                               [smartModalIndex]:newKeys
                           }))
                        }
                    }
                   
                }
                setValue(newValue);
               
                
            }}
            style={{
                width: '100%',
            }}
        />
        {
            wordList
            &&
            wordList.map((tag: any) => <Button  onClick={() => onClickToSelect(`${tag}`)} type='dashed'>+{tag}</Button>)
        }
          
         {/* <Button  onClick={() => onClickToSelect('(2)')} type='dashed'>插入文本2</Button> */}
       </>
    );
};

export default AutoSelect;