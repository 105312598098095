import {List} from 'antd';
import './index.css';
interface Props {
    record: any
}
const ChuFangCollection = (props: Props) => {
  const {record} = props;
  const listLabel = [{
    label: '名称',
    value: 'cName'
  }, {
    label: '来源',
    value: 'ly'
  }, {
    label: '功效主治',
    value: 'gxzz'
  }, {
    label: '民族医特色运用',
    value: 'mzytsyy'
  }];
  const splitMzytsyy = (text: any, parentIndex: number) => {
    let _text = '';
    _text = text
    if (typeof _text == 'string' && _text) {
      return _text.split('_').map((item, index) => <p key={`${parentIndex}${index}`}>({index + 1}) {item}</p>)
    }
  };
  return (<List
    itemLayout="vertical"
    size="large"
    pagination={{
      pageSize: 100,
      hideOnSinglePage: true
    }}
    dataSource={record}
    renderItem={(item: any) => (
      <List.Item key={item.id}>
        {
          listLabel.map((_list: any, _index) => <div key={_index} className='chuFangList'>
            <span className='chuFangBoxTitle chuFangListLeft'>{_list.label}: </span>
            <div className='chuFangListRight'>{
              _list.value === 'mzytsyy' ? splitMzytsyy(item[_list.value], _list.id) : item[_list.value]
            }</div>
          </div>)
        }
      </List.Item>
    )}
  />)
};
export default ChuFangCollection;
