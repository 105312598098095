import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space,notification, List, Collapse, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Common from '../../../../../lib/Common';
import Constants from '../../../../../lib/Constants';

import CaseBasic from './CaseBasic';
import CaseHistory from './CaseHistory';
import DoctorDiagnosis from './DoctorDiagnosis';
import APIServices from '../../../../../lib/APIServices';
import { actions as globalAction } from '../../../../../redux/modules/global';
import { actions as smartAction } from '../../../../../redux/modules/smart';
import { actions as wordCloudAction } from '../../../../../redux/modules/wordCloud';
import { actions as prescriptionAction } from '../../../../../redux/modules/prescription';
import '../preCase.css'
import PreSmartAnalyses from './PreSmartAnalyses';
import PrescriptionModalList from './PrescriptionModalList';

const { getAge } = Common;
const { updateNewPatientDiagnosis} = APIServices;
const { setSmartChiefComplaint, setSmartDiagnosis, getAllDiagnosisList, getAllTreatmentsList, setSmartDiagnosisDefaultList, setSmartTreatmentsDefaultList, setSmartDiagnosisCurrentList, setSmartTreatmentsCurrentList} = smartAction;
const { getWordCloudList } = wordCloudAction;
const {setPrescriptionSelectList,} = prescriptionAction;
const { Panel } = Collapse;
interface Props {
    visible: boolean
    record: any
    onClose: () => void
    title: string
}
const UpdateCase = (props: Props) => {
    const [form] = Form.useForm();
    
    const dispatch = useDispatch();
    const { visible, record, onClose, title } = props;
    const [recordValue, setRecordValue] = useState<any>({});
     // 诊断的顺序索引值
     const [smartListFlag, setSmartListFlag] = useState<number>(0);
    // 获取开药的值
    const [prescriptionData, setPrescriptionData] = useState<any>({})
    const { 
        smartDiagnosis,
        smartModalIndex,
        smartDiagnosisCurrentList,
        smartTreatmentCurrentList
    } = useSelector((state: any) => ({
        //treat
        smartDiagnosis: state.smart.smartDiagnosis,
        smartDiagnosisCurrentList: state.smart.smartDiagnosisCurrentList,
        //只能弹窗索引
        smartModalIndex: state.smart.smartModalIndex,
      smartTreatmentCurrentList: state.smart.smartTreatmentCurrentList,
    }));

    //根据表格主诉值得变化 获取
    const onBlurChange = (event: any, all: any) => {
        const key = event[0].name[0];
        const targetValue = event[0].value
        // 诊断改变推荐得值
        if(key === 'szDiagnosis' && event[0].name.length > 2) {
            if(event[0].name[2]  === 'zd') {
                dispatch(setSmartDiagnosis({
                    ...smartDiagnosis,
                    [smartListFlag]: event[0].value
                }))
            }
           
        }
        // 主诉改变
        if( key === 'chiefComplaint') {
            dispatch(setSmartChiefComplaint(targetValue))
        } 
        // 改变生日， 联动年龄
        if( key === 'birthday') {
            form.setFieldsValue({
                age: targetValue ? getAge(targetValue.format('YYYY-MM-DD')): '0'
            })
        } 
    };
    
    // 提交诊断
    const onSubmit = async  (values: any) => {
      // 1. 获取新的值
        // form.resetFields();
        // onCreate(values);
        // 2. 组成需要得数据格式
      // diagnosis list select
      delete values.age;
     // 疑似诊断
      values.szDiagnosis = values.szDiagnosis.map((item: any, index: number) => {
        return {
            ...item,
            prescription: prescriptionData[index],
            sortCode: index ? 0 : 1, // 数组第一个值  排序放在前面
            diagnosis: smartDiagnosisCurrentList[index] || [],
            treatments: smartTreatmentCurrentList[index] && smartTreatmentCurrentList[index].length && smartTreatmentCurrentList[index].map((item: any) => ({
                ...item,
                jId: item.id
            })) || []
        }
      })

      // tags 字符串存储 
      // values.zsCy = wordClickTags.zsCy.length ? wordClickTags.zsCy.join(','): '';// 主诉词云

      // 时间
    //   values.recordDate =  values['recordDate'].format('YYYY-MM-DD');
     values.birthday = values.birthday.format('YYYY-MM-DD');
      values.zdType = 1; // 标记就诊状态
      const newData = values;
      // 3. 调用更新接口
    //  console.log({...record ,...newData})
      const res = await updateNewPatientDiagnosis({...record ,...newData});
      if(res) {
        //   success()
        notification.success({
            message: '保存成功',
            placement: 'topRight',
        });
        }else {
          notification.success({
              message: '保存失败',
              placement: 'topRight',
          });
      }
        //关闭页面
        onClose();
    };

    const getSearchValue  = () => {
        return {
            diagnosis: '',
            chiefComplaint: ''
        }
    }
    useEffect(() => {
        setRecordValue(record);
        // 根据主诉 搜索诊断
        //设置主诉
        record.chiefComplaint && dispatch(setSmartChiefComplaint(record.chiefComplaint))
    },[record])
     
    
    //首次加载
    useEffect(() => {
        // 获取词云
        // dispatch(getWordCloudList());
    }, [])

  
    return (
         <Drawer
             title={`${title}`}
            placement="right"
            onClose={onClose}
            width={'100%'}
            open={visible}
            bodyStyle={{ paddingBottom: 10 }} 
            className='pre-case-container'
       >
        <Form
            form={form}
            layout="horizontal"
            labelAlign="right"
            initialValues={{
            ...record,
            age: record.birthday ? `${getAge(record.birthday)}岁`: '0',
            birthday: record.birthday ? moment(new Date(record.birthday)):moment().format('YYYY-MM-DD'),
            recordDate: record.recordDate ? moment(record.recordDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD'),
            szDiagnosis: record.szDiagnosis || [{
                zd: '',
                diseaseDefinition: '',
                mzyScheme: '',
                xyScheme: '',
                zyScheme: '',
                checksTag: '',
                physicalexamsTag: '',
                signsTag: '',
                prescriptionList: []
            }]
            }}
            onFieldsChange={(_, allFields) => {
                onBlurChange(_, allFields);
              }}
            onFinish={onSubmit}
            >
           
           <Row className='pre-case-btn'>
            <Form.Item key={'submit'} wrapperCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit" className='btn'>
                    保存
                </Button>
            </Form.Item>
            <Form.Item key={'cancel'} wrapperCol={{ span: 24 }}>
                <Button  className='btn'onClick={onClose} >
                    取消
                </Button>
            </Form.Item>

           </Row>
           <Row className='patient-case-info' align={'middle'} gutter={16}>
                {/* 患者信息 */}
                <CaseBasic record={record} />
           </Row>
           <Row className='patient-case-history' justify='space-between' gutter={24}>
                {/* 患者病史和问诊记录 */}
            <Col span={5}>
                <CaseHistory record={record} />
            </Col>
                {/* 主诉 诊断 检查 */}
            <Col span={19} className='patient-case-add'>
                <DoctorDiagnosis 
                    record={record}
                    form={form}
                    getValues={(_values: any) => setPrescriptionData(_values)}
                />
            </Col>
           </Row>
        </Form>
         <div>
            <PreSmartAnalyses searchValue={getSearchValue()} initForm={form}/>
         </div>
         <div>
             <PrescriptionModalList />
         </div>      
       </Drawer>
    );
};

export default UpdateCase;