import React, { useEffect, useState } from 'react';
import { Table, Input, Space, Button, Steps, Spin, Tabs } from 'antd';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
// import TreatmentDetail from './components/treatmentDetail';
import APIServices from '../../lib/APIServices';
import Constants from '../../lib/Constants';
import SameTableTemp from './components/SameTableTemp';
import './sameTechnology.css';

const { Step } = Steps;
const { getSearchTreatment } = APIServices;
const { TYPE_NAME, STEPS } = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;
const { TabPane } = Tabs;
const SameTechnology = () => {
    const [searchValue, setSearchValue] = useState('')
    const [TreatmentData, setTreatmentData] = useState([]);
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>();
    const [count, setCount] = useState<number>(0);
    const [current, setCurrent] = React.useState(0);
    const [loadingStatus, setLoadingStatus] = useState(false);

    const columns: any = [
        {
            title: '共性技术',
            dataIndex: 'tName',
            key: 'tName',
            align: 'center',
            render: (text: any) => <Button type="text">{text}</Button>,
        },
        {
            title: '分类',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (text: number) => <span>{TYPE_NAME[Number(text) - 1]}</span>,
        },
        {
            title: '范围',
            dataIndex: 'rane',
            key: 'rane',
            align: 'center',
            render: (text: any) => <span>{text || '无'}</span>,
        },
        {
            title: '详情',
            key: 'ttbw',
            align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage = () => {
                    setDefaultRecord(record);
                    setVisibleDetail(true);
                }
                return (<Space size="middle" onClick={openDetailPage}>
                    <EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} />
                </Space>)
            },
        }];

    // 获取治疗推荐数据
    const getTreatmentData = async (_value: string) => {
        const _result = await getSearchTreatment(_value);
        setTreatmentData(_result)
        setCurrent(0)
    }
    const onSearch = (value: any, event: any) => {
        if (value.length) {
            setCurrent(1)
            setCount(count + 1);
            setSearchValue(value);
            setLoadingStatus(true);
        } {
            setSearchValue(value);
        }
    }
    const onCloseDetail = (_visible: boolean) => {
        setVisibleDetail(_visible)
    };
    useEffect(() => {
        if (searchValue) {
            getTreatmentData(searchValue)
        }
    }, [searchValue, count]);

    useEffect(() => {
        if (current > STEPS_Start && current < STEPS_End - 1) {
            setTimeout(() => {
                setCurrent(current + 1)
            }, current * 1000)
        }
        if (current == STEPS_End - 1) {
            setTimeout(() => {
                setLoadingStatus(false)
                setCurrent(0);
            }, current * 1000)
        }
    }, [current]);
    console.log('test:===', 123)
    return (
        <div className='treatment-container'>
            <Tabs defaultActiveKey="0" type="card" size='large'>
                <TabPane tab={<div className='tab-item'><div style={{ margin: '0 10px' }}><img src='/i-zdjl.png' /></div>治疗疾病</div>} key="0">
                    <SameTableTemp tabKey='treatDisease' titleName={{name: '疾病', value: 'dName'}} />
                </TabPane>
                <TabPane tab={<div className='tab-item'><div style={{ margin: '0 10px' }}><img src='/i-hzxx.png' /></div>准备材料</div>} key="1">
                    <SameTableTemp tabKey='material'  titleName={{name: '材料', value: 'cName'}} />
                </TabPane>
                <TabPane tab={<div className='tab-item'><div style={{ margin: '0 10px' }}><img src='/i-zdjl.png' /></div>操作方法</div>} key="2">
                    <SameTableTemp tabKey='operation'  titleName={{name: '操作', value: 'cName'}}/>
                </TabPane>
                <TabPane tab={<div className='tab-item'><div style={{ margin: '0 10px' }}><img src='/i-xxpt.png' /></div>禁忌症</div>} key="3">
                    <SameTableTemp tabKey='contraindications' titleName={{name: '禁忌人群', value: 'jName'}}  />
                </TabPane>
                <TabPane tab={<div className='tab-item'><div style={{ margin: '0 10px' }}><img src='/i-zdjl.png' /></div>处方组成</div>} key="4">
                    <SameTableTemp tabKey='recipe'  titleName={{name: '处方', value: 'zName'}} />
                </TabPane>
             </Tabs>
            {/* <div className='tab-box'>
                {
                    loadingStatus
                        ? <Spin tip={current ? STEPS[current].title : STEPS[0].title} size="large" spinning={loadingStatus} className='tip'>
                        </Spin>
                        :
                        <Table loading={loadingStatus} rowKey='id' className='bottom-card' dataSource={TreatmentData} columns={columns} />
                }
            </div> */}
        </div>
    )
};

export default SameTechnology;
