import { createAction, handleActions } from 'redux-actions';
import { notification } from 'antd';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/Constants';
import COMMON from '../../lib/Common';
import { number } from 'echarts/core';

const {
    PRESCRIPTION_SELECT_LIST,
    PRESCRIPTION_NUMBER_LIST,
    PRESCRIPTION_MODAL_INDEX,
    PRESCRIPTION_MODAL_VISIBLE
} = CONSTANTS;
// initialState
const initialState = {
    // 选择的开药list 
    prescriptionSelectList: {},
    prescriptionNumberList: [],
    prescriptionModalIndex: number,
    prescriptionModalVisible: false
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        [PRESCRIPTION_SELECT_LIST](state: State, action: Action) {
            return {
                ...state,
                prescriptionSelectList:  action.payload,
            };
        },
        [PRESCRIPTION_NUMBER_LIST](state: State, action: Action) {
            return {
                ...state,
                prescriptionNumberList: action.payload, 
            };
        },
        [PRESCRIPTION_MODAL_INDEX](state: State, action: Action) {
            return {
                ...state,
                prescriptionModalIndex: action.payload, 
            };
        },
        [PRESCRIPTION_MODAL_VISIBLE](state: State, action: Action) {
            return {
                ...state,
                prescriptionModalVisible: action.payload, 
            };
        },
    },
    initialState
);

export const actions = {
    setPrescriptionSelectList: createAction(PRESCRIPTION_SELECT_LIST),
    setPrescriptionNumberList: createAction(PRESCRIPTION_NUMBER_LIST),
    setPrescriptionModalIndex: createAction(PRESCRIPTION_MODAL_INDEX),
    setPrescriptionModalVisible: createAction(PRESCRIPTION_MODAL_VISIBLE),
};
