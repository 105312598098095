
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, DatePicker, AutoComplete, notification, Select, Radio, InputNumber, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import { actions as globalAction } from '../../../../redux/modules/global';
import APIServices from '../../../../lib/APIServices';
import Constants from '../../../../lib/Constants';
import TreatmentIndex from './treatmentIndex';
const { getPatientInfo, getQueryUserCardNumber, createPatientInfo, updatePatientInfo } = APIServices;
const { Option } = Select;
const { getPatientAllDiseaseInfo, setCurrentPatientId } = globalAction;
const { NATIONS, GENDER, GENDER_MAN } = Constants;
interface Props {
    getPatientBasicInfo: (data: any) => void

}
const initDefaultValue = {
    particularHistory: 0,
    seeDoctor: 0,
    gender: GENDER_MAN, // 0是男 
    // cardNumber: '610521199504050322',
    recordDate: moment(new Date(), 'YYYY-MM-DD'),
    presentIllnessHistory: '无',
    pastHistory: '无',
    allergyHistory: '无',
}
const PatientBasicInfo = (props: Props) => {
    const { getPatientBasicInfo } = props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [options, setOptions] = useState<any>([]);
    const [showSave, setShowSave] = useState(false);
    const [openTreatmentIndex, setTreatmentIndex] = useState(false);
    const [patientInfo, setPatientInfo] = useState<any>({});

    const { patientAllDiseaseInfo } = useSelector((state: any) => {
        return {
            patientAllDiseaseInfo: [],
        }
    });

    const addCaseList = () => {
        if (JSON.stringify(patientInfo) === '{}') {
            message.info('请先填写信息')
        } else {
            setTreatmentIndex(true);
        }

    }
    const onSearchPatientInfo = async (id: any, type?: boolean) => {
        //身份证号
        id = type ? id.target.value : id;
        if (id.replace(/\s/g, '').length) {
            // 是否能根据身份证或者信息
            const res =  await getPatientInfo(id);
            const {id: _id} = res;
            if(_id) {
                const { age, gender, patientName, race, id: _id, cardNumber, birthday , presentIllnessHistory, pastHistory, allergyHistory} = res;
                form.setFieldsValue({
                    ...initDefaultValue, birthday:birthday ? moment(new Date(birthday), 'YYYY-MM-DD'): '', patientName, gender: Number(gender), race, seeDoctor: 1, presentIllnessHistory, pastHistory, allergyHistory
                });
                // 设置基础信息
                getPatientBasicInfo(res);
                setPatientInfo(res)
                // 获取所有病例
                // dispatch(getPatientAllDiseaseInfo(_id, {
                //     pageSize: 3,
                //     pageNum: 1,
                //     total: 0
                // }))
                dispatch(setCurrentPatientId(_id))
            } else {
                setPatientInfo({})
                form.setFieldsValue({
                    ...initDefaultValue,
                    age: '',
                    patientName: '',
                    race: '',
                    cardNumber: id,
                })
                // dispatch(getPatientAllDiseaseInfo('', {
                //     pageSize: 3,
                //     pageNum: 1,
                //     total: 0
                // }))
                dispatch(setCurrentPatientId(''))
                notification.info({
                    message: '没有查询到信息',
                    placement: 'topRight',
                });
            }
        } else {

            notification.info({
                message: '身份证不能为空',
                placement: 'topRight',
            });
        }

    };

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    const onSelect = (value: string) => {
        // 查询当前身份人的信息
        onSearchPatientInfo(value);
        setShowSave(true);
    };

    const handleSearch = async (value: string) => {
        if (value.replace(/\s/g, '').length) {
            const data = await getQueryUserCardNumber(value.toString());
            const newData = data && data.map((item: any) => {
                return {
                    value: item.cardNumber,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            key={item.cardNumber}
                        >
                            <span>{item.cardNumber}</span>
                            <span>{item.patientName}</span>

                        </div>)
                }
            })
            setOptions(newData)
        } else {
            setOptions([])
        }
    };
    // submit
    const onFinish = (values: any) => {
        values.recordDate = values.recordDate.format('YYYY-MM-DD');
        values.birthday = values.birthday.format('YYYY-MM-DD');
        getPatientBasicInfo(values);
        setPatientInfo({ ...patientInfo, ...values });
        // save patient case data
        const { patientName, age, cardNumber, gender, race,birthday, particularHistory, particularHistoryText, presentIllnessHistory, pastHistory, allergyHistory, seeDoctor, recordDate } = values;
        const newData = {
            ...patientInfo,
            patientName,
            age,
            cardNumber,
            gender: gender.toString(),
            race,
            // particularHistory,
            // particularHistoryText,
            // presentIllnessHistory,
            // pastHistory,
            // allergyHistory,
            seeDoctor,
            recordDate,
            birthday,
        }
        // 创建或者编辑
        if (patientInfo.id) {
            // 保存患者基础信息
            updatePatientInfo(newData).then((res: any) => {
                if (res) {
                    // form.resetFields();
                    notification.success({
                        message: '保存成功',
                        placement: 'topRight',
                    });
                }

            }).catch((err) => {
                notification.success({
                    message: '保存失败',
                    placement: 'topRight',
                });
            })
        } else {
            // 保存患者基础信息
            createPatientInfo(newData).then((res: any) => {
                if (res) {
                    // form.resetFields();
                    notification.success({
                        message: '保存成功',
                        placement: 'topRight',
                    });
                }

            }).catch((err) => {
                notification.success({
                    message: '保存失败',
                    placement: 'topRight',
                });
            })
        }

    };

    const onReset = () => {
        form.resetFields();
        setPatientInfo({});
    };
    const onFinishFailed = (error: any) => {
        console.error(error);
    };

    return (

        <fieldset className='zt-left'>
            <legend>就诊</legend>
            <Form
                form={form}
                name="basic"
                initialValues={initDefaultValue}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                // onFieldsChange={onFieldsChange}
                autoComplete="off">
                <Row style={{ width: '70%', margin: '0 auto' }}>
                    <Col span="24">
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            name="cardNumber"
                            label={'身份证号'}
                            rules={[{ required: true, message: `身份证号不能为空` }]}
                        >
                            <AutoComplete
                                dropdownMatchSelectWidth={252}
                                // style={{ width: 300 }}
                                options={options}
                                onSelect={onSelect}
                                onSearch={handleSearch}
                            >
                                <Input.Search
                                    onPressEnter={(e: any) => {
                                        handleSearch(e.target.value)
                                        e.preventDefault();
                                    }}
                                    onSearch={(_value) => onSearchPatientInfo(_value)}
                                    onBlur={(_value: any) => onSearchPatientInfo(_value, true)}
                                    type="primary"
                                    placeholder={`输入患者身份证号码`}
                                    size='large'
                                    allowClear
                                />
                            </AutoComplete>
                        </Form.Item>
                    </Col>

                    <Col span={12} key={'patientName'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'患者姓名'}
                            name={'patientName'}
                            rules={[{ required: true, message: `患者姓名不能为空` }]}
                        >
                            <Input
                                size='large'
                                style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                onPressEnter={(e) => e.preventDefault()}
                                placeholder={`输入患者姓名`}
                            />

                        </Form.Item>
                    </Col>
                    <Col span={12} key={'birthday'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'生日'}
                            name={'birthday'}
                            rules={[{ required: true, message: `年龄不能为空` }]}
                        >
                            {/* <InputNumber

                                size='large'
                                style={{ width: '100%', border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                onPressEnter={(e) => e.preventDefault()}
                                placeholder={`输入年龄`}
                            /> */}
                            {   
                                //@ts-ignore
                                 <DatePicker    
                                    size='large'
                                    format={'YYYY-MM-DD'}
                                    placeholder={`选择生日`}
                                    style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                    suffixIcon={<img src='/i-date.png' />} />
                            }

                        </Form.Item>
                    </Col>
                    <Col span={12} key={'gender'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'性别'}
                            name={'gender'}
                            rules={[{ required: true, message: `性别不能为空` }]}
                        >
                            <Select
                                size='large'
                                showSearch
                                placeholder={`选择性别`}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                suffixIcon={<img src='/i-select.png' />}
                            >
                                {
                                    GENDER.map((item: any) => <Option key={item.value} value={item.value}>{item.name}</Option>)
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={12} key={'race'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'族别'}
                            name={'race'}
                            rules={[{ required: true, message: `族别不能为空` }]}
                        >
                            <Select
                                size='large'
                                showSearch
                                placeholder={`选择族别`}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                suffixIcon={<img src='/i-select.png' />}
                            >
                                {
                                    NATIONS.map((option) => (
                                        <Option key={option} value={option}>{option}</Option>
                                    ))
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={12} key={'recordDate'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'诊断日期'}
                            name={'recordDate'}
                            rules={[{ required: true, message: `诊断日期不能为空` }]}
                        >
                            {
                                //@ts-ignore
                                <DatePicker
                                    size='large'
                                    format={'YYYY-MM-DD'}
                                    placeholder={`选择日期`}
                                    style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                    suffixIcon={<img src='/i-date.png' />}
                                />
                            }

                        </Form.Item>
                    </Col>
                    <Col span={12} key={'seeDoctor'}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            label={'就诊状态'}
                            name={'seeDoctor'}
                            rules={[{ required: true, message: `就诊状态不能为空` }]}
                        >
                            <Select
                                size='large'
                                placeholder={`选择就诊状态`}
                                disabled
                                suffixIcon={<img src='/i-select.png' />}
                            >
                                <Option key={0} value={0}>初诊</Option>
                                <Option key={1} value={1}>复诊</Option>
                            </Select>

                        </Form.Item>
                    </Col>
                    {/* <Col span={24} key={'particularHistory'}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={'特殊病史'}
                            name={'particularHistory'}
                            rules={[{ required: true, message: `特殊病史不能为空` }]}
                        >
                            <Radio.Group onChange={onChange} value={value}>
                                <Radio key={0} value={0}>无</Radio>
                                <Radio key={1} value={1}>有</Radio>
                            </Radio.Group>

                        </Form.Item>
                    </Col> */}

                    {/* <Col span={24} key={'particularHistoryText'}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={'描述'}
                            hidden={!Boolean(value)}
                            name={'particularHistoryText'}
                            rules={[{ required: Boolean(value), message: `描述不能为空` }]}
                        >
                            <Input.TextArea
                                style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                placeholder={`输入描述`}
                            />

                        </Form.Item>
                    </Col>

                    <Col span={24} key={'presentIllnessHistory'}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={'现病史'}
                            name={'presentIllnessHistory'}
                            rules={[{ required: true, message: `现病史不能为空` }]}
                        >
                            <Input.TextArea
                                style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                placeholder={`输入现病史`}
                            />

                        </Form.Item>
                    </Col>
                    <Col span={24} key={'pastHistory'}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={'既往史'}
                            name={'pastHistory'}
                            rules={[{ required: true, message: `既往史不能为空` }]}
                        >
                            <Input.TextArea
                                style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                placeholder={`输入既往史`}
                            />

                        </Form.Item>
                    </Col>
                    <Col span={24} key={'allergyHistory'}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={'过敏史'}
                            name={'allergyHistory'}
                            rules={[{ required: true, message: `过敏史不能为空` }]}
                        >
                            <Input.TextArea
                                style={{ border: '1px solid #ccc', boxShadow: 'inset 1px 1px 1px 0 rgba(0, 0, 0, 0.1)' }}
                                placeholder={`输入过敏史`}

                            />
                        </Form.Item>
                    </Col> */}

                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            label={' '}
                            className='btn-box'
                        >
                            <Button type="primary" htmlType="submit" size='large'>
                                保存
                            </Button>
                            <Button size='large' htmlType="button" onClick={onReset}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="button" onClick={addCaseList} size='large'>
                                就诊
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>

            {
                openTreatmentIndex
                &&
                <TreatmentIndex
                    title='就诊'
                    visible={openTreatmentIndex}
                    onClose={() => { setTreatmentIndex(false); }}
                    record={patientInfo}
                />
            }
        </fieldset >
    )
};

export default PatientBasicInfo;
