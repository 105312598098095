import React, { useState, useEffect } from 'react';
import { Drawer, Tabs } from 'antd';
import APIServices from '../../../lib/APIServices';
const { TabPane } = Tabs;
const {  getJiFaDetail} = APIServices;

interface Props {
    values: any
    onCancel: () => void
    visible: boolean
    title:string
}
const JiFaDetail = (props: Props) => {
  const { values ,visible , onCancel, title} = props;
//   const [values, setValues] = useState<any>([]); //技法详情
  const [cjbczjs, setCjbczjs] = useState<any>([]); //// 适用的常见疾病
  const [cyqjjjbczff, setCyqjjjbczff] = useState<any>([]); // 常用器具及基本操作方法
   // 递归组件 - 展示节点内容
   const recursiveHtml = (dataArr: any, xuhao: any) => {
    // 层级数
    return dataArr.map((item: any, index: any) => {
      item.code = `${xuhao}${xuhao === '' ? '' : '.'}${index + 1}`;
      return <div key={index} >
        <div>
          <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '8px' }}>{item.code} {item.isTitle}</div>
          {
            item.isSHow ? <div style={{ marginLeft: '2em', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: item.isText }}></div> : null
          }
        </div>
        {item.children.length > 0 ? recursiveHtml(item.children, item.code) : null}
      </div>
    })
  }
  const getDetail =async (id:any) => {
    const result = await getJiFaDetail(id);
    const {  cyqjjjbczff: _cyqjjjbczff, cjbczjs: _cjbczjs} = result;
    // setValues(tB_TTBZ);
    setCjbczjs(_cjbczjs);
    setCyqjjjbczff(_cyqjjjbczff);
  }
  useEffect(() => {
    getDetail(values.id)
   
 }, [values])
  return (
    <Drawer
      title={title}
      onClose={() => {
        onCancel();
      }}
      className='detail-container'
      width={'50%'}
      visible={visible}
    >
      <Tabs defaultActiveKey='1'>
        <TabPane tab="基本信息" key="1">
          <div className="titBox">
            <div><span>技术名称:</span> <span className="textBox">{values.title}</span></div>
            <div><span>术语和定义:</span> <span className="textBox">{values.syhdy}</span></div>
            <div><span>范围:</span> <span className="textBox">{values.fw}</span></div>
          </div>
        </TabPane>
        {
          values.jjz ?
            <TabPane tab="禁忌症" key="2">
              <div dangerouslySetInnerHTML={{ __html: values.jjz }}></div>
            </TabPane> : null
        }
        {
          values.czffmc ?
            <TabPane tab={values.czffmc} key="3">
              {
                cjbczjs && cjbczjs.length && recursiveHtml(cjbczjs, '')
              }
            </TabPane> : null
        }
        {
          values.czffmc ?
            <TabPane tab={values.czjsmc} key="4">
              {
                cyqjjjbczff && cyqjjjbczff.length && recursiveHtml(cyqjjjbczff, '')
              }
            </TabPane> : null
        }
        
        {
          values.zysx ?
            <TabPane tab="注意事项" key="5">
              <div dangerouslySetInnerHTML={{ __html: values.zysx }}></div>
            </TabPane> : null
        }
        {
          values.ycqkjclcs ?
            <TabPane tab="异常情况及处理措施" key="6">
              <div dangerouslySetInnerHTML={{ __html: values.ycqkjclcs }}></div>
            </TabPane> : null
        }
      </Tabs>

    </Drawer>
  )
};
export default JiFaDetail;
