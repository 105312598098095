
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { MapChart, EffectScatterChart ,ScatterChart  } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import  geoJson from './MapData.json';
import APIServices from '../../../lib/APIServices';

const { getMapChartForRecord } = APIServices;
echarts.use([MapChart]);
echarts.use([CanvasRenderer, EffectScatterChart, ScatterChart ]);
echarts.registerMap('china', ({geoJSON: geoJson}) as any );

const  ExtendChainMap = () =>{
    const ref: any = useRef(null);
    let mapInstance = null;
    const chinaMapData = geoJson.features.map((item: any) => {
        return {
            name: item.properties.name,
            value: item.properties.center
        }
    });


    const convertData = (initData: any) => {
        const convert =  initData.map((item: any, index: number) => {
                const res = chinaMapData.map((item2: any) => {
                const reg = new RegExp(`${item2.name}`);
                if(reg.test(item.name) && item.name && item2.name) {
                    return {
                        name: item2.name,
                        value: [ ...item2.value,item.value]
                    }
                }
            }).filter(Boolean)
            return res;
        })
        return convert.flat();
    }
    const mergeData = (initData: any) => {
        const convert =  initData.map((item: any, index: number) => {
                const res = chinaMapData.map((item2: any) => {
                const reg = new RegExp(`${item2.name}`);
                if(reg.test(item.address) && item.address && item2.name) {
                    return {
                        name: item2.name,
                        value: item.bCount
                    }
                }
            }).filter(Boolean)
            return res;
        })
        return convert.flat();
    }
    //
    const compare = (arr: [], judgeKey: any, mergeKey: any) => {
        let result: any = [];
        arr.forEach((item:any) => {
            let index= -1;
            result.forEach((m: any, n:any) => {
                if(m[judgeKey] == item[judgeKey]) {
                    index = n;
                }; 
            })
            if(index != -1) {
                const {value} = item;
                result[index][mergeKey] += item[mergeKey];
            }else {
                result.push(item)
            }
        });
        return result;
    }
    const renderChart = async () => {
    // 获取数据
    const _recordData = await getMapChartForRecord();
    const _compareData = compare(mergeData(_recordData), 'name', 'value');
    // 基于准备好的dom，初始化echarts实例
    mapInstance = echarts.init(ref.current);
    mapInstance.setOption({
        // backgroundColor: '#051b4a',
        title: {
            show: true, //是否显示标题
            text: '推广人数', //标题名称
            left: 'center',
            textStyle: {
                // fontSize: 35,
                color: '#1890ff'
            },
            top: '10px'
        },
        geo: {
            map: 'china',
            zoom: 1.2,
            label: {
                normal: {
                    show: false,
                    color: '#c1c4c8'
                },
                emphasis: {
                    show: true,
                    color: '#292929'
                }
            },
            roam: true,
            itemStyle: {
                normal: {
                    borderColor: '#1890ff',
                    borderWidth: 1,
                    areaColor: {
                        type: 'radial',
                        x: 0.5,
                        y: 0.5,
                        r: 0.8,
                        colorStops: [{
                            offset: 0,
                            color: 'rgba(36, 100, 232, 0)' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: 'rgba(36, 100, 232, .8)' // 100% 处的颜色
                        }],
                        globalCoord: false // 缺省为 false
                    },
                    shadowColor: 'rgba(36, 100, 232, 1)',
                    // shadowColor: 'rgba(255, 255, 255, 1)',
                    shadowOffsetX: -2,
                    shadowOffsetY: 2,
                    shadowBlur: 10 
                },
                emphasis: {
                    areaColor: '#fff',
                    borderWidth: 0
                }
            }
        },
        //属性值
        series: [
            {
                name: 'Top 5',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                data: convertData(_compareData),
                symbolSize:(val: any) => {
                   // 控制size大小 1-10
                    // let size = 20
                    // if(val[2] >= 1000) {
                    //     size = 100
                    // }else if(val[2] >= 100 && val[2] < 1000) {
                    //     size = val[2] / 10  * 0.5
                    // }else if(val[2] >= 10 && val[2] < 100) {
                    //     size = val[2] / 1 * 0.5
                    // }else {
                    //     size = val[2]  * 0.5
                    // }
                   return val[2] / 100;
                    
                },
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke'
                },
                hoverAnimation: true,
                label: {
                    normal: {
                        formatter: (params: any) => {
                            return `${params.name}:${params.value[2]}(人)`;
                        },
                        position: 'right',
                        show: true,
                        color: '#000'
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#f5804d'
                    }
                },
                zlevel: 10
            },
        ]
    })
    
  };

    useEffect(() => {
        renderChart();
    }, []);
  

    return (
        <div style={{  height: "400px",}} ref={ref}></div>
    );
}

export default  ExtendChainMap;