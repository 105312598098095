import React, { useEffect, useState } from 'react';
import { EditableProTable, } from '@ant-design/pro-components';
import { Drawer, Col, Row, Form, Table, Button} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { actions as smartAction } from '../../../../../redux/modules/smart';
import { actions as prescriptionAction } from '../../../../../redux/modules/prescription';
import APIServices from '../../../../../lib/APIServices';
import Common from '../../../../../lib/Common';
import Constants from '../../../../../lib/Constants';
import TextArea from 'antd/lib/input/TextArea';

import '../../../diagnosis.css'
import PreAutoSelect from '../../../../../components/PreAutoSelect';
import Weditor from '../../../../../components/wEditor';


const { PRE_CLINICAL_DELETE_TYPE,TREATMENT_TYPE} = Constants;
const { setSmartDiagnosisVisible, setSmartTreatmentVisible, setSmartModalIndex } = smartAction;
const {setPrescriptionModalVisible, setPrescriptionModalIndex, setPrescriptionSelectList} = prescriptionAction;
interface Props {
    record: any
    getValues:(data: any) => void
    form: any
}

const DoctorDiagnosis = (props: Props) => {
    const { record, form, getValues} = props;
    const [editableKeys, setEditableRowKeys] = useState<any>({});
    const [dataSource, setDataSource] = useState<any>({});
    // 显示治疗
    const [showTreatmentType, setShowTreatmentType] = useState<any>(TREATMENT_TYPE.MZ);
    const { 
        prescriptionSelectList
     } = useSelector((state: any) => ({
        // 开药
        prescriptionSelectList: state.prescription.prescriptionSelectList,
    }));

    const dispatch = useDispatch();
   
      
    const columns: any = [
        {
            title: '名称',
            dataIndex: 'yName',
            key: 'yName',
            // width: '20%',
            renderFormItem: (res: any, { isEditable }: any) => {
               const {entry} = res;
                return <>{entry['yName']}</>;
            },
        },
        {
            title: '数量',
            dataIndex: 'kysl',
            valueType: 'digit',
            // width: '20%',
        },
        {
            title: '单位',
            // width: '25%',
            dataIndex: 'jldw',
        },
        {
            title: '操作',
            valueType: 'option',
            // width: '10%',
            render: () => {
              return null;
            },
          },
    ];
 
    const onGetValue = (_value: any) => {
        // getValues(_value)
    }
    const openSmartModal = (type: number, index: number) => {
        // 打开诊断弹窗
        if(type === PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS) {
            dispatch(setSmartDiagnosisVisible(true));
        }
        // 打开治疗弹窗
        if(type === PRE_CLINICAL_DELETE_TYPE.TREATMENT) {
            dispatch(setSmartTreatmentVisible(true));
        }
        // 设置弹窗索引
        dispatch(setSmartModalIndex(index));
    }
    const onOpenPrescriptionModal = (key: number) => {
        // 设置可见 设置索引
        dispatch(setPrescriptionModalVisible(true));
        dispatch(setPrescriptionModalIndex(key));
    }
    // 根据索引获取当前开药列表
    const concatSelectListAndTableList = (selectList:any, tableList: any) => {
       let list: any = {};
       for(let key in selectList) {
        const _selectArr = selectList[key];
        const _tableArr: [] = tableList[key] || [];
        let targetArr: any = [];
        if(!_tableArr.length) {
            targetArr = _selectArr;
        }else{
            // 弹窗删除联动table
           const _selectArrIds = _selectArr.map((item: any) => item.yId);
           const _filterTableArr = _tableArr.filter((item: any) => _selectArrIds.includes(item.yId)) // 联动删除
           const allArr = [..._selectArr, ..._filterTableArr]; // 合并
           const res = new Map();
           const setAllArr = allArr.filter((item: any) => !res.has(item.yId) && res.set(item.yId, 1)); // 去重
           targetArr = setAllArr;
        }
        list[key] = targetArr
        setEditableRowKeys({
            ...editableKeys,
            [key]: targetArr.map((item: any) => item.yId)
        })
       }
       setDataSource(list);
    }

    // 富文本函数
    const editorC = (name: string, val: any) => {
        form.setFieldsValue({
            szDiagnosis: [{
            ...form.getFieldValue('szDiagnosis')[0], // 结构表单当前数据
            [name]: val
            }]
        
        })
    };

    const editorText = (name: string, text: any) => {
        // record[name] = text;
    };

    
    useEffect(() => {
       if(JSON.stringify(prescriptionSelectList) !== '{}') {
        let _dataList: any = {};
        for(let key in prescriptionSelectList) {
            _dataList[key] = [];
            if(JSON.stringify(prescriptionSelectList[key]) !== '{}') {
                const targetObj = prescriptionSelectList[key];
                for(let key2 in targetObj) {
                    targetObj[key2] && _dataList[key].push(...targetObj[key2])
                } 
            }
        }
        // 合并同类项目
        concatSelectListAndTableList(_dataList, dataSource)
       }
    }, [prescriptionSelectList])

    useEffect(() => {
        // 传送开药数据
        getValues(dataSource)
    }, [dataSource])

    return (
        <>
         <Row>
            <Col className='case-title-row' span={24}>
                <Button className='case-title-btn' disabled>主诉</Button>
            </Col>
            <Col span={24}>
               <Form.Item
                 name="chiefComplaint"
                 rules={[{ required: true, message: '主诉' }]}
               >  
                {/* {禁止回车提交} */}
                 <TextArea  placeholder="请输入主诉"   />
               </Form.Item>
            </Col>
         </Row>
         <Form.List name="szDiagnosis">
            {(fields, { add, remove }) => (
            <div className='szDiagnosis-item'>
                {fields.map(({ key, name, ...restField }) => {
                   return (
                    <>
                     {
                         key === 0
                         &&
                         <Row key={key}   className='doctor-module' gutter={8}>
                         <Col span={24} hidden>
                             <Row justify='space-between' align='middle' className='doctor-module-title' hidden>
                                 <Col><span className='doctor-module-title-text'>{key + 1}. 诊断</span></Col>
                                 <Col>
                                     <Button 
                                         type='primary'
                                         hidden
                                         onClick={() => remove(name)}
                                     >
                                         删除
                                     </Button></Col>
                             </Row>
                             
                         </Col>
                         <Col span={18}>
                             <Row gutter={8}>
                                 <Col className='case-title-row' span={24}>
                                     <Button className='case-title-btn' disabled>诊断</Button>
                                 </Col>
                                 <Col span={21}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'zd']}
                                         // label="诊断:"
                                         rules={[{ required: false, message: '诊断' }]}
                                     >
                                         <PreAutoSelect
                                             type={PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS}
                                             indexNumber={key}
                                             label={'诊断'}
                                             defaultValue={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].zd || ''}
                                             onGetValue={onGetValue}
                                             form={form}
                                         />
                                     </Form.Item>
                                 </Col>
                                 <Col span={2}>
                                     <Form.Item>
                                         <Button type="primary" onClick={() => {openSmartModal(PRE_CLINICAL_DELETE_TYPE.DIAGNOSIS, key)}} >智能推荐</Button>
                                     </Form.Item>
                                 </Col>
                             </Row>
                             <Row>
                                 <Col className='case-title-row' span={24}>
                                     <Button className='case-title-btn' disabled>疾病描述</Button>
                                 </Col>
                                 <Col span={24}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'diseaseDefinition']}
                                         // label="疾病描述:"
                                         rules={[{ required: false, message: '疾病描述' }]}
                                     >
                                                       {/* <TextArea placeholder="请输入疾病描述" /> */}
                                                <Weditor name={`diseaseDefinition`} textName={`diseaseDefinition`} editorC={editorC} editorText={editorText} initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].diseaseDefinition}  placeholder="请输入疾病描述"/>
                                     </Form.Item>
                                 </Col>
                             </Row>
                             <Row gutter={8}>
                                 <Col className='case-title-row' span={24}>
                                     <Button className={`case-title-default-btn ${showTreatmentType == TREATMENT_TYPE.MZ && 'case-title-btn'} `} onClick={() => setShowTreatmentType(TREATMENT_TYPE.MZ)}>民族医治疗</Button>
                                     <Button className={`case-title-default-btn ${showTreatmentType ==TREATMENT_TYPE.EN && 'case-title-btn'} `} onClick={() => setShowTreatmentType(TREATMENT_TYPE.EN)}>西医治疗</Button>
                                     <Button className={`case-title-default-btn ${showTreatmentType == TREATMENT_TYPE.ZH && 'case-title-btn'} `} onClick={() => setShowTreatmentType(TREATMENT_TYPE.ZH)}>中医治疗</Button>
                                 </Col>
                                 <Col span={21}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'mzyScheme']}
                                         // label="民族医治疗:"
                                         rules={[{ required: false, message: '民族医治疗' }]}
                                         hidden={!(showTreatmentType === TREATMENT_TYPE.MZ)}
                                     >
                                         <PreAutoSelect
                                             type={PRE_CLINICAL_DELETE_TYPE.TREATMENT}
                                             defaultValue={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].mzyScheme || ''}
                                             label={'民族医治疗'}
                                             onGetValue={onGetValue}
                                             form={form}
                                             indexNumber={key}
                                         />
                                     </Form.Item>
                                 </Col>
                                 <Col span={2}>
                                     <Form.Item hidden={!(showTreatmentType === TREATMENT_TYPE.MZ)}>
                                         <Button type="primary" onClick={() => {openSmartModal(PRE_CLINICAL_DELETE_TYPE.TREATMENT, key)}} >智能推荐</Button>
                                     </Form.Item>
                                 </Col>
                                 <Col span={24}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'xyScheme']}
                                         // label="西医治疗:"
                                         rules={[{ required: false, message: '西医治疗' }]}
                                         hidden={!(showTreatmentType === TREATMENT_TYPE.EN)}
                                     >
                                         {/* <TextArea placeholder="请输入西医治疗" /> */}
                                            <Weditor
                                                name={`xyScheme`}
                                                textName={`xyScheme`}
                                                editorC={editorC}
                                                editorText={editorText}
                                                initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].xyScheme}
                                                placeholder="请输入西医治疗"
                                            />
                                     </Form.Item>
                                 </Col>
                                 <Col span={24}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'zyScheme']}
                                         // label="中医治疗:"
                                         rules={[{ required: false, message: '中医治疗' }]}
                                         hidden={!(showTreatmentType === TREATMENT_TYPE.ZH)}
                                     >
                                                       {/* <TextArea placeholder="请输入中医治疗" /> */}
                                            <Weditor
                                                name={`zyScheme`}
                                                textName={`zyScheme`}
                                                editorC={editorC}
                                                editorText={editorText}
                                                initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].zyScheme}
                                                placeholder="请输入中医治疗"
                                            />
                                     </Form.Item>
                                 </Col>
                                 
                             </Row>
                         
                         <Row gutter={8}>
                            
                         </Row>
                         {/* 建议检查 */}
                         <Row>
                             <Col className='case-title-row' span={24}>
                                 <Button className='case-title-btn' disabled>建议检查</Button>
                             </Col>
                             <Col span={24}>
                                 <Form.Item
                                     {...restField}
                                     name={[name, 'checksTag']}
                                     // label="建议检查:"
                                     rules={[{ required: false, message: '建议检查' }]}
                                 >
                                        {/* <TextArea placeholder="请输入建议检查" /> */}
                                         <Weditor
                                                name={`checksTag`}
                                                textName={`checksTag`}
                                                editorC={editorC}
                                                editorText={editorText}
                                                initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].checksTag}
                                                placeholder="请输入建议检查"
                                        />
                                 </Form.Item>
                             </Col>
                         </Row>
     
                         <Row>
                             <Col className='case-title-row' span={24}>
                                 <Button className='case-title-btn' disabled>建议查体</Button>
                             </Col>
                             <Col span={24}>
                                 <Form.Item
                                     {...restField}
                                     name={[name, 'physicalexamsTag']}
                                     // label="建议查体:"
                                     rules={[{ required: false, message: '建议查体' }]}
                                 >
                                     {/* <TextArea placeholder="请输入建议查体" /> */}
                                     <Weditor
                                        name={`physicalexamsTag`}
                                        textName={`physicalexamsTag`}
                                        editorC={editorC}
                                        editorText={editorText}
                                        initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].physicalexamsTag}
                                        placeholder="请输入建议查体"
                                    />
                                 </Form.Item>
                             </Col>
                         </Row>
     
                         <Row>
                             <Col className='case-title-row' span={24}>
                                 <Button className='case-title-btn' disabled>重要体征</Button>
                             </Col>
                             <Col span={24}>
                                 <Form.Item
                                     {...restField}
                                     name={[name, 'signsTag']}
                                     // label="重要体征:"
                                     rules={[{ required: false, message: '重要体征' }]}
                                 >
                                    {/* <TextArea placeholder="请输入重要体征" /> */}
                                    <Weditor
                                        name={`signsTag`}
                                        textName={`signsTag`}
                                        editorC={editorC}
                                        editorText={editorText}
                                        initialHtml={record.szDiagnosis && record.szDiagnosis.length && record.szDiagnosis[key].signsTag}
                                        placeholder="请输入重要体征"
                                    />
                                 </Form.Item>
                             </Col>
                         </Row>
                         </Col>
     
                         <Col span={6}>
                             <Row>
                                 <Col span={24}>
                                     <Form.Item
                                         {...restField}
                                         name={[name, 'prescriptionList']}
                                         // label="开药:"
                                        
                                         rules={[{ required: false, message: '开药' }]}
                                     >
                                         <div className='prescription-row'>
                                             <Button  className='prescription-btn' type="primary" onClick={() => {onOpenPrescriptionModal(key)}} >点击开药</Button>
                                         </div>
                                         {
                                             <EditableProTable<any>
                                                 // headerTitle="可编辑表格"
                                                 columns={columns}
                                                 rowKey="yId"
                                                 scroll={{
                                                 y: 650,
                                                 x: 500
                                                 }}
                                                 className='prescription-list'
                                                 value={dataSource[key]}
                                                 // onChange={ setDataSource}
                                                 recordCreatorProps={false}
                                                 toolBarRender={false}
                                                 editable={{
                                                     type: 'multiple',
                                                     editableKeys:editableKeys[key],
                                                     actionRender: (row: any, config: any, defaultDoms: any) => {
                                                         return [defaultDoms.delete];
                                                     },
                                                     onValuesChange: (record: any, recordList: any) => {
                                                         // 删除列表
                                                         if(recordList.length < dataSource[key].length) {
                                                             // 不能直接赋值, 根据encode赋值
                                                             const encodeKeys = recordList.map((item: any) => item.encode);
                                                             const obj: any = {};
                                                         
                                                             encodeKeys.map((keyName: string) => {
                                                                 obj[keyName] = recordList.filter((item: any) => item.encode === keyName);
                                                             })
                                                             dispatch(setPrescriptionSelectList({
                                                                 ...prescriptionSelectList,
                                                                 [key]: obj
                                                             }))
                                                         } else {
                                                             // 更新编辑列表
                                                             setDataSource({
                                                                 ...dataSource,
                                                                 [key]: recordList
                                                             });
                                                         }
                                                     
                                                     },
                                                     onChange: (_editableKeys: any) => {
                                                         setEditableRowKeys({
                                                             ...editableKeys,
                                                             [key]: _editableKeys
                                                         })
                                                     },
                                                 }}
                                         />
                                         }
                                         
                                     </Form.Item>
                                 </Col>
                             </Row>
                         </Col>
                        
                         </Row>
                     }
                    </>
                   )
                 })}
               
                <Row hidden>
                    <Col span={24} >
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加诊断
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            )}
         </Form.List>
        </>
    );
};

export default DoctorDiagnosis;