
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Card, Row, Col, Tag, Empty } from 'antd';

import { actions as globalAction } from '../../../../redux/modules/global';
import '../../diagnosis.css';
const { getPatientAllDiseaseInfo } = globalAction;
interface Props {
}

const patientCasesInfo = [{
    label: '时间',
    name: 'recordDate', //initialDiagnosis tags is chief tags
    tagName: [],
    isTime: true
}, {
    label: '主诉',
    name: 'chiefComplaint', //initialDiagnosis tags is chief tags
    tagName: ['initialDiagnosisTags']
},
{
    label: '诊断',
    name: 'initialDiagnosis',
    tagName: ['diagnosis', 'diagnosisTag']
},
{
    label: '处置',
    name: 'cz',
    tagName: ['treatments', 'treatmentsTag']
}];

const CaseList = (props: Props) => {
    const dispatch = useDispatch();
    const { patientAllDiseaseInfo, currentPatientId} = useSelector((state: any) => {
        return {
            patientAllDiseaseInfo: state.global.patientAllDiseaseInfo,
            currentPatientId: state.global.currentPatientId
        }
    });
    const [pagination, setPagination] = useState<any>({
        pageSize: 3,
        pageNum: 1,
        total: 0
    });
    const getTags = (item: any, data: any) => {
        const { tagName } = item;
        return tagName.map((tag: any) => {
            if (typeof (data[tag]) === 'string') {
                const _tags = data[tag] && data[tag].split(',');
                return _tags && _tags.map((_item: string) => _item && <span key={_item} color="success">{_item}</span>)

            } else {
                return data[tag] && data[tag].map((_item: any) => <span key={_item.id} color="success">{_item.zd || _item.tName}</span>)
            }
        })
    }
    useEffect(() => {
        //分页数据
        dispatch(getPatientAllDiseaseInfo(currentPatientId, {
            pageSize: 3,
            pageNum: pagination.pageNum,
            // total: 0
        }))
        //
    }, [pagination.pageNum])
   

    useEffect(() => {
        dispatch(getPatientAllDiseaseInfo(currentPatientId, {
            pageSize: 3,
            pageNum: 1,
            total: 0
        }))
    }, [currentPatientId])
    return (


        <fieldset className='zt-right'>
            <legend>就诊信息</legend>
            {
                JSON.stringify(patientAllDiseaseInfo) !== '{}' &&  JSON.stringify(patientAllDiseaseInfo) !== '[]'
                    ? <List
                    className='zt-list'
                        dataSource={patientAllDiseaseInfo.list}
                        pagination={{
                            onChange: page => {
                                console.log(page);
                                setPagination({
                                    pageSize: 3,
                                    pageNum: page,
                                })
                              },
                            pageSize: pagination.pageSize,
                            total: patientAllDiseaseInfo.pagination.records
                        }}
                        renderItem={(item: any) => (
                            <List.Item key={item.id}>
                                <div className='zt-card'>
                                    <div className='zt-card-ht'>
                                        <div>时间 : {item['recordDate'] ? item['recordDate'].split('T')[0]: ''}</div>
                                    </div>

                                    <div className='zt-card-bd'>
                                        <div>主诉 :
                                            {item['chiefComplaint']}
                                            {
                                                getTags({ tagName: ['initialDiagnosisTags'] }, item)
                                            }
                                        </div>
                                        <div>诊断 :
                                            {item['initialDiagnosis']}
                                            {
                                                getTags({ tagName: ['diagnosis', 'diagnosisTag'] }, item)
                                            }
                                        </div>

                                    </div>

                                    <div className='zt-card-ft'>
                                        <div>处置 :
                                            {item['cz']}
                                            {
                                                getTags({ tagName: ['treatments', 'treatmentsTag'] }, item)
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* {
                                    patientCasesInfo.map((_item: any, index) => (<div key={item.id + index}>
                                        <div>{_item.label} :
                                            {_item.isTime ? item[_item.name].split('T')[0] : item[_item.name] || '无'}
                                            {
                                                _item.tagName && getTags(_item, item)
                                            }
                                        </div>
                                    </div>))
                                } */}

                            </List.Item>
                        )}
                    />
                    : <Empty description={'暂时没有记录'} />
            }

        </fieldset>
    )
};

export default CaseList;
