
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, DatePicker, AutoComplete, notification, Select, Tabs, Spin, Table, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import  CaseDetail from './CaseDetail';
import  UpdateCase from './UpdateCase';
import  AddCase from './AddCase';
import APIServices from '../../../../lib/APIServices';
import { actions as globalAction } from '../../../../redux/modules/global';
import Common from '../../../../lib/Common';
import Constants from '../../../../lib/Constants';


const {GENDER_WOMAN} = Constants;
const { getAge } = Common;

interface Props {
}

const { getDoctorRecord, getMiniCaseList } = globalAction;
const { getAllMiniCases } = APIServices;
const CaseList = (props: Props) => {
    const dispatch = useDispatch();
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [visibleAddCase, setVisibleAddCase] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>();
    const [loadingStatus, setLoadingStatus] = useState<any>();
    const [recordData, setRecordData] = useState<any>([]);
    const [pagination, setPagination] = useState<any>({
        pageSize: 10,
        pageNum: 1,
        total: 0
    });
    const [searchValue, setSearchValue] = useState<any>({
        patientName: '',
        startTime: '',
        endTime: '',
    });
    const columns: any = [
        {
            title: '姓名',
            dataIndex: 'patientName',
            key: 'patientName',
            // align: 'center',
            render: (text: any) => <span >{text}</span>,
        },
        {
            title: '身份证号',
            dataIndex: 'cardNumber',
            key: 'cardNumber',
            // align: 'center',
            render: (text: any) => <span >{text}</span>,
        },
        {
            title: '年龄',
            dataIndex: 'birthday',
            key: 'birthday',
            // align: 'center',
            render: (text: any) => <span >{getAge(text)}岁</span>,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            // align: 'center',
            render: (text: any) => <span >{Number(text) === GENDER_WOMAN ? '女' : '男'}</span>,
        },
        {
            title: '病名',
            dataIndex: 'diseaseName',
            key: 'diseaseName',
        },
        {
            title: '主诉',
            dataIndex: 'chiefComplaint',
            key: 'chiefComplaint',
            // align: 'center',
            render: (text: any) => <span >{text}</span>,
        },
        {
            title: '时间',
            dataIndex: 'createDate',
            key: 'createDate',
            // align: 'center',
            render: (text: any) => <span >{text ? text.split('T')[0]: ''}</span>,
        },
        {
            title: '详情',
            key: 'ttbw',
            // align: 'center',
            render: (text: number, record: any) => {
                const openDetailPage = () => {
                    setDefaultRecord(record);
                    setVisibleDetail(true);
                }
                const openUpdatePage = () => {
                    setDefaultRecord(record);
                    setVisibleUpdate(true);
                }
               
                const openAddCasePage = () => {
                    setDefaultRecord(record);
                    setVisibleAddCase(true);
                }
                return (<Space size="middle">
                    {/* <Button type='link' onClick={openDetailPage}>预诊情况</Button> */}
                    {/* <Button type='link' onClick={openUpdatePage}>诊断</Button> */}
                    <Button type='link' onClick={openAddCasePage}>添加病例</Button>
                    {/* <Space onClick={openUpdatePage}><EyeOutlined style={{ fontSize: 24, color: '#1890ff' }} /></Space> */}
                    
                </Space>)
            },
        }
    ];
    const { miniCaseList } = useSelector((state: any) => ({
        miniCaseList: state.global.miniCaseList,
       
    }));
    const AllData = async () => {
        dispatch(getMiniCaseList(searchValue, pagination ));
        dispatch(getDoctorRecord({
            patientName: '',
            startTime: '',
            endTime: '',
        }, {
            pageSize: 10,
            pageNum: 1,
            total: 0
        }));
    };
    const onChange = (date: any, dateString: any, title: any) => {
        setSearchValue({
            ...searchValue,
            [title]: dateString,
        })

    };
    const onChangeName = (e: any) => {
        setSearchValue({
            ...searchValue,
            patientName: e.target.value,
        })
    }

    const onChangePagination = (_pagination: any) => {
        setPagination({
            ...pagination,
          pageNum: _pagination.current,
          pageSize: _pagination.pageSize,
        })
      };


    useEffect(() => {
        if(miniCaseList.pagination) {
            setPagination({
                ...pagination,
                total: miniCaseList.pagination.records
            })
        }
        
    }, [miniCaseList.pagination])

    useEffect(() => {
        AllData()
    }, [pagination.pageNum, searchValue])
    return (
        <div className='container'>
            <Form
                layout='inline'
            >
                <Form.Item label="患者姓名:">
                    <Input placeholder='输入人名搜索' onChange={onChangeName} />
                </Form.Item>
                <Form.Item label="开始时间:">
                    {
                        // @ts-ignore
                        <DatePicker
                            format={'YYYY-MM-DD'}
                            placeholder='选择时间'
                            onChange={(data, dataString) => onChange(data, dataString, 'startTime')} />
                    }
                </Form.Item>
                <Form.Item label="结束时间:">
                    {
                        // @ts-ignore
                        <DatePicker
                            format={'YYYY-MM-DD'}
                            placeholder='选择时间'
                            onChange={(data, dataString) => onChange(data, dataString, 'endTime')} />
                    }
                </Form.Item>
            </Form>
            {
                loadingStatus
                    ? <Spin tip={'数据加载中...'} size="large" spinning={loadingStatus} className='tip'>
                    </Spin>
                    :
                    <Table 
                    loading={loadingStatus} 
                    rowKey='id' 
                    dataSource={miniCaseList.list} 
                    columns={columns} 
                    style={{marginTop:10}}
                    pagination={pagination}
                    onChange={onChangePagination}
                    />
            }
            {
                visibleDetail
                &&
                <CaseDetail
                    title='患者详情'
                    visible={visibleDetail}
                    record={defaultRecord}
                    onClose={() => setVisibleDetail(false)}

                />

            }

            {
                visibleUpdate
                &&
                <UpdateCase
                    title='患者详情'
                    visible={visibleUpdate}
                    record={defaultRecord}
                    onClose={() => {setVisibleUpdate(false); AllData();}}

                />

            }

            {
                visibleAddCase
                &&
                <AddCase
                    title='添加患者病例'
                    visible={visibleAddCase}
                    record={defaultRecord}
                    onClose={() => {setVisibleAddCase(false);}}

                />

            }
        </div>
    )
};

export default CaseList;