import React, { useEffect, useState } from 'react';
import { Drawer, Col, Row, Tree, Table, Button, Tag } from 'antd';
import { useSelector } from 'react-redux';

import DiagnosisCaseList from '../diagnosisCase/DiagnosisCaseListDetail';
import APIServices from '../../../../lib/APIServices';
import Constants from '../../../../lib/Constants';
const {GENDER_WOMAN, CASE_TYPE} = Constants;
interface Props {
    title: string
    visible: boolean
    record: any
    onCloseDetail: () => void
}
const SmartAnalysesDiagnosis = (props: Props) => {
    const [dataSource, setDataSource] = useState([]);
    const { title, visible, record, onCloseDetail } = props;
    const [visibleCaseDetail, setVisibleCaseDetail] = useState(false);
    const [defaultRecord, setDefaultRecord] = useState<any>([]);
    // 打开决策的病例
    const onOpenPreCase = (currentCase: any) => {
        setVisibleCaseDetail(true);
        setDefaultRecord(currentCase);
    };
    const columns: any = [
        {
            title: '姓名',
            dataIndex: 'patientName',
            key: 'patientName',
            align: 'center',
            render: (text: any, _r: any) => <Button type='text' >{text}</Button>,
        },
        {
            title: '年龄',
            dataIndex: 'nl',
            key: 'nl',
            align: 'center',
            render: (text: any, _r: any) => <span >{text}岁</span>,
        },
        {
            title: '性别',
            dataIndex: 'xb',
            key: 'xb',
            align: 'center',
            render: (text: any, _r: any) => <span >{Number(text)=== GENDER_WOMAN ? '女' : '男'}</span>,
        },
        {
            title: '民族',
            dataIndex: 'mz',
            key: 'mz',
            align: 'center',
        },
        {
            title: '疼痛部位',
            dataIndex: 'ttbw',
            key: 'ttbw',
            align: 'center',
        },
        {
            title: '诊断',
            dataIndex: 'zd',
            key: 'zd',
            align: 'center',
        },
        {
            title: '推荐治疗',
            dataIndex: 'mainTitle',
            key: 'mainTitle',
            align: 'center',
            render: (text: any, record: any) => {
                return <div>
                {
                    record.type == CASE_TYPE.TB &&
                    <a target={'_blank'} href={`http://tbxt.mzyx.zydyfy.cn/anquan/details/${record.aId}`}>{record.mainTitle}</a>
                }
                {
                    record.type == CASE_TYPE.JC &&
                    <Button type='link' style={{ color: '#576d95'}}  onClick={() => onOpenPreCase(record)} >{record.mainTitle}</Button>
                }
            </div>
                
            }
        },
    ];


    useEffect(() => {
        setDataSource(record);
    }, [record])

    return (
        <Drawer
            title={`${title}详情`}
            placement="right"
            onClose={onCloseDetail}
            width={'80%'}
            visible={visible}
        >
            <Table rowKey='id' dataSource={dataSource} columns={columns} />
             {
                visibleCaseDetail
                &&
                <DiagnosisCaseList
                    title='病历详情'
                    visible={visibleCaseDetail}
                    record={defaultRecord}
                    onCloseDetail={() => setVisibleCaseDetail(false)}
                />
            }
        </Drawer>
    );
};

export default SmartAnalysesDiagnosis;